/*doc
---
title: Ficha Publicación
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Ficha publicaciones
body.node-type-publicacion {

	//.page-header-styles;

	div#cuerpo-ficha {
		padding-top: 10px;
	}

}

// Autores

p#autores-publicacion {
	padding-bottom: 10px;
	border-bottom: 1px solid @grayLighter;

	span {
		width: 70px;
		margin-bottom: 3px;
		padding: 5px 0 3px 0;
		display: block;
		font: 400 14px/14px @boldScout;
		text-transform: uppercase;
		text-align: center;
		background: @grayDark;
		color: @white;
	}

}

// Imagen

div#imagen-publicacion {
	margin: 0 0 20px 0;
	@media (min-width: 1281px) {
		margin: 0 0 30px 0;
	}
}

// Precio

p#precio-publicacion {
	margin-bottom: 5px;

	span.etiqueta {
		font: 400 14px/14px @regularScout;
		text-transform: uppercase;
		color: @grayLight;
	}
	
	span.precio {
		font: 400 14px/14px @boldScout;
	}

}

// Datos publicacion

ul#datos-publicacion {
	margin: 0 0 20px 0;
	padding: 0 0 5px 10px;
	list-style: none;
	border-left: 1px solid @grayLighter;
	@media (min-width: 1281px) {
		margin: 0 0 30px 0;
	}

	li {

		&,
		p {
			padding: 2px 0;
			font: 400 13px/18px @sansFontFamily;
			color: @grayLight;
		}

		span {
			font: 400 11px/18px @sansFontFamily;
			text-transform: uppercase;
		}

	}	

}

.external-form-wrapper {
  background: #f5f5f5;
  border-color: #f5f5f5;
  margin-bottom: 30px;
  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    .external-form-iframe {
      flex-grow: 1;
      flex-basis: 50%;
    }

    .external-form-text {
      padding: 30px;
      flex-grow: 1;
      flex-basis: 50%;
    }
  }
  .external-form-iframe {
    width: 100%;
  }
  .external-form-text {
    padding: 30px;
  }
}

/*doc
---
title: Thumbnails Nota de prensa
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.nota-prensa {

		a.imagen {
			margin-bottom: 10px;
		}

		div.caption {
			padding-top: 10px;
		}

		h3.titulo {
			margin-bottom: 5px;
		}

		.titulo {

			small {
				white-space: nowrap;
			}
			
		}

		.fecha {
			margin-bottom: 5px;
			font-family: @lightScout;
			font-size: 14px;
			line-height: 1;
			color: @grayLight;
		}

		div.field-name-field-nota-prensa-sede,
		div.field-name-field-nota-prensa-lugar {
			margin: 0 0 5px 0;
			font-weight: 700;
			font-size: 12px;
			line-height: 1.3;
			color: @grayLight;
		}

	}

}
/*doc
---
title: Ficha Exposición
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-exposicion {

	.page-header-styles;

	div#autores-colaboradores {
		display: none;
	}

	.cuerpo-ficha--figure {
		margin-bottom: 20px;
	}

}

// Cuerpo expo
div.field-name-field-exposicion-texto{margin-bottom:30px;}

div#cuerpo-ficha-lateral {
	
	// Carteles

	div.field-name-field-exposicion-carteles {
		padding-top: 2px;

		div.field-label {
			margin-bottom: 2px;
			display: block;
		}

		a {
			margin-left: -5px;
		}

	}

	// Folletos

	div.field-name-field-exposicion-folletos {
		padding-top: 2px;

		div.field-label {
			margin-bottom: 2px;
			display: block;
		}

	}

	// Colaboradores

	div.field-name-field-exposicion-patrocinador {

		div.field-items {

			* {
				display: inline;
			}

		}

	}

	// Autores

	span#mas-autores {
		display: none;	
	}

	a#lnk-mas-autores {
		padding: 0 10px 0 0;
		display: inline-block;
		font: 700 12px/14px @sansFontFamily;
		color: @grayDark;
		text-decoration: none;
		background: url(../images/ie7/red_arrow_down.png) center right no-repeat;
		&:hover {
			cursor: pointer;
		}
		&.active {
			background: url(../images/ie7/red_arrow_up.png) center right no-repeat;
		}
	}

}

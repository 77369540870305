/*doc
---
title: Formularios
category: Estilos basicos
---

Documentación...

*/

// Formularios

input,
textarea,
select,
.uneditable-input {
	padding-left: @space-unit-xxs;
	padding-right: @space-unit-xxs;
	color: #666;	
}

input {
	height: 20px;
	line-height: 20px;
}

input:focus,
textarea:focus {
	border-color: @grayLighter;
	.box-shadow (none);
}

.help-block {
	font-size: 11px;
	line-height: 13px;
	color: @grisClaro;
}

.aviso-legal,
.aviso-legal p {
	.font-size-small ();
	line-height: 1.2;
	color: @grayLight;
}

.form-control--icono {
	position: relative;
}

.form-control--icono__icono--calendario {
	width: 16px;
	height: 16px;
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	background: url(../images/ico.calendario.png) 0 0 no-repeat;
}
.top-bar {
    .ancho-completo-xs ();
    background-color: @grayDark;
    @media (max-width: @mobile-down) {
        display: none;
    }
}

.top-bar__wrapper {
    height: 33px;
    display: flex;
    justify-content: space-between;
}

.top-bar__left {
    display: flex;
    flex-grow: 1;

    .top-bar__label {
        flex-shrink: 0;
    }

    .top-bar__link {
        flex-grow: 1;
    }
}

.top-bar__right {
    display: flex;
    flex-shrink: 0;
}

.top-bar__text-overflow {
    white-space: nowrap; 
    width: 300px; 
    overflow: hidden;
    text-overflow: ellipsis;
    @media (min-width: @desktop) {
        max-width: 500px;
    }
}

.top-bar__label {
    display: inline-flex;
    align-items: center;
    padding-right: @space-unit-xs;
    font-size: @smallFontSize;
    font-weight: bold;
    color: @mostaza;
    // border-right: 1px solid @gray; // Lo comento hasta que publiquemos el carrusel

    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.top-bar__link {
    display: inline-flex;
    align-items: center;
    padding-left: @space-unit-xs;
    padding-right: @space-unit-xs;
    font-size: @smallFontSize;
    font-weight: bold;
    &,
    &:hover {
        color: #fff;   
    }
    &:hover {
        text-decoration: none;
    }
    &:hover,
    &:focus {
        background-color: @grayDarker;
    }
}

.top-bar__button {
    display: inline-flex;
    align-items: center;
    padding-left: @space-unit-xs;
    padding-right: @space-unit-xs;
    font-size: @smallFontSize;
    font-weight: bold;
    color: @grayDark;
    background-color: @mostaza;
    &:hover {
        color: @grayDark;
        text-decoration: none;
        background-color: darken(@mostaza, 5%);
    }
}

.top-bar__dropdown {
    display: inline-flex;

    .btn.dropdown-toggle {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 0 @space-unit-xs;
        // z-index: 1000;
        font-size: @smallFontSize;
        font-weight: bold;
        text-shadow: none;
        text-transform: uppercase;
        color: #fff;
        background-color: transparent;
        border-color: @gray;
        border-width: 0 1px 0 1px;

        &:hover,
        &:focus {
            background-color: @grayDarker;
        }

        .caret {
            margin: 0 0 0 @space-unit-xxxs;
            border-top-color: #fff;
        }

    }

    &.open .btn.dropdown-toggle {
        background-color: @grayDarker;
    }

    .dropdown-menu {
        min-width: inherit;
        margin-top: 0;
        padding: 0;
        left: 0;
        right: 0;
        border-style: solid;
        border-color: @borderLighter;
        border-width: 0 1px 1px 1px;
        background-color: #fff;

        a {
            padding: @space-unit-xxxs @space-unit-xs;
            display: block;
            font-size: @smallFontSize;
            font-weight: 700;
            
            &:hover,
            &:focus {
                color: @grayDark;
                background-color: @whiteDarker;
            }

        }

        span {
            padding: @space-unit-xxxs @space-unit-xs;
            display: block;
            font-size: @smallFontSize;
            font-weight: 700;
        }

        .active {
            &,
            &:hover,
            &:focus {
                color: @grayDark;
                background-color: @mostaza;
            }
        }
    }
}

.top-bar__carrusel {
    display: inline-flex;
    align-items: center;
    width: 350px;
    height: 33px;
    @media (min-width: @desktop) {
        max-width: 550px;
    }

    .top-bar__link {
        height: 33px;
    }

}

/*doc
---
title: Ficha Vídeo
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#video-ficha {
	margin-bottom: 20px;

	div.field-name-field-video-video {
		height: 349px;
		@media (min-width: 1281px) {
			height: 433px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			height: 268px;
		}
		@media (max-width: 767px) {
			height: 248px;
		}
		@media (max-width: 480px) {
			height: 158px;
		}
	}

}

// Duracion video

div#cuerpo-ficha-lateral {

	div.field-name-field-video-duracion {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 22px/22px @boldScout;
			color: @grayDark;
		}

		div.field-items {

			em {
				font: 400 22px/22px @boldScoutItalic;
				font-style: normal;
			}
			
		}

	}

	// Fecha libre video

	div.field-name-field-video-fecha-libre {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 22px/22px @thinScout;
			color: @grayDark;
		}

		div.field-items {

			em {
				font: 400 22px/22px @thinScoutItalic;
				font-style: normal;
			}
			
		}

	}

	// Fecha creación video

	div.field-name-field-video-fecha-creacion {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 16px/16px @thinScout;
			color: @grayDark;
		}

		div.field-items {

			em {
				font: 400 16px/16px @thinScoutItalic;
				font-style: normal;
			}

		}

	}

	// Etiqueta multimedia

	span.multimedia-tipo,
	a.multimedia-tipo {
		background: @grayDark;
		color: @white;
	}

	a.multimedia-tipo {
		&:hover {
			color: @grayDark;
		}
	}

}
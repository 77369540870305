/*doc
---
title: Hoy en el Museo
category: Modulos
---

Estilos de la página "Hoy en el Museo".

*/

.hoy-museo__cabecera {
	margin: -30px 0 45px;
	padding: 20px;
	background-color: @beige;
	@media (max-width: 767px) {
		padding: 10px 15px;
	}
}

.hoy-museo__cabecera__titulo {
	margin-top: 3px;
	margin-bottom: 0;
	font-family: @thinScout;
	font-size: 26px;
	line-height: 26px;
}

.hoy-museo__cabecera__accion {
	margin-left: 10px;
	text-align: right;
	float: right;
	@media (max-width: 767px) {
		margin: 5px 0;
		float: none;
	}
	.btn-default {
		text-shadow: none;
		border-color: #DFD7C7;
		background-color: #DFD7C7;
		.box-shadow(0 0 0 0 transparent);
		&:hover,
		&:active {
			border-color: darken(#DFD7C7, 3%);
			background-color: darken(#DFD7C7, 3%);
		}
	}
	&.open {
		.btn-default {
			border-color: #DFD7C7;
			background-color: #DFD7C7;
			&:hover,
			&:active {
				border-color: #DFD7C7;
				background-color: #DFD7C7;
			}
		}
		.btn.dropdown-toggle {
			background-color: #DFD7C7;
		}
	}
	.dropdown-menu {
		margin-top: 0;
		padding: 0;
		.border-radius(0 0 0 0);
		border-color: #DFD7C7;
		.box-shadow(0 0 0 0 transparent);
	}
	.calendario {
		padding: 30px;
        max-width: 400px;
		background-color: #DFD7C7;
		a {
			&.ui-state-active {
				color: @white;
			}
		}
		.ui-datepicker {
            width: 300px;
			.ui-datepicker-header {
				border-bottom: 1px solid darken(#DFD7C7, 10%);
			}
			tbody {
				border-left: 1px solid #DFD7C7;
				border-top: 1px solid #DFD7C7;
			}
			td {
				span,
				a,
				.ui-state-default {
					border-color: #DFD7C7;
				}
			}
			.calendar-colours(@white, darken(@white, 5%), @mostaza, @grayDark);
		}
	}
}

#actividades-visitas-comentadas,
#exposiciones-bloque-portada {
	.title-section {
		margin-bottom: 30px;
		padding-bottom: 5px;
		border-bottom: 4px solid @grayDark;
	}
}
/*doc
---
title: Ficha Audio
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#audio-ficha {
	margin-bottom: 20px;

}

// Reproductor con imagen para ficha y destacado multimedia

.reproductor {
	position: relative;	
}

.reproductor-con-imagen div.jp-audio {
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid @grayLighter;
}

div.jp-audio {
	width: 100%;
	padding: 15px 0;
	background: @white;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a.descarga_audio {
	position: absolute;
	bottom: 4px;
	right: 0;
	font: 400 11px/13px @sansFontFamily;
}

div#cuerpo-ficha-lateral {
	
	// Fecha creación audio

	div.field-name-field-audio-fecha-creacion {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 16px/16px @thinScout;
			color: @grayDark;
		}

		div.field-items {
			
			em {
				font: 400 16px/16px @thinScoutItalic;
				font-style: normal;
			}

		}

	}
	
	// Fecha libre audio

	div.field-name-field-audio-fecha-libre {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 22px/22px @thinScout;
			color: @grayDark;
		}
		
		div.field-items {

			em {
				font: 400 22px/22px @thinScoutItalic;
				font-style: normal;
			}

		}

	}

	// Duracion audio

	div.field-name-field-audio-duracion {
		margin-bottom: 10px;

		&,
		div.field-items,
		div.field-items * {
			font: 400 22px/22px @boldScout;
			color: @grayDark;
		}

	}

}
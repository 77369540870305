.site-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.site-nav--primary {
    align-self: flex-end;
    .site-nav__item {
        padding-bottom: 2px;
        font-size: 18px;
        font-weight: bold;
        line-height: @headingsLineHeight;
        text-transform: uppercase;
        color: @textColorDark;
        border-bottom: 2px solid transparent;
        @media (min-width: @desktop-large) {
            font-size: 19px;
        }
        &:not(:first-child) {
            margin-left: @space-unit-sm;
        }
    }

    .site-nav__item--active {
        border-bottom-color: @grayDark;
    }
}

.site-nav--secondary {
    .site-nav__item {
        font-size: 13px;
        color: @grisClaro;
        &:not(:first-child) {
            margin-left: @space-unit-sm;
        }
    }

    .site-nav__item--active {
        border-bottom: 1px solid @grisClaro;
    }
}

.site-nav__item {
    transition: @transition-base;
    &:hover {
        text-decoration: none;
        color: @component-active-color;
        cursor: pointer;
    }
}

.site-nav__buscador {
    &.site-nav__item {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .site-nav__buscador__close {
        display: none;
    }
}

.site-nav__buscador--active {
    .site-nav__buscador__lupa {
        display: none;
    }

    .site-nav__buscador__close {
        display: inline-flex;
    }
}
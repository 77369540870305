.hamburger {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  transition: @transition-base;
  @media (min-width: @desktop) {
    display: none;
  }

  &.hover,
  &:hover {
    color: @component-active-color;
  }

  &.active,
  &.focus,
  &:active,
  &:focus {
    color: @component-active-color;
  }

  &.focus,
  &:focus {
    outline: none;
  }

  .hamburger__icon {
    color: inherit;
  }

  .hamburger__icon--close {
    display: none;
  }
}

.hamburger--active {
  &,
  &.active,
  &.focus,
  &.hover,
  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }

  .hamburger__icon--hamburger {
    display: none;
  }

  .hamburger__icon--close {
    display: inline-flex;
  }
}

.hamburger__label {
  display: inline-block;
  margin-right: @space-unit-xxxs;
  font-family: @boldScout;
  font-size: 16px;
}

.hamburger__icon {
  color: inherit;
}

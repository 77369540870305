/*doc
---
title: Página Estática
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

 */

// Columna central
body.node-type-page .node-page{
	padding-bottom:20px;}
@media (min-width: 1281px) {
body.node-type-page .node-page{
	padding-bottom:30px;}
}

body.node-type-page  .pagina-con-columna{
	padding-top:15px;}

// Titulos
body.node-type-page h1,
body.node-type-page h2,
body.node-type-page h3,
body.node-type-page h4,
body.node-type-page h5,
body.node-type-page h6{
	clear:both;}

body.node-type-page h2.subtitulo{
  margin-bottom:5px;padding:0;
  font:400 34px/34px @thinScout;
}
@media (min-width: 1281px) {
  body.node-type-page h2.subtitulo {
	font: 400 44px/44px @thinScout;
  }
}

// Compartir
body.node-type-page div#compartir {
	padding-top: 0;
}

// Imagenes
body.node-type-page div.imagenes{
	margin:-20px 0 0 0;}
div#imagenes-page{margin-bottom:0;}
@media (max-width: 767px) {
body.node-type-page div.imagenes a.carousel-control{
	display:none;}
}


// Bloques destacados
div.bloque-destacado{
	margin-bottom:20px;padding-top:20px;
	border-top:1px solid @grayLighter;}
div.gris{
	background: @whiteDarker;
}

// Titulos de bloque
div.field-name-field-page-texto-columna h3{
	padding-top:5px;
	font-size:18px;line-height:19px;
	border-top:4px solid @grayDark;}

// Listado enlaces
ul.listado-enlaces{
	margin:0 0 15px 0;
	list-style:none;}
ul.listado-enlaces li{
	border-bottom:1px solid #ddd;}
ul.listado-enlaces li > a.pdf,
ul.listado-enlaces li > a.txt,
ul.listado-enlaces li > a.doc,
ul.listado-enlaces li > a.xls,
ul.listado-enlaces li > a.ppt{
	padding-top:3px;padding-bottom:3px; display:block;}
ul.listado-enlaces li > a.pdf:hover,
ul.listado-enlaces li > a.txt:hover,
ul.listado-enlaces li > a.doc:hover,
ul.listado-enlaces li > a.xls:hover,
ul.listado-enlaces li > a.ppt:hover{
	background-color:@whiteDark; text-decoration:none;}
ul.listado-enlaces li > a.pdf{
	background:url(../images/ico.descarga.pdf.png) 0 3px no-repeat;}
ul.listado-enlaces li > a.txt{
	background:url(../images/ico.descarga.txt.png) 0 3px no-repeat;}
ul.listado-enlaces li > a.doc{
	background:url(../images/ico.descarga.doc.png) 0 3px no-repeat;}
ul.listado-enlaces li > a.xls{
	background:url(../images/ico.descarga.xls.png) 0 3px no-repeat;}
ul.listado-enlaces li > a.ppt{
	background:url(../images/ico.descarga.ppt.png) 0 3px no-repeat;}
ul.listado-enlaces span{
	display:block;
	font-weight:bold;
}

// Listado Accesos

.listado-accesos {
	margin:0 0 20px 0;
	list-style:none;
	border-top:1px solid @grayLighter;
}

.acceso {
	padding: 10px 0;
	border-bottom:1px solid @grayLighter;

	> a {
		font-family: @boldScout;
		font-size: @fontScale2;
		line-height: 1.1;
		color:@grayDark;
	}

}

.acceso__submenu {
	margin-left: 10px;
	padding-top: 5px;
	list-style: none;

	li {
		margin-bottom: 5px;
		&:first-child {
			margin-top: 5px;
		}
	}

	a {
		font-family: @lightScout;
    font-size: @fontScale1;
		line-height: 1.1;
		color: @grayLight;
		cursor: pointer;
	}

}

// Iconos
.icon-izda{
	margin:0 20px 20px 0;float:left;}

// Parrafos con formato de bloque
p.bloque-izda,
p.bloque-dcha{
	width:300px;padding:15px 0 20px 20px;
	border-left:1px solid @grayLighter;
	}
p.bloque-izda span,
p.bloque-dcha span{
	margin-bottom:4px;display:block;
	font:700 13px/15px @sansFontFamily;}
p.bloque-izda small,
p.bloque-dcha small{
	display:block;}
p.bloque-izda{
	margin:0 20px 20px 0;float:left;}
p.bloque-dcha{
	margin:0 0 20px 20px;float:right;}
@media (min-width: 1281px) {
p.bloque-izda,
p.bloque-dcha{
	width:370px;}
}
@media (min-width: 768px) and (max-width: 979px) {
p.bloque-izda,
p.bloque-dcha{
	width:228px;}
}
@media (max-width: 767px) {
p.bloque-izda,
p.bloque-dcha{
	width:228px;margin:0 0 20px 0;float:none;}
}

// Parrafos con formato en columna derecha
div.field-name-field-page-texto-columna p.bloque-izda,
div.field-name-field-page-texto-columna p.bloque-dcha{
	width:auto;margin:0 0 20px 0;padding:15px 0 20px 0;float:none;
	border-top:1px solid @grayLighter;border-bottom:1px solid @grayLighter;border-left:none;border-right:none;
	}

// Imagenes en cuerpos de texto
img.imagen-izda{
	margin:0 20px 20px 0;float:left;}
img.imagen-dcha{
	margin:0 0 20px 20px;float:right;}
img.imagen-centrada{
	margin:0 auto;}
@media (max-width: 767px) {
img.imagen-izda,
img.imagen-dcha{
	width:100%;
	margin:0 0 20px 0;float:none;}
}

// Parrafo destacado
.parrafo-destacado{
	padding:15px 20px 20px 20px;
	background:@whiteDarker;}

// Precios en pagina Horarios y tarifas
p.precio{
	font:400 14px/14px @lightScout;
	color:@grayLight; text-align:right;}

// Bloque de novedades Colabora con el museo
div.field-name-field-page-texto-columna .bloque-novedades{
	padding:5px 20px 10px 20px;
	background: @beige;
	h3{
		font:400 20px/20px @regularScout;
		border-top:none;
	}
}



// Anclas
ul.anclas{
	margin:0 0 20px 0;padding:0;
	list-style:none;border-top:1px solid @grayLighter;}
ul.anclas > li > a{
	display:block;padding:8px 5px;
	font:400 16px/16px @lightScout;
	color:@grisClaro;border-bottom:1px solid @grayLighter;}
ul.anclas > li > a:hover{
	color:@grayDark;text-decoration:none;}
ul.anclas > .active > a,
ul.anclas > .active > a:hover{
	color:@grayDark;
	border-color:@grayLighter;
	background:@whiteDarker;}
@media (max-width: 767px) {
ul.anclas{
  display:none;}
body#tinymce.mceContentBody ul.anclas {
  display:block;}
}

// Anclas con img destacada
ul.anclas li.destacado{
	position:relative;}
ul.anclas li.destacado a{
	padding:0;
	border-bottom:none;}
ul.anclas li.destacado span{
	width:100%;min-height:30px;display:block;padding:5px;position:absolute;bottom:0;left:0;
	font:400 12px/15px @sansFontFamily;
	background:@grayDark;color:@white;
	opacity:0.8;filter:alpha(opacity=80);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing:border-box;}
ul.anclas li.destacado a:hover span{
	background:@beige; color:@grayDark;}

// Intro
.intro{
	color:@grayLight;}

// Descarga de documentos
a.pdf,
a.txt,
a.doc,
a.xls,
a.ppt{
	padding-left:40px;display:inline-block;}
a.pdf:hover,
a.txt:hover,
a.doc:hover,
a.xls:hover,
a.ppt:hover{
	color:@linkColorHover;
}
a.pdf{
	background:url(../images/ico.descarga.pdf.png) 0 0 no-repeat;}
a.txt{
	background:url(../images/ico.descarga.txt.png) 0 0 no-repeat;}
a.doc{
	background:url(../images/ico.descarga.doc.png) 0 0 no-repeat;}
a.xls{
	background:url(../images/ico.descarga.xls.png) 0 0 no-repeat;}
a.ppt{
	background:url(../images/ico.descarga.ppt.png) 0 0 no-repeat;}

// Relacionados
div#menu-descargas{
  border-top: 4px solid #333333;}
div#menu-relacionados p.titulo-bloque, div#menu-descargas p.titulo-bloque{
	margin-bottom:5px;}
div#menu-relacionados ul, div#menu-descargas ul{
	margin:0 0 20px 0;padding:0;
	list-style:none;border-top:1px solid @grayLighter;}
div#menu-relacionados ul > li > a, div#menu-descargas ul > li > a{
	display:block;padding:8px 0;
	font:400 16px/16px @lightScout;
	color:@grisClaro;border-bottom:1px solid @grayLighter;}
div#menu-relacionados ul > li > a:hover, div#menu-descargas ul > li > a:hover{
	color:@grayDark;text-decoration:none;}
div#menu-relacionados ul > .active > a,
div#menu-relacionados ul > .active > a:hover{
	color:@grayDark;border-color:@grayLighter;}

@media (max-width: 767px) {
div#menu-relacionados, div#menu-descargas{
	display:none;}
}
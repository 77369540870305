/*doc
---
title: Portada Prensa
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.portada_prensa div#main{
	background:url(../images/bk.borde.gris.png) 650px 0 repeat-y;}
@media (min-width: 1281px) {
body.portada_prensa div#main{
	background:url(../images/bk.borde.gris.png) 815px 0 repeat-y;}
}
@media (min-width: 768px) and (max-width: 979px) {
body.portada_prensa div#main{
	background:url(../images/bk.borde.gris.png) 506px 0 repeat-y;}
}
@media (max-width: 767px) {
body.portada_prensa div#main{
	background:none;}
}

// Recursos
a.recursos{
	padding:4px 0 4px 20px;
	position: relative;
	font:400 12px/12px @sansFontFamily;
	&:before{
		.spritePadded(sprite1, 5, 4, 16px, 16px);
   		left: 0;
   		top: 3px;
	}
}

div#notas-prensa a.recursos,
ul#results_list a.recursos,
div#view-exposiciones-informacion-para-prensa a.recursos{
	display:inline-block;}

// IE7
.lt-ie8 a.recursos{
	background:url(../images/ie7/ico.descargas-recursos.png) 0 3px no-repeat;
}

// Bloque notas de prensa
div#notas-prensa{
	margin-bottom:20px;}
div#notas-prensa ul.thumbnails{
	margin-left:0;}
div#notas-prensa ul.thumbnails li.views-row-first{
	padding:10px 0;
	border-top:1px solid @grayLighter;}
div#notas-prensa ul.thumbnails > li{
	width:100%;margin-bottom:10px; margin-left:0;padding:0 0 10px 0;
	border-bottom:1px solid @grayLighter;}
div#notas-prensa ul.thumbnails .thumbnail > a{
	margin:0 0 0 20px;float:right; vertical-align:top;}
div#notas-prensa ul.thumbnails div.caption{
	max-width: 380px;
	padding-top:0;}
div#notas-prensa div.fecha{
	margin-bottom:5px;
	font:400 14px/14px @lightScout;}
div#notas-prensa div.entradilla{
	margin-bottom:5px;}
div#notas-prensa div.entradilla *{
	margin-bottom:0;}
div#notas-prensa div.carousel div.item{
	text-align:left;}
@media (min-width: 1281px) {
div#notas-prensa{
	margin-bottom:30px;}
div#notas-prensa ul.thumbnails .thumbnail > a{
	margin-left:30px;}
div#notas-prensa ul.thumbnails div.caption{
	max-width: 470px;}
}
@media (min-width: 768px) and (max-width: 979px) {
div#notas-prensa ul.thumbnails div.caption{
	max-width: 380px;}
}
@media (max-width: 767px){
div#notas-prensa ul.thumbnails div.caption{
	max-width: 100%;}
div#notas-prensa ul.thumbnails .thumbnail > a{
	margin-bottom:5px;float:none;}
div#notas-prensa ul.thumbnails > li{
	margin-bottom:0;padding-bottom:0; padding-top:10px;border-bottom:none;}
div#notas-prensa ul.thumbnails .thumbnail > a{
	width:auto;margin-right:0;float:none;}
div#notas-prensa div.fecha{
	padding-top:5px;}
div#notas-prensa ul.thumbnails{
	border-bottom:none;}
}

// Bloque suscripciones
div#suscripciones-notas-prensa label{
	display:none;}
@media (min-width: 1281px) {
div#suscripciones-notas-prensa input.input-medium{
	width:220px;}
}
@media (max-width: 979px) {
div#suscripciones-notas-prensa input.input-medium{
	width:100%;height:28px; margin-bottom:5px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;}
}
.close {
	line-height: 1;
}

button.close--full-screen {
	.splash-button;

	padding: 8px;
	float: none;
	position: fixed;
	top: @space-unit-xxs;
	right: @space-unit-xxs;
	left: auto;
	bottom: auto;
	z-index: @zindexCloseFullScreen;
	@media (min-width: @tablet) {
    padding: 16px;
		top: @space-unit-xs;
		right: @space-unit-xs;
  }
	
	&,
	&:hover,
	&:focus {
		opacity: 1;
	}
  
}
/*doc
---
title: Ficha Nota de prensa
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-nota-prensa {
	.page-header-styles;

	h1.page-header {
		small {
			font-size: 20px;
		}

	}

	// Cuerpo Ficha

	div#cuerpo-ficha {
		margin-bottom: 0;
	}

	.field-name-field-nota-prensa-texto {
		margin-bottom: 30px;
	}
	
	// Ficha lateral

	#cuerpo-ficha-lateral {
		margin-bottom: 0;
		padding-top: 20px;
	}

}


// Tipo

div#cuerpo-ficha-lateral {

	div.field-name-field-nota-prensa-categoria {
		margin-bottom: 5px;

		div.field-item {
			margin-bottom: 5px;
			padding: 5px 10px 6px;
			display: inline-block;
			font: 400 16px/16px @regularScout;
			color: @grayDark;
			background: @beige;
		}

	}

}

// Listado recursos

div#listado-recursos {
	padding-top: 30px;

	.tipo {
		display: none;
	}

}

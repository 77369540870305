/*doc
---
title: Thumbnails Fondos
category: Components
---

Este componente se utiliza para maquetar una fila que contenga columnas de la misma altura. Posteriormente hemos añadido a los Estilos básicos el elemento Table grid para conseguir el mismo objetivo pero de forma más avanzada.

*/

.grid-cajas {
	.row {
		margin-bottom: 20px;
		overflow: hidden;
		@media (min-width: 1281px) {
			margin-bottom: 30px;
		}
		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}
	.caja {
		padding-bottom: 100000px;
		margin-bottom: -100000px;
		background: @whiteDarker;
		@media (max-width: 767px) {
      .margin-bottom-sm ();
			padding-bottom: 0;
		}
	}
}

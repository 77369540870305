/*doc
---
title: Thumbnails Obra
category: Componentes
---

Documentación...

*/

ul.thumbnails {
	
	.obra {

		h3.titulo {
			font-family: @lightScoutItalic;

			.autor {
				display: block;
				font-family: @boldScout;
			}

		}

	}

}
/*doc
---
title: Isla
category: Estilos basicos
---

Creado a partir de la idea original de Harry Roberts http://csswizardry.com/2011/10/the-island-object/. Esta abstracción la utilizamos para controlar los espacios en blanco de los elementos, 
utilizando las propiedades 'margin' y 'padding' y nuestra escala de espacios (mixins/_spaces.scss).

*/

/**
 * Clases con el mismo espacio interior en los ejes X e Y.
 */

.isla--xs {
	.padding-horizontal-xs ();
	.padding-vertical-xs ();
}

.isla--sm {
	.padding-horizontal-sm ();
	.padding-vertical-sm ();
}

.isla--md {
	.padding-horizontal-md ();
	.padding-vertical-md ();
}

/**
 * Clases con valores diferentes en los ejes X e Y.
 * Ejemplo: 	isla--0-xs
 * 				Eje X: 0
 * 				Eje Y: xs
 */

.isla--0-xs {
	.padding-vertical-xs ();
}

.isla--0-sm {
	.padding-vertical-sm ();
}

.isla--sm-0-0 {
	.padding-top-sm ();
}

.isla--0-lg {
	.padding-vertical-lg ();
	@media (max-width: 767px) {
		.padding-vertical-sm ();
	}
}

.isla--sm-0 {
	.padding-horizontal-sm ();
}

.isla--xl-sm {
	.padding-horizontal-xl ();
	.padding-vertical-sm ();
	@media (max-width: 767px) {
		.padding-horizontal-sm ();
	}
}

/**
 * Clases para controlar el espacio interior en función del tamaño de pantalla.
 */

.isla--screen-small--0 {
	@media (min-width: 768px) and (max-width: 979px) {
		.no-padding-horizontal ();
		.no-padding-vertical ();	
	}
}

.isla--screen-extra-small--0 {
	@media (max-width: 767px) {
		.no-padding-horizontal ();
		.no-padding-vertical ();
	}
}

.isla--screen-large--xl-sm {
  @media (min-width: 1281px) {
	.padding-horizontal-xl ();
	.padding-vertical-sm ();
  }
}

.isla--screen-large--xl-xs {
	@media (min-width: 1281px) {
		.padding-horizontal-xl ();
		.padding-vertical-xs ();
	}
}

.isla--screen-large--xl-0 {
  @media (min-width: 1281px) {
	.padding-horizontal-xl ();
	.no-padding-vertical ();
  }
}

.isla--screen-large--xl-md {
	@media (min-width: 1281px) {
		.padding-horizontal-xl ();
		.padding-vertical-md ();
	}
}

.isla--screen-large--md-sm {
  @media (min-width: 1281px) {
	.padding-horizontal-md ();
	.padding-vertical-sm ();
  }
}
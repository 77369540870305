/*doc
---
title: Text icon
category: Componentes
---

Este componente se usa en las siguientes páginas: [Visita](http://www.museoreinasofia.es/visita), [Planificar la visita](http://www.museoreinasofia.es/visita/planificar-visita), [Hoy en el museo](http://www.museoreinasofia.es/hoy).

<div class="alert alert-error">
	Este componente será reemplazado por un componente Media Object.
</div>

```html_example
<div class="text-icon">
	<div class="wrapper">
		<img src="http://placehold.it/50x50" class="icon">
		<div class="text">
			<p>Las salas de exposición se mantienen a una temperatura constante de 21ºC durante todo el año. En días cálidos se recomienda hacer la visita con una prenda de abrigo</p>
		</div>
	</div>
</div>
```
*/

.text-icon {
	margin-bottom: 15px;
	display: table;
	.wrapper {
		display: table-row;
	}
	.icon,
	.text {
		display: table-cell;
		vertical-align: top;
	}
	.icon {
		max-width: none;
		margin: 3px 10px 0 0;
		@media (max-width: 767px) {
			margin-bottom: 15px;
		}
	}
}
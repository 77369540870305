.block-section {
	padding: @space-unit-sm;
	background-color: @whiteDarker;
}

.block-section--beige {
	background-color: @beige;
}

.block-section--dark {
	background-color: @grayDarker;
	&,
	.title1,
	.title2,
	.title3,
	.title4,
	.title5 {
		color: #fff;
	}
}

.block-section--beige {
	background-color: @beige;
}

.block-section--2-cols {
	display: grid;
	gap: @space-unit-md;
	@media (max-width: @mobile-down) {
		grid-template-rows: repeat(auto-fit, minmax(0, max-content));
	}
	@media (min-width: @tablet) {
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-between;
	}
	@media (min-width: @desktop) {
		column-gap: @space-unit-xxl;
	}
}

.block-section--3-cols {
	display: grid;
	gap: @space-unit-md;
	@media (max-width: @mobile-down) {
		grid-template-rows: repeat(auto-fit, minmax(0, max-content));
	}
	@media (min-width: @tablet) {
		grid-template-columns: repeat(3, 1fr);
		justify-content: space-between;
	}
	@media (min-width: @desktop) {
		column-gap: @space-unit-xxl;
	}
}

.block-section--centered {
	.block-section__content {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}
/*doc
---
title: Imágenes
category: Estilos basicos
---

Disponemos de diferentes estilos para representar imágenes.

##Imagenes para publicaciones reales##

Si una imágen corresponde a un documento o publicación real utilizamos el siguiente estilo para que el usuario identifique rapidamente la imágen con el objeto que representa.

```html_example
<a href="#" class="imagen--publicacion">
    <img src="http://placehold.it/120x120">
</a>
```

Si no es necesario envolver el elemento <img> con un link, podemos envolverlo con un 'span.imagen--publicación'.

*/

.imagen--publicacion {
  display: inline-block;
  padding: 5px;
  background-color: darken(@whiteDarker, 4%);

  img {
    .box-shadow (0 2px 2px rgba(0,0,0,0.4));
  }

}

.imagen--izda, // Clase en desuso
.image-left {
  display: table;
  margin-bottom: @space-unit-xsm;
  @media (min-width: @tablet) {
    margin-right: @space-unit-sm;
    float: left;
  }
}

.image-right {
  display: table;
  margin-bottom: @space-unit-xsm;
  @media (min-width: @tablet) {
    margin-left: @space-unit-sm;
    float: right;
  }
}

.image__caption {
  display: table-caption;
  caption-side: bottom;
  padding: @space-unit-xxs 0 @space-unit-xxs 20px;
  position: relative;
  font-family: @thinScout;
  font-size: @baseFontSize;
  line-height: 18px;
  color: @grayLight;
  border-bottom:1px solid @grayLighter;
  &:before{
    .spritePadded(sprite1, 1, 5, 12px, 11px);
       left: 5px;
       top: 11px;
  }
}

/*doc
---
title: Página 500
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-500 h1.page-header{
	padding-top:20px;}
body.page-500 #footer{
	border-top:none;}
.data-primary {
  &:not(:last-child) {
    margin-bottom: @space-unit-xxs;
    @media (min-width: @tablet) {
      margin-bottom: @space-unit-xs;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  a:not([class]) {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: darken(@textColorDark, 10%);
    }
  }
  
}

.data-primary--cta {
  &:not(:last-child) {
    margin-bottom: @space-unit-xsm;
  }
}

.data-primary__desc {
  font-size: @baseFontSize;
}
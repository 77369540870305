.big1 {
    .title-style;
    .font-size-big1;
}

.title1 {
    .title-style;
    .font-size-title1;
}

.title2 {
    .title-style;
    .font-size-title2;
}

.title3 {
    .title-style;
    .font-size-title3;
}

.title4 {
    .title-style;
    .font-size-title4;
}

.title5 {
    .title-alt-style;
    .font-size-title5;
}

.title--muted {
  color: @grayLight;
}

.subtitle2 {
    .subtitle-style;
    .font-size-subtitle2;
}

.subtitle3 {
    .subtitle-style;
    .font-size-subtitle3;
}

.subtitle4 {
    .subtitle-style;
    .font-size-subtitle4;
}

.subtitle5 {
    .subtitle-alt-style;
    .font-size-subtitle5;
}

.antetitle {
    .antetitle-style;
}

.antetitle--muted {
    color: @grayLight;
}

.antetitle__label {
    margin-right: @space-unit-xxxs;
    font-family: @boldScout;
}

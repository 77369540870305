/*doc
---
title: Media Object
category: Componentes
---

Utilizamos nuestra propia versión del componente Media Object ya que Bootstrap 2 no lo incluye. Este es un ejemplo básico:

```html_example
<div class="media">
    <div class="media__media-izquierda">
        <a href="#">
            <img src="http://placehold.it/120x120">
        </a>
    </div>
    <div class="media__contenido">
        <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus 
        scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in 
        vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi 
        vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
    </div>
</div>
```

*/

.media {
    .margin-bottom-sm ();
}

.media,
.media__contenido { 
    overflow:hidden;
    _overflow:visible;
    zoom:1;
}

.media__media-izquierda {
    float: left;
    margin-right: @space-unit-xs;
}

.media__media-derecha {
    float: right;
    margin-left: @space-unit-xs
}

.media__media-izquierda,
.media__media-derecha {
    max-width: 40%;
    @media (max-width: 767px) {
        max-width: 30%
    }

    .imagen--publicacion {
        display: inline-block;
        line-height: 0;
    }

    img {
        display:block;
        &:hover {
            .opacity (75);
        }
    }
}

.media__titulo {
    .no-margin-top ();
    margin-bottom: 5px;
}

/**
 * Variante para mostrar las columnas apiladas en pantallas pequeñas
 */

.media--columnas-apiladas--xs {
    .media__media-izquierda,
    .media__media-derecha {
        @media (max-width: 767px) {
            width: 100%;
            max-width: inherit;
            .no-margin-horizontal ();
            margin-bottom:  @space-unit-xs;
            float: none;
        }
    }
}
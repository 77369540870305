.card {
    display: block;
    color: inherit;
    transition: @transition-base;
    &:hover {
        color: @component-active-color;
        text-decoration: none;
    }
    @media (max-width: @mobile-down) {
		margin-left: -20px;
		margin-right: -20px;
	}
    @media (min-width: @tablet) {
        border: 1px solid @borderLighter;
    }

    .link-icon {
        &:hover {
            color: @component-active-color;
        }
    }
}

.card__image {
    width: 100%;
}

.card__caption {
    padding: 20px;
    @media (min-width: @tablet) {
        padding: @space-unit-sm;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.card__pretitle {
  .antetitle-style;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxs;
  }
}

.card__title {
    .title-style;
    &:not(:last-child) {
      margin-bottom: @space-unit-xs;
    }
}

.card__subtitle {
  .subtitle-style;
  .font-size-title4;
  margin-top: -@space-unit-xxs;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.card__text {
    margin-bottom: @space-unit-xs;
}

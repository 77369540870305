.section-header {
    @media (min-width: @tablet) {
        display: flex;
        justify-content: center;
        background-color: @whiteDarker;
    }
}

.section-header--right {
    .section-header__arrow {
        transform: rotate(180deg);
    }
}

.section-header__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: @space-unit-sm;
    color: inherit;
    text-align: center;
    @media (max-width: @mobile-down) {
		margin-left: -20px;
		margin-right: -20px;
        background-color: @whiteDarker;
	}
    @media (min-width: @tablet) {
        padding: @space-unit-md;
    }
    &[href] {
        cursor: pointer;
        transition: .5s;
        &:hover {
            color: @linkColor;
            text-decoration: none;
    
            .section-header__intro {
                color: @linkColorHover;
            }
        }
    }
}

.section-header__title {
    .title-style;
    margin-bottom: @space-unit-xxs;
}

.section-header__intro {
    max-width: 640px;
    &:not(:last-child) {
        margin-bottom: @space-unit-xxs;
        @media (min-width: @tablet) {
            margin-bottom: @space-unit-xs;
        }
    }

    &,
    p {
        .lead-styles;
    }

    p:last-child {
        margin-bottom: 0;
    }
    
}

.section-header__link {
    align-self: center;
    margin-bottom: @space-unit-xxs;
    text-decoration: underline;
}

.section-header__arrow {
    display: flex;
    justify-content: center;
}

.section-header__btn {
    @media (max-width: @mobile-down) {
        margin-top: @space-unit-xs;
    }
}

.section-header {
    .section-header__arrow {
        @media (max-width: @mobile-down) {
            .arrow-thin-left{
                transform: rotate(270deg);
            }
            .arrow-thin-right{
                transform: rotate(90deg);
            }
        }
    }
}
.display-flex {
  display: flex !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.display-flex\@tablet {
  @media (min-width: @tablet) {
    display: flex !important;
  }
}

.display-flex\@desktop {
  @media (min-width: @desktop) {
    display: flex !important;
  }
}

.display-inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap\@mobile-down {
  @media (max-width: @mobile-down) {
    flex-wrap: wrap !important;
  }
}

.flex-wrap\@tablet {
  @media (min-width: @tablet) {
    flex-wrap: wrap !important;
  }
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-between\@tablet {
  @media (min-width: @tablet) {
    justify-content: space-between !important;
  }
}

.justify-content-space-between\@desktop {
  @media (min-width: @desktop) {
    justify-content: space-between !important;
  }
}

.justify-content-center {
  justify-content: center;
}

.justify-content-center\@tablet {
  @media (min-width: @tablet) {
    justify-content: center !important;
  }
}

.align-items-center {
  align-items: center !important;
}

.align-items-center\@tablet {
  @media (min-width: @tablet) {
    align-items: center !important;
  }
}

.align-items-center\@desktop {
  @media (min-width: @desktop) {
    align-items: center !important;
  }
}

.align-items-end\@tablet {
  @media (min-width: @tablet) {
    align-items: flex-end !important;
  }
}

.align-items-center\@desktop {
  @media (min-width: @desktop) {
    align-items: center !important;
  }
}

.justify-self-center\@tablet {
  @media (min-width: @tablet) {
    justify-self: center !important;
  }
}

.align-self-center\@tablet {
  @media (min-width: @tablet) {
    align-self: center !important;
  }
}

.align-self-end\@tablet {
  @media (min-width: @tablet) {
    align-self: flex-end !important;
  }
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-basis-50 {
  flex-basis: 50% !important;
}

.flex-basis-100 {
  flex-basis: 100% !important;
}

.flex-basis-50\@tablet {
  @media (min-width: @tablet) {
    flex-basis: 50% !important;
  }
}

.flex-basis-auto\@tablet {
  @media (min-width: @tablet) {
    flex-basis: auto !important;
  }
}

.flex-basis-1\/3\@tablet {
  @media (min-width: @tablet) {
    flex-basis: 33.333333% !important;
  }
}

.flex-direction-column\@tablet {
  @media (min-width: @tablet) {
    flex-direction: column !important;
  }
}

.flex-nowrap\@desktop {
  @media (min-width: @desktop) {
    flex-wrap: nowrap !important;
  }
}

.shrink-0\@desktop {
  @media (min-width: @desktop) {
    flex-shrink: 0 !important;
  }
}
@section-vertical-py: @space-unit-md; // 45px
@section-vertical-sticky-height: ~"calc(100vh - 90px)";

.section-vertical {
  box-sizing: border-box;
  display: grid;
  grid-gap: @space-unit-sm;
  padding: @section-vertical-py @gridGutterWidth;
  background-color: @whiteDarker;
  @media (max-width: @mobile-down) {
    margin-left: -@gridGutterWidth;
    margin-right: -@gridGutterWidth;
  }
  @media (min-width: @tablet) {    
    grid-gap: @space-unit-lg;
    grid-template-columns: 1fr auto 2fr;
    padding: @section-vertical-py @space-unit-md;
  }
  @media (min-width: @desktop) {
    padding-left: @space-unit-lg;
    padding-right: @space-unit-lg;
  }
  @media (min-width: @desktop-large) {
    padding-left: @space-unit-xl;
    padding-right: @space-unit-xl;
  }
}

.section-vertical--light {
  background-color: #fff;
}

.section-vertical--dark {
  background-color: @grayDarker;
  
  .section-vertical__divider {
    @media (min-width: @tablet) {
      border-right-color: @grayDark;
    }
  }

  .section-vertical__title {
    color: #fff;
    @media (min-width: @tablet) {
      border-bottom-color: @grayDark;
    }
  }
}

.section-vertical__header {
  @media (min-width: @tablet) {
    position: sticky;
    top: @section-vertical-py;
    height: @section-vertical-sticky-height;
    display: flex;
    align-items: center;
  }
}

.section-vertical__divider {
  @media (max-width: @mobile-down) {
    display: none;
  }
  @media (min-width: @tablet) {
    position: sticky;
    top: @section-vertical-py;
    height: @section-vertical-sticky-height;
    border-right: 1px solid @borderLighter;
  }
}

.section-vertical__title {
  .title-style;
  .font-size-title2;
  margin-bottom: @space-unit-xsm;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-md;
    padding-bottom: @space-unit-xs;
    border-bottom: 1px solid @borderLighter;
  }
}

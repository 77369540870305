.card-logo {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: percentage(9 / 16);    
}

.card-logo__logo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid @borderLighter;
    transition: @transition-base;
    &:hover,
    &:focus {
        border-color: @borderLight;
    }
}

.card-logo__img {
    flex-shrink: 0;
    max-width: 65%;
    max-height: 65%;
}
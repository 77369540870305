.flex-carousel {
	margin-bottom: 100px;
	position: relative;
	@media (max-width: @mobile-down) {
		margin-left: -20px;
		margin-right: -20px;
	}
	
	ul.flex-direction-nav a {
		background-image: url('../images/rs-minimal-black.png');
		&.flex-prev {
			left: 5px;
			@media (min-width: @tablet) {
				left: 0;
			}
		}
		&.flex-next {
			right: 5px;
			@media (min-width: @tablet) {
				right: 0;
			}
		}
	}

	.flex-control-nav {

		&,
		li {
			line-height: 0;
		}

		li {
			margin: 0 5px;
		}
	}

	.flex-control-paging li a {
		width: 8px;
		height: 8px;
		border-radius: 100%;
		background: @grayLighter;
		box-shadow: none;
		&.flex-active {
			background: @gray;
		}
	}

}

.flex-carousel__list {
	display: flex;
	align-items: flex-end;
}

.flexslider__item {
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;
}

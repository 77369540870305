/*doc
---
title: Página 404
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-node-14929 h1.page-header{
	padding-top:20px;}
#E404 p {
	font:normal 20px/24px @sansFontFamily;
	color: @gray;}
#E404 #formulario_buscador {
	padding: 30px 20px 55px 160px;
	background: @whiteDarker url(../images/error404.png) 20px 20px no-repeat;}
#E404 #formulario_buscador p { font:normal 16px/20px @sansFontFamily; }
#E404 #formulario_buscador p a { color: @linkColor;}
#E404 #formulario_buscador .controls { float: left;}
#E404 #formulario_buscador .form-text {
	width: 340px;
	margin: 0 3px 0 0;}
#E404 #formulario_buscador .btn {
	background: @whiteDark;
	color: @grayDark;
	border-color: @grayLighter;
	text-shadow: none;}
#E404 #formulario_buscador .btn:hover {
	background: #e6e6e6;}
@media (min-width: 768px) and (max-width: 979px) {
#E404 #formulario_buscador .form-text {
	width: 200px;}
}
@media (max-width: 767px) {
#E404 #formulario_buscador .form-text {
	width: 170px;}
}
@media (max-width: 480px) {
#E404 #formulario_buscador{
	width:150px;padding:30px 20px;
	background:@whiteDarker;}
#E404 #formulario_buscador .form-text {
	width: 170px;}
}
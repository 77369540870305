/*doc
---
title: Portada Colección
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

@media (min-width: 1281px) {
body.page-coleccion div.buscador-abajo div.region-bottom-header{
	margin-bottom:30px;}
}

.node-type-portada-coleccion .miniatura--fondo-imagen .miniatura__seccion {
	display: none;
}
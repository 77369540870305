@splash-close-color:          #000;
@splash-border-width:         15px;

.splash {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  background: rgba(0,0,0,0.9);
}

.splash--modal {
  align-items: flex-start;
  background-color: #fff;
}

.splash--reader {
  align-items: stretch;
}

.splash__content {
  margin-top: auto;
  margin-bottom: auto;
  padding: @space-unit-lg @gridGutterWidth;
  @media (min-width: @tablet) {
    padding: @space-unit-xxl 0;
  }
}

.splash__loader {
  position: fixed;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%,-50%);
}

.splash__header {
  margin-bottom: @space-unit-xsm;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-sm;
  }
}

.splash__title {
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}

// Clase para evitar el scroll del body

.splash-opened {
  overflow: hidden;
}

// Botones Anterior y Siguiente

.splash__prev,
.splash__next {
  .splash-button;
  padding: 16px 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: @zindexCloseFullScreen;
  @media (min-width: @tablet) {
    padding: 24px 16px;
  }
}

.splash__prev {
  left: 0;
}

.splash__next {
 right: 0;
}

/**
 * Transitions
 */

.splash {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s;
  z-index: -1;

  .close--full-screen {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
  }

}

.splash--open {
  pointer-events: auto;
  opacity: 1;
  z-index: @zindexModalBackdrop;
  overflow-x: hidden;
  overflow-y: auto;

  .close--full-screen {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

}

/*doc
---
title: Elementos fijos
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si funcionan correctamente.</div>

*/

.sticky {
	position: fixed;
	top: 35px;
}

.span3.sticky.right {
	margin-left: 740px;
}

.span4.sticky.right {
	margin-left: 660px;
}

.span6.sticky.right {
  margin-left: 500px;
}

@media (min-width: 1281px) {

	.span3.sticky.right {
		margin-left: 930px;
	}

	.span4.sticky.right {
		margin-left: 830px;
	}

	.span6.sticky.right {
	    margin-left: 630px;
	}

}

@media (min-width: 768px) and (max-width: 979px) {
	
	.span3.sticky.right {
		margin-left: 578px;
	}

	.span4.sticky.right {
		margin-left: 516px;
	}

	.span6.sticky.right {
	    margin-left: 392px;
	}

}

@media (max-width: 767px) {
	
	.span3.sticky.right {
		margin-left: 0;
	}

	.span4.sticky.right {
		margin-left: 0;
	}

	.span6.sticky.right {
	    margin-left: 0;
	}

}



@media (max-width: 767px) {
	
	.sticky {
		position: static;
		top: auto;
	}

}

.capa0 {
	z-index: 5;
}

.capa1 {
	z-index: 6;
}

.capa2 {
	z-index: 7;
}

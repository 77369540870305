.editorial-line {
  display: block;
  color: inherit;

  &:hover {
    text-decoration: none;

    .editorial-line__header {
      border-bottom-color: @linkColor;
    }
    
    .icon,
    .editorial-line__title {
      color: @linkColor;
    }

    .editorial-line__text {
      color: @textColor;
    }

  }

}

.editorial-line__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: @space-unit-xs;
  padding-bottom: @space-unit-xxxs;
  border-bottom: 2px solid @grayDark;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xsm;
  }

  .icon {
    display: inline-block;
    transition: .2s;
    margin-top: -@space-unit-xxxs;
  }

}

.editorial-line__title {
  .title-style;
  .font-size-title3;
  margin-bottom: 0;
  display: inline;
  transition: .2s;
}

.editorial-line__body {
  @media (max-width: @mobile-down) {
    display: none;
  }
  @media (min-width: @desktop) {
    display: flex;
    gap: @space-unit-sm;
  }
}

.editorial-line__text {

  > :last-child {
    margin-bottom: 0;
  }

}

.editorial-line__img {
  display: none;
  @media (min-width: @desktop) {
    width: 40%;
    display: block;
    flex-shrink: 0;
  }
  @media (min-width: @desktop-large) {
    width: 35%;
  }

}
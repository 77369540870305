/*doc
---
title: Página Preparar la visita
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-preparar-visita{
	margin:0;padding:40px 20px;
	font:400 13px/18px @sansFontFamily;
	background:@whiteDarker;color:@black;}
body.page-preparar-visita h1{
	margin:0 0 20px 0;
	font:400 38px/38px 'PFDinTextPro-Bold', sans-serif;}
body.page-preparar-visita h2{
	margin:0 0 10px 0;
	font:400 28px/28px @lightScout;}
body.page-preparar-visita h3{
	margin:0 0 10px 0;
	font:400 24px/24px @thinScout;}
body.page-preparar-visita h4{
	margin:0 0 5px 0;
	font:400 16px/16px @regularScout;
	text-transform:uppercase;}
body.page-preparar-visita h5{
	margin:0 0 5px 0;
	font:400 16px/16px @lightScout;}
body.page-preparar-visita h6{
	margin:0 0 5px 0;
	font:400 14px/14px @lightScout;
	text-transform: uppercase;}

body.page-preparar-visita a{color:#000; text-decoration:none;}

// Navegacion
body.page-preparar-visita div#navegacion{
	width:100%;position:fixed; top:0; left:0; z-index:1; padding:0;
	background:@grayDark;}
body.page-preparar-visita div#navegacion a{
	padding:0 10px 2px;display:block;
	font:700 13px/22px @sansFontFamily;
	color:@white;}
body.page-preparar-visita div#navegacion a:hover{
	background:@gray;}
body.page-preparar-visita div#navegacion a.imprimir{
	float:left;}
body.page-preparar-visita div#navegacion a.volver{
	float:right;}

// Pagina
body.page-preparar-visita div#pagina{
	max-width:640px;margin:0 auto;padding:50px 90px 90px 90px;
	background:@white;border:1px solid @grayLighter;}
body.page-preparar-visita div#cabecera{
	position:relative;padding:0 0 0 150px;}
body.page-preparar-visita div#cabecera img{
	position:absolute; top:0; left:0;}
body.page-preparar-visita div#cabecera h1{
	padding:10px 0 6px 0;
	border-bottom:4px solid @black;}
body.page-preparar-visita div#cabecera h1 small{
	font:400 22px/38px @lightScout;}

// Listados
body.page-preparar-visita ul.thumbnails{
	margin:0 0 60px 0;
	list-style:none;
	border-bottom:1px solid @grayLighter;}
body.page-preparar-visita ul.thumbnails > li{
	margin:0; float:none;}
body.page-preparar-visita ul.thumbnails .obra{
	margin:0;padding:20px 0;overflow:hidden;
	border-top:1px solid @grayLighter;}
body.page-preparar-visita ul.thumbnails div.imagen{
	width:200px; margin:0 20px 0 0; height:auto;float:left;}
body.page-preparar-visita ul.thumbnails div.imagen img{
	max-width:200px;max-height:200px;}
body.page-preparar-visita ul.thumbnails div.caption{
	margin-left:220px;
	font:400 14px/21px @sansFontFamily;}
body.page-preparar-visita ul.thumbnails div.field-name-field-obra-autor{
	font:700 14px/21px @sansFontFamily;}
body.page-preparar-visita ul.thumbnails .titulo{
	margin-bottom:0;
	font:400 14px/21px @sansFontFamily;
	text-transform:none; text-decoration:underline;}
body.page-preparar-visita ul.thumbnails .obra div.field-name-field-obra-datacion-texto{
	margin-bottom:0;
	font:400 14px/21px @sansFontFamily;
	color:@black;}

// Listados salas
body.page-preparar-visita ul.listado-salas{
	width:100%;margin:0 0 50px 0;overflow:hidden;
	list-style:none;}
body.page-preparar-visita ul.listado-salas li{
	width:30%;margin-bottom:5px;float:left;
	font:400 18px/18px @thinScout;}
body.page-preparar-visita ul.listado-salas li.limpiar{
	clear:both;}


// Plano
body.page-preparar-visita div.plano{
	margin-bottom:50px; min-height:inherit;}

// Salto de pagina
body.page-preparar-visita hr.salto-pagina{
	height:0; font-size:0; line-height:0; margin:0; padding:0; background:none; border:none;
	page-break-before:always;}
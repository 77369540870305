/*doc
---
title: Preparar Visita
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#preparar-visita {
	display: none;	
	background: @beige;
	border-bottom: 1px solid @grayLighter;
}

div#preparar-visita.desplegado {
	width: 100%;
	position: fixed;
	top: 33px;
	left: 0;
	z-index: 990;
}

div#preparar-visita > div.container {
	min-height: 213px;
	background: url(../images/bk.borde.gris.png) right top repeat-y;
}

div#preparar-visita > div.container > div.row {
	min-height: 213px;
	background: url(../images/bk.borde.gris.png) 810px 0px repeat-y;
}

@media (min-width: 1281px) {
	div#preparar-visita > div.container > div.row {
		background: url(../images/bk.borde.gris.png) 1015px 0px repeat-y;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	div#preparar-visita > div.container > div.row {
		background: url(../images/bk.borde.gris.png) 630px 0px repeat-y;
	}
	div#preparar-visita > div.container {
		background: none;
	}
}

@media (max-width: 767px) {
	div#preparar-visita {
		margin: 0 -20px;
		padding: 0 20px;
	}
	div#preparar-visita > div.container {
		background: none;
	}
}

// Preparar la visita

div#preparar-visita-tool {
	width: 225px;
	height: 32px;
	overflow: hidden;
	float: left;
}

// Link menu

#btn-preparar-visita {
	padding: 0 10px 2px 25px;
	display: block;
	float: left;
	position: relative;
	font: 700 13px/30px @sansFontFamily;
	color: @grayDark;
	border-left: 1px solid darken(@mostaza, 10%);
	&:hover {
		text-decoration: none;
		background-color: @mostazaHover;
	}
	&:before {
		.spritePadded(sprite1, 2, 4, 16px, 16px);
		left: 4px;
		top: 8px;
	}
	 .caret {
		border-top: 4px solid @grayDark;
		vertical-align: middle;
		opacity: 1;
		filter: none;
	}
}

// IE7

.lt-ie8 a#btn-preparar-visita {
	background: url(../images/ico.anadir.visita.png) 4px 5px no-repeat;

}

a#btn-preparar-visita.active {
	background: @grayDark;
	color: @white;
	&:hover {
		text-decoration: none;
		background: @beigeHover;
		color: @grayDark;
	}
	.caret {
		border-top: 4px solid @white;
	}
}

// Contador

div#btn-visita-counter {
	float: left;
}

span#counter {
	width: 24px;
	height: 32px;
	display: inline-block;
	overflow: hidden;
	position: relative;
	top: 0;
	float: left;
	line-height: 32px;
	border-left: 1px solid darken(@mostaza, 10%);
	border-right: 1px solid darken(@mostaza, 10%);
	background: @white;
}

span.btn-visita-counter-number {
	width: 24px;
	height: 32px;
	display: block;
	font: 700 13px/32px @sansFontFamily;
	text-align: center;
	color: @grisClaro;
}

div#btn-visita-counter span.counter-number-active {
	background: @grayDark;
	color: @white;
}

span.counter-number-active span.btn-visita-counter-number {
	color: @white;
}

span#btn-visita-counter-text {
	padding-left: 5px;
	display: inline-block;
	float: left;
	font: 400 11px/32px @sansFontFamily;
	color: darken(@mostaza, 25%);
}

// Obras

p#numero-obras {
	padding-top: 3px;
	font: 400 18px/18px @regularScout;
}

div#carrusel-visita {
	padding-top: 10px;
}

// Mensaje 0 obras

div#cart-carousel-empty {
	padding-left: 350px;
	height: 167px;
	background: url(../images/bk.lupa.visita.png) left bottom no-repeat;
}

div#cart-carousel-empty p {
	padding-top: 50px;
	@media (min-width: 768px) and (max-width: 979px) {
		padding-top: 0;
	}
}

div#preparar-visita.sin-obras div#acciones-visita {
	display: none;
}

div#preparar-visita.sin-obras  > div.container {
	background: none;
}

div#preparar-visita.sin-obras > div.container > div.row {
	background: none;
}

// Paginado

div#preparar-visita div.pagination {
	margin: 0 0 10px 0;
	height: 28px;
	float: right;
	text-align: right;
	border-left: 1px solid @grayLighter;
	background: @white;
}

div#preparar-visita div.pagination ul {
	background: @white;
}

div#preparar-visita div.pagination a,
div#preparar-visita div.pagination span {
	padding: 0 9px;
	float: left;
	line-height: 26px;
	border-width: 1px 1px 1px 0;
	border-style: solid;
	border-color: @grayLighter;
}

div#preparar-visita .current {
	background: @whiteDarker;
	color: @grisClaro;
}

// Fotos

div#preparar-visita ul.thumbnails {
	margin-left: -6px;
}

div#preparar-visita ul.thumbnails > li {
	width: 106px;
	margin: 0 0 10px 6px;
}

div#preparar-visita ul.thumbnails .obra {
	height: 150px;
	padding: 14px 14px 3px 14px;
	position: relative;
	background: @white;
	border: 1px solid @grayLighter;
}

div#preparar-visita ul.thumbnails .obra a.eliminar {
	width: 16px;
	height: 15px;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	.sprite(sprite1, 0,6);
	&:hover {
		.sprite(sprite1, 0,5);
		text-decoration: none;
	}
}

div#preparar-visita ul.thumbnails .obra a.eliminar span {
	display: none;
}

div#preparar-visita ul.thumbnails .imagen {
	width: 76px;
	height: 76px;
	overflow: hidden;
}

div#preparar-visita ul.thumbnails .imagen img {
	max-width: 76px;
	max-height: 76px;
}

div#preparar-visita ul.thumbnails div.caption {
	padding: 2px 0;
}

div#preparar-visita ul.thumbnails div.field-name-field-obra-autor {
	font: 700 11px/13px @sansFontFamily;
}

div#preparar-visita ul.thumbnails .titulo {
	font: 400 11px/13px @sansFontFamily;
}

@media (min-width: 1281px) {
	div#preparar-visita ul.thumbnails > li {
		width: 133px;
	}
	div#preparar-visita ul.thumbnails .obra {
		height: 165px;
	}
	div#preparar-visita ul.thumbnails .imagen {
		width: 103px;
		height: 103px;
	}
	div#preparar-visita ul.thumbnails .imagen img {
		max-width: 103px;
		max-height: 103px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	div#preparar-visita ul.thumbnails {
		margin-left: -2px;
	}
	div#preparar-visita ul.thumbnails > li {
		width: 84px;
		margin: 0 0 10px 2px;
	}
	div#preparar-visita ul.thumbnails .obra {
		height: 140px;
	}
	div#preparar-visita ul.thumbnails .imagen {
		width: 54px;
		height: 54px;
	}
	div#preparar-visita ul.thumbnails .imagen img {
		max-width: 54px;
		max-height: 54px;
	}
}

@media (max-width: 767px) {
	div#preparar-visita {
		display: none!important;
	}
}

// Acciones

div#acciones-visita {
	padding: 45px 10px 0 0;
}

div#acciones-visita p {
	margin: 0 0 7px 0;

	font: 400 12px/14px @sansFontFamily;
}

div#acciones-visita a.btn {
	display: block;

	font: 700 12px/14px @sansFontFamily;

	text-align: left;
}

div#acciones-visita .icon-white {
	float: right;
}

@media (min-width: 1281px) {
	div#acciones-visita {
		padding: 45px 15px 0 0;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	div#acciones-visita a.btn {
		font: 700 11px/13px @sansFontFamily;
	}
	div#acciones-visita .icon-white {
		display: none;
	}
}

@media (max-width: 767px) {
	div#acciones-visita {
		padding: 10px 0;
		border-top: 1px solid @grayLighter;
	}
	div#acciones-visita a.btn {
		display: inline-block;
		text-align: center;
	}
	div#acciones-visita .icon-white {
		float: none;
	}
}

// Icono anadir

a.visita-action {
	width: 10px;
	height: 16px;
	padding-left: 18px;
	display: inline-block;
	line-height: 1;
	color: @grayDark;
	.sprite(sprite1, 2,4);
	&:hover {
		text-decoration: none;
	}
	span {
		width: 12px;
		height: 12px;
		display: block;
		margin-left: -4px;
		margin-top: -2px;
		line-height: 9px;
		text-align: center;
		font-weight: bold;
		background: url(../images/bk.anadir-obra.png) 0 0 no-repeat;
	}
}

// IE8

.lt-ie9 a.visita-action span {
	width: 10px;
	height: 11px;
	padding-left: 2px;
	padding-top: 1px;
}

ul.thumbnails div.caption a.visita-action:hover {
	text-decoration: none;
}

.thumbnail a.visita-action {
	margin: 5px 0;
}

div#autores-colaboradores a.visita-action {
	position: absolute;
	right: 0;
	top: 9px;
}

// Rueda de progreso anadir a visita

div.ajax-progress {
	height: 16px;
	margin: 5px 0;
	display: inline-block;
	line-height: 1;
}

div.ajax-progress div.throbber {
	margin: 0;
	float: none;
    background: transparent url(../images/custom-ajax.gif) no-repeat 0 0;
}

// Rueda de progreso anadir a visita en snippet de obra

ul.thumbnails div.caption a.progress-disabled {
	display: none;
}

// Rueda de progreso anadir a visita en ficha de obra

div#autores-colaboradores a.progress-disabled {
	display: none;
}

div#autores-colaboradores div.ajax-progress {
	position: absolute;
	right: 5px;
	top: 4px;
}
.file-download {
	margin: 0;
	padding-left: 40px;
	position: relative;
	font-size: @baseFontSize;

	.file-icon {
		position: absolute;
		top: 0;
		left: 0;
		
	}

	.fecha-autor {
		margin-bottom: 0;
		font-size: @smallFontSize;
		color: @grisClaro;
	}

}

.artworks-list {
  display: grid;
  grid-gap: @gridGutterWidth;
  grid-template-rows: repeat( auto-fit, minmax(0, max-content) );
  grid-template-columns: repeat(2, minmax(0, 2fr));
  grid-auto-flow: row;
  @media (min-width: @tablet) {
    grid-gap: @space-unit-lg;
  }
}
#autores .artworks-list {
  display: block;
}

.artworks-list--inverse {

  .artworks-list__snippet {
    &:hover {
      .artworks-list__snippet-antetitle,
      .artworks-list__snippet-title,
      .artworks-list__snippet-subtitle {
        color: #fff;
      }
  
    }
  }
  
  .artworks-list__snippet-antetitle,
  .artworks-list__snippet-title,
  .artworks-list__snippet-subtitle {
    color: #fff;
  }
  
  .artworks-list__snippet-text {
    color: @textColorInverse;
  }

}

.artworks-list--horizontal {
  @media (min-width: @tablet) {    
    grid-template-rows: repeat(2, minmax(0, 2fr));
    grid-template-columns: repeat( auto-fit, minmax(0, max-content) );
    grid-auto-flow: column;
  }

  .artworks-list__item {
    @media (min-width: @tablet) {
      grid-column-start: initial;
      grid-column-end: initial;
      grid-row-start: 1;
      grid-row-end: 3;
      margin-top: auto;
      margin-bottom: auto;
    }
    
    img {
      max-width: 90vw;
    }

  }

  .artworks-list__row-items {
    @media (min-width: @tablet) {
      grid-template-rows: initial;
      grid-auto-flow: initial;
      grid-template-columns: repeat( auto-fit, minmax(0, max-content) );
      grid-auto-flow: column;
    }

    img {
      @media (min-width: @tablet) {
        max-height: @img-small-full-screen-max-height;
      }
    }

  }

  .artworks-list__snippet {
    @media (min-width: @tablet) {
      grid-template-columns: repeat( 2, minmax(0, max-content) );
    }
  }

}

.artworks-list__item {
  grid-column-start: 1;
  grid-column-end: 3;

  img {
    max-width: 100%;
    max-height: 60vh;
    @media (min-width: @tablet) {
      max-height: @img-full-screen-max-height;
    }
  }

}

.artworks-list__row-items {
  display: grid;
  grid-gap: @gridGutterWidth;
  grid-template-rows: repeat( auto-fit, minmax(0, max-content) );
  grid-auto-flow: row;
  @media (min-width: @tablet) {
    grid-gap: @space-unit-lg;
  }
}

.artworks-list__snippet {
  display: grid;
  grid-gap: @space-unit-xxs;
  color: inherit;
  @media (min-width: @tablet) {
    grid-gap: @space-unit-sm;
    align-items: end;
  }
  &:hover {
    color: inherit;
    text-decoration: none;

    .artworks-list__snippet-title,
    .artworks-list__snippet-subtitle {
      color: @linkColor;
    }

  }
}

.artworks-list__snippet-media {
  @media (min-width: @tablet) {
    display: flex;
    align-items: center;
  }
}

.artworks-list__snippet-header {
  &:not(:last-child) {
    margin-bottom: @space-unit-xxs;
  }
}

.artworks-list__snippet-antetitle {
  color: @grayDark;
  transition: .2s;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}

.artworks-list__snippet-caption {
  width: 270px;

  .artworks-list__row-items & {
    width: 40vw;
    @media (min-width: @tablet) {
      width: 20vw;  
    }
  }

  .artworks-list--horizontal .artworks-list__row-items & {
    @media (min-width: @tablet) {
      width: 270px;
    }
  }

}

.artworks-list__snippet-title {
  overflow: hidden;
  text-overflow: ellipsis;
  color: @grayDark;
  transition: .2s;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}

.artworks-list__snippet-subtitle {
  color: @grayDark;
  transition: .2s;
}

.artworks-list__snippet-text {
  font-size: @smallFontSize;
  line-height: @smallLineHeight;
  transition: .2s;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}
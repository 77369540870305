ul.breadcrumb{
	margin: 0;
	padding: 5px 0px;
	background: transparent;
	box-shadow: none;
	border: none;
	.border-radius(0);
	filter: none;

	li {
		font-size: 13px;
		line-height: 15px;
		color: @grayLighter;
		text-shadow: none;
		
		a {
			font-weight: normal;
			color: @gray;
			&:hover,
			&:focus {
				color: @grayDark;
			}
		}

	}

}




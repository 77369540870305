@collapse-mobile-gradient-height: 100px;

.collapse-mobile__collapse { 
  @media (min-width: @tablet) {
    height: auto;
  }
  &.in + .collapse-mobile__button {
    &::before {
      content: none;
    }
    .collapse-mobile__button-icon {
      transform: rotate(-90deg);
    }
  }
}

.collapse-mobile__collapse-inner {
  @media (max-width: @mobile-down) {
    padding-bottom: @space-unit-xsm;
  }
}


.collapse-mobile__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  position: relative;
  font-weight: bold;
  color: @linkColor;
  text-decoration: none;
  border: none;
  background: none;
  transition: .2s;
  @media (min-width: @tablet) {
    display: none;
  }
  &:hover {
    color: @linkColorHover;
  }
  &:focus {
    outline: 0;
  }
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: @collapse-mobile-gradient-height;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
    z-index: 1;
  }
}

.collapse-mobile__button-icon {
  margin-left: @space-unit-xxxs;
  transform: rotate(90deg);
}

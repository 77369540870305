/*doc
---
title: Portada Restauración
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-coleccion-restauracion div#main > .span12 > div.row{
	background:url(../images/bk.borde.gris.png) 650px 0 repeat-y;}
@media (min-width: 1281px) {
body.page-coleccion-restauracion div#main > .span12 > div.row{
	background:url(../images/bk.borde.gris.png) 815px 0 repeat-y;}
}
@media (min-width: 768px) and (max-width: 979px) {
body.page-coleccion-restauracion div#main > .span12 > div.row{
	background:url(../images/bk.borde.gris.png) 505px 0 repeat-y;}
}
@media (max-width: 767px) {
body.page-coleccion-restauracion div#main > .span12 > div.row{
	background:none;}
}

// Bloque proyectos
div#restauracion-proyectos{
	margin-bottom:10px; padding-bottom:10px;
	border-bottom:1px solid @grayLighter;}
div#restauracion-proyectos ul.thumbnails{
	margin-bottom:10px;}
div#restauracion-proyectos h4.titulo{
	margin-bottom:5px;padding-top:0;
	text-transform:none;}
div#restauracion-proyectos h4.titulo a{
	color:@grayDark;}
div#restauracion-proyectos div.carousel div.item{
	text-align:left;}

// Bloque procesos
div#restauracion-procesos{
	margin-bottom:20px;}
div#restauracion-procesos ul.thumbnails{
	margin-bottom:10px;}
div#restauracion-procesos h4.titulo{
	margin-bottom:5px;padding-top:0;
	text-transform:none;}
div#restauracion-procesos h4.titulo a{
	color:@grayDark;}
div#restauracion-procesos div.carousel div.item{
	text-align:left;}

// Bloque publicaciones
div#restauracion-publicaciones{
	padding-top:2px;}

// Bloque actividades
div#restauracion-educacion {
  margin-bottom:10px;
  padding-bottom:10px;
  border-bottom:1px solid @grayLighter;
}
div#restauracion-educacion ul.bloque3{
  background:none;
  margin-bottom: 0;
}
@media (min-width: 1281px) {
div#restauracion-educacion ul.bloque3{
	background:none;}
}
@media (min-width: 768px) and (max-width: 979px) {
div#restauracion-educacion ul.bloque3{
	background:none;}
}
@media (max-width: 767px) {
div#restauracion-educacion ul.bloque3{
	margin-top:0;
	background:@white;border-bottom:none;}
div#restauracion-educacion ul.bloque3 > li{
	margin-bottom:10px;padding:0 0 10px 0;
	border-top:none;border-bottom:1px solid @grayLighter;}
div#restauracion-educacion ul.bloque3 .thumbnail > div.caption{padding:10px 10px 20px 10px;}
}
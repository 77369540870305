/*doc
---
title: Contenido principal
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si son necesarios estos estilos. En lugar de un componente serían más bien parte del archivo global/base.less</div>

*/

div#main{
	margin-bottom:40px;
}

.lt-ie9 div#main{
	overflow:hidden;
}
.breadcrumb-dark() {

  .breadcrumb .divider {
    color: @textColorInverse;
  }

  ul.breadcrumb li a {
    color: @textColorInverse;
    &:hover {
      color: #fff;
    }
  }

}
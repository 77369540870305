.site-submenu-item-style(){
    padding: @space-unit-xs;
    font-size: 12px;
    font-weight: 500;
    color: @grayDark;
    text-transform: uppercase;
    cursor: pointer;
    background-color: @whiteDarker;
    @media (min-width: @desktop-large) {
        padding: @space-unit-xs;
        font-size: 13px;
    }
    &:hover {
        color: @grayDark;
        text-decoration: none;
        background-color: darken(@whiteDarker, 5%);
    }
}
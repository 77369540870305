/*doc
---
title: Labels & Badges
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si se esta utilizando este componente de Bootstrap.</div>

*/

.label-info,
.badge-info {
	font:400 11px/14px @sansFontFamily;
	background:@grayDark;
	.border-radius(0);
}
/*doc
---
title: Global
category: Estilos basicos
---

Extendemos los estilos básicos "Utilities" de Bootstrap.

##Flotar una columna a la izquierda##

Si necesitamos flotar una columna a la izquierda para invertir el orden del layout, podemos utilizar el class `.pull-column-right`.

*/

.pull-column-right {
	float: right;
    @media (max-width: 767px) {
        float: none;
    }
}

/**
 * Clases para controlar la rejilla en función del tamaño de pantalla (es necesario que la rejilla sea fluída).
 */

.span-stacked-sm,
.row-fluid .span-stacked-sm {
	@media (min-width: 768px) and (max-width: 979px) {
		width: 100%;
		margin-left: 0;
		float: none;
	}
}

.span12\@only-tablet {
	@media (min-width: 768px) and (max-width: 979px) {
		width: auto;
		float: none;
	}
}

.offset0\@only-tablet {
	@media (min-width: 768px) and (max-width: 979px) {
		margin-left: 20px;
	}
}

/**
 * Clase para hacer que un elemento ocupe todo el ancho de la pantalla xs
 * (el elemento <body> en Bootstrap 2 tiene padding horizontal por defecto).
 */

.ancho-completo-xs {
	.ancho-completo-xs ();
}

.ancho-completo-without-padding-xs {
  .ancho-completo-without-padding-xs;
}

/**
 * Clase para quitar el margen a un elemento hijo.
 */

.hijo-sin-margen {
	> *:last-child {
		.no-margin-bottom ();
	}
}

/*doc
---
title: Thumbnails Radio
category: Componentes
---

Documentación...

*/

ul#results_list {

	.rrs {

		div.reproductor {
			height: 165px;
			@media (min-width: 1281px) {
				height: 203px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				height: 125px;
			}

			div.field {
				margin: 0;
			}

		}
		
	}

}

ul.thumbnails {

	.rrs {

		div.caption {

			.logo {
				margin-bottom: 3px;
			}
			
			p.autor {
				margin-bottom: 0;
				font-family: @thinScout;
				font-size: 18px;
				line-height: 1;	
				color: @grayDark;
			}

		}

	}

}
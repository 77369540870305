.container-xl {
    @media (min-width: @tablet) {
		padding-left: @space-unit-xsm;
        padding-right: @space-unit-xsm;
	}
    @media (min-width: @desktop) {
		padding-left: @space-unit-lg;
        padding-right: @space-unit-lg;
	}
    @media (min-width: @desktop-large) {
        max-width: 1560px;
        margin-left: auto;
        margin: auto;
    }
}
/*doc
---
title: Varios
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar cuales se utilizan.</div>

*/

// Fuertes
.limpiar{clear:both;}



// Fix Toolbar for .navbar-fixed-top
body.toolbar .navbar-fixed-top {
 top: 30px;
}

body.toolbar-drawer .navbar-fixed-top {
 top: 65px;
}

@media(min-width: 980px) {
  body.toolbar { padding-top: 90px !important; }
  body.toolbar-drawer { padding-top: 125px !important; }
}

html.overlay-open .navbar-fixed-top {
  z-index: 400;
}

// Admin toolbar fix
body.admin-expanded.admin-vertical.admin-nw .navbar,
body.admin-expanded.admin-vertical.admin-sw .navbar {
  margin-left: 260px;
}

div#admin-toolbar {
  z-index: 500;
}

.submitted {
  margin-bottom: 1em;
  font-style: italic;
  font-weight: normal;
  color: #777;
}

.toolbar-menu .icon {
  display: inline;
}

// System base fix
.container-inline div, .container-inline label {
  display: block;
}

// Default form fields to auto
input, textarea, select, .uneditable-input {
  width:auto;
}

// throbber fix
html.js input.form-autocomplete {
  background-position: 100% 7px;
  background-repeat: no-repeat;
}
html.js input.throbbing {
  background-position: 100% -13px;
}

.navbar-search .control-group {
  margin-bottom:0;
}

input.error {
  color: #B94A48;
  border-color: #B94A48;
}

.footer {
  margin-top: 45px;
  padding: 35px 0 36px;
  border-top: 1px solid #E5E5E5;
}

// Menu hover http://stackoverflow.com/questions/8878033/how-to-make-twitter-bootstrap-menu-dropdown-on-hover-rather-than-click

.sidebar-nav {
    padding: 9px 0;
}

.dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
}

.dropdown-menu li:hover .sub-menu {
    visibility: visible;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
    margin-top: 0;
}

.navbar .sub-menu:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
}
.navbar .sub-menu:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    top: 11px;
    left: -6px;
}

/*añadir a calendario*/
.addtocal {
  margin: 0;
  padding: 0;
  border: 0;
  float: none;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
}

//ajustes de backend que se ven en el front
span.date-display-end:after {
  content: "\000A";
  white-space: pre;
}
/*doc
---
title: Thumbnails Exposición
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	div.exposicion {

		div.fecha {
			margin-bottom: 5px;
			font-family: @lightScout;
			font-size: 14px;
			line-height: 1;
			color: @grayDark;

			.field {
				margin-bottom: 0;
			}

		}

		div.field-name-field-exposicion-lugar {
			font-weight: 700;
			font-size: 12px;
			line-height: 1.2;
			color: @grayLight;
		}

	}

}
@site-header-badge-size: 80px;
@site-header-badge-margin-left: @space-unit-xsm;

.site-header {
    .ancho-completo-xs ();
    padding-top: @space-unit-xxs;
    padding-bottom: @space-unit-xxs;
    @media (max-width: @mobile-down) {
        border-bottom: 1px solid @borderLighter;
    }
    @media (min-width: @tablet) {
        padding-top: @space-unit-sm;
        padding-bottom: @space-unit-sm;
    }
}

.site-header__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: @mobile-down) {
        align-items: center;
    }
}

.site-header__nav {
    display: flex;
    position: relative;
    &.site-header__nav-badge {
      @media (min-width: @desktop-large) {
        padding-right: @site-header-badge-size + @site-header-badge-margin-left;
      }
    }
}

.site-header__menus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.site-header__badge {
    .title-style;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: @site-header-badge-size;
    height: @site-header-badge-size;
    margin-left: @site-header-badge-margin-left;
    margin-top: -@space-unit-xxs;
    position: absolute;
    top: 0;
    right: 0;
    font-size: @fontScale1;
    background-color: @beige;
    border-radius: 100%;
    text-align: center;
    transition: @transition-base;
    transform: rotate(-20deg);
    @media (max-width: @desktop-down) {
        display: none;
    }

    &,
    &:hover {
        color: @grayDarker;
        text-decoration: none;
    }
    &:hover {
        background-color: @beigeHover;
    }
}

/*doc
---
title: Arzábal
category: Modulos
---

Estilos específicos para la landing del restaurante Arzábal.

*/

/**
 * Fuentes
 */

@font-face{
	font-family: 'novecento';
	src:url('../fonts/novecento/novecentowide-bold-webfont.eot');
	src:url('../fonts/novecento/novecentowide-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/novecento/novecentowide-bold-webfont.woff2') format('woff2'),
		url('../fonts/novecento/novecentowide-bold-webfont.woff') format('woff'),
		url('../fonts/novecento/novecentowide-bold-webfont.ttf') format('truetype'),
		url('../fonts/novecento/novecentowide-bold-webfont.svg#novecento') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face{
	font-family: 'novecento';
	src:url('../fonts/novecento/novecentowide-demibold-webfont.eot');
	src:url('../fonts/novecento/novecentowide-demibold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/novecento/novecentowide-demibold-webfont.woff2') format('woff2'),
		url('../fonts/novecento/novecentowide-demibold-webfont.woff') format('woff'),
		url('../fonts/novecento/novecentowide-demibold-webfont.ttf') format('truetype'),
		url('../fonts/novecento/novecentowide-demibold-webfont.svg#novecento') format('svg');
	font-weight: 600;
	font-style: normal;
}

/**
 * Básicos
 */

.landing-arzabal {
	padding-top: 30px;
	font-family: 'Montserrat', sans-serif;
	color: #4a2a6b;
}

.landing-arzabal p {
	font-family: inherit;
}

/**
 * Deepbanner
 */

.landing-arzabal__deepbanner {
	min-height: 400px;
	.margin-bottom-md ();
	position: relative;
	background: url('../images/arzabal/arzabal-deepbanner.jpg') no-repeat center center;
	background-size: cover;
	@media (max-width: 767px){
		min-height: inherit;
		margin: 0 -20px 30px;
		padding: 60px 20px;
		background: #5d3989;
	}
}

.landing-arzabal__deepbanner__slogan {
	max-width: 900px;
	.no-margin-bottom ();
	font-family: 'novecento';
	font-size: 40px;
	line-height: 1.2;
	font-weight: bold;
	text-align: center;
	letter-spacing: 8px;
	color: #fff;
	text-transform: uppercase;
	@media (min-width: 768px){
		.center ();	
	}
	@media (max-width: 767px){
		font-size: 22px;
	}
}

/**
 * Reservas
 */

.reservasinfobox {
	.margin-right-xs ();
	padding: 35px 20px 30px;
	border: #5d3989 solid 5px;
	font-family: 'novecento';
	color: #5d3989;
	font-weight: bold;
	text-transform: uppercase;
	@media (max-width: 767px){
		.no-margin-right ();
	}
	@media (max-width: 767px){
		margin-bottom: 30px;
		padding-left: 60px;
	}
	
	.verticaltext {
		position: relative;
		height: 0;

		.verticaltextinner {
			-ms-transform: rotate(270deg);
			-ms-transform-origin: 0% top;
			-webkit-transform: rotate(270deg);
			-webkit-transform-origin: 0% top;
			transform: rotate(270deg);
			transform-origin: 0% top;
			position: absolute;
			bottom: -60px;
			left: -55px;
			font-size: 1.214285714em;
			font-weight: 600;
			letter-spacing: 1px;
			@media (max-width: 767px){
				bottom: -35px;
			}
		}

	}

	.rib_text_1,
	.rib_text_2 { 
		position: relative;
		padding-left: 20px;
		font-size: 0.857142857em;
		letter-spacing: 2px;
		&:before{content: '';
			display: block;
			width: 14px;
			height: 3px;
			background: #4a2a6b;
			position: absolute;
			top: 5px;
			left: 0;
		}

		p {
			line-height: 1.2;
			margin-bottom: 5px;
		}

	}

	.rib_phone_1,
	.rib_phone_2{
		font-size: 1.857142857em;
	}

	.rib_phone_1{
		margin-bottom: 20px;
	}

	.italic{font-size: 1.285714286em;
		font-style: italic;
	}

}

/**
 * Destacados
 */

.landing-arzabal__destacado {
	.margin-bottom-sm ();
	position: relative;
}

.landing-arzabal__destacado__texto {
	position: absolute;
    width: 240px;
    top: 15px;
    padding: 15px 20px;
    @media (max-width: 767px) {
    	width: auto;
    	position: static;
    }
}

.landing-arzabal__destacado__titulo {
	padding-left: 40px;
	position: relative;
	font-family: 'novecento';
	font-size: 28px;
	font-weight: bold;
	letter-spacing: 7px;	
    text-transform: uppercase;
    &:before {
    	content: '';
	    display: block;
	    width: 30px;
	    height: 5px;
	    position: absolute;
	    top: 10px;
	    left: 0;
    }
}

.landing-arzabal__destacado__texto p {
	font-size: 12px;
	letter-spacing: 1px;
}

.landing-arzabal__destacado__btn {
	padding: 6px 10px 5px;
	display: inline-block;
	font-family: 'novecento';
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
	border: 5px solid #5a3d87;
	color: #5a3d87;
	&:hover {
		color: #fff;
		background-color: #5a3d87;
		text-decoration: none;
	}
}

.landing-arzabal__destacado--localizacion {
	padding: 7px 10px;
	background-color: #5e3989;
	@media (min-width: 1281px) {
		padding: 30px 20px 45px;
	}
	@media (min-width: 768px) and (max-width: 979px) {
		padding-bottom: 30px;
	}
	
	.landing-arzabal__destacado__imagen {
		@media (max-width: 767px) {
			max-width: 180px;
		}
	}

	.landing-arzabal__destacado__texto {
		padding-right: 0;
		right: 0;
		color: #fff;
		@media (max-width: 767px) {
			padding-top: 30px;
		}
	}

	.landing-arzabal__destacado__titulo {
		color: #fff;
		&:before {
			background-color: #fff;
		}
	}

}

.landing-arzabal__destacado__leyenda-mapa {
	position: absolute;
	color: #fff;
	font-size: 12px;
	letter-spacing: 1px;
}

.landing-arzabal__destacado__leyenda-mapa--1 {
	top: 100px;
	left: 260px;
	@media (min-width: 1281px) {
		top: 150px;
		left: 280px;
	}
	@media (min-width: 768px) and (max-width: 979px) {
		top: 80px;
		left: 270px;
	}
	@media (max-width: 767px) {
		top: 60px;
		left: 200px;
	}
	@media (max-width: 479px) {
		width: 72px;
	}
}

.landing-arzabal__destacado__leyenda-mapa--2 {
	top: 220px;
	left: 15px;
	@media (min-width: 768px) and (max-width: 979px) {
		top: 220px;
		left: 15px;
	}
	@media (max-width: 767px) {
		top: 165px;
		left: 10px;
	}
}

.landing-arzabal__destacado--carta {

	.landing-arzabal__destacado__texto {
		left: 15px;
		background: #fff;
		color: #000;
	}

	.landing-arzabal__destacado__titulo {
		color: #5a3d87;
		&:before {
			background-color: #5a3d87;
		}
	}

}

/**
 * Pie
 */

.langing-arzabal__pie__titulo {
	.margin-bottom-sm ();
	padding-top: 10px;
	font-size: 11px;
	letter-spacing: 1px;
	border-top: 3px solid #5a3d87;
	@media (max-width: 767px) {
		text-align: center;
	}
}

.langing-arzabal__pie__iconos-social {
	margin-top: 10px;
	float: right;
	@media (max-width: 767px) {
		.margin-top-sm ();
		.margin-bottom-sm ();
		float: none;
		text-align: center;
	}

	a {
		padding: 0 5px;

		&:hover {
			text-decoration: none;
		}	
	}

}

.langing-arzabal__pie__logo {
	.margin-right-lg ();
	.margin-bottom-xs ();
	float: left;
	@media (max-width: 767px) {
		display: inline-block;
		.margin-horizontal-sm ();
		float: none;
	}
	@media (max-width: 479px) {
		display: block;
		text-align: center;
	}
}
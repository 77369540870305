/*doc
---
title: Últimas adquisiciones
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#ultimas-adquisiciones div.row{
	margin-bottom:0;}
@media (max-width: 767px){
div#ultimas-adquisiciones{
	margin-bottom:20px;}
}
/*doc
---
title: Aviso Cookies
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

//#sliding-popup .popup-content #popup-text h2, #sliding-popup .popup-content #popup-text p{color:#888;}
//#popup-botones{padding:10px;}

#ot-sdk-btn.ot-sdk-show-settings {
  border-color: #ffffff !important;
  color: #ffffff !important;
  &:hover{
    background-color: #fff !important;
    border-color: #222222 !important;
    color: #222222 !important;
  }
}

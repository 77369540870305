.advertisement {
  .padding-xs ();
  background-color: @infoBackground;
}

.advertisement__title {
  .title-style;
  .font-size-title4;
  .margin-bottom-xxxs ();
  color: @infoText;
}

.advertisement__text {
  .margin-bottom-xxs ();
  font-family: @thinScout;
  color: @infoText;
}
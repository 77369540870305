.zoom-map {

    .leaflet-top {
        top: @space-unit-xxl;
  
        .leaflet-control {
            margin-top: 0;
        }
  
    }
  
    .leaflet-right {
        right: @space-unit-xxs;
        @media (min-width: @tablet) {
            right: @space-unit-xs;
        }
  
        .leaflet-control {
            margin-right: 0;
        }
  
    }
  
    .leaflet-control a,
    .leaflet-bar {
        border-radius: 0;
    }
  
}
  
.zoom-map__content {
    width: 100%;
    height: 100%;
    background-color: transparent;
}
  
.zoom-map__info {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    z-index: 1000;
    @media (min-width: @tablet) {
        left: 24px;
        bottom: 24px;
        right: 24px;
    }
}
  
.zoom-map__title {
    display: inline-block;
    padding: 8px 16px;
    font-size: @smallFontSize;
    color: #fff;
    background-color: @grayDarker;
}
  
/**
   * Transitions
*/
  
.zoom-map__content {
    opacity: 0;
    transition: opacity 1s .5s;
  
    .splash--open & {
      opacity: 1;
    }
}
  
.zoom-map__nav {
    opacity: 0;
    transition: opacity .5s 1.5s;
  
    .splash--open & {
      opacity: 1;
    }
  
}
  
.zoom-map__info {
    opacity: 0;
    transition: opacity .5s 1s;
  
    .splash--open & {
      opacity: 1;
    }
  
}
  
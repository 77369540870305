@section-horizontal-py: @space-unit-md; // 45px

.section-horizontal {
  box-sizing: border-box;
  display: grid;
  grid-gap: @space-unit-sm;
  padding: @section-horizontal-py @gridGutterWidth;
  background-color: @whiteDarker;
  @media (max-width: @mobile-down) {
    grid-template-rows: repeat( auto-fit, minmax(0, max-content) );
    grid-auto-flow: row;
    margin-left: -@gridGutterWidth;
    margin-right: -@gridGutterWidth;
  }
  @media (min-width: @tablet) {    
    grid-gap: @space-unit-lg;
    grid-template-columns: repeat( auto-fit, minmax(0, max-content) );
    grid-auto-flow: column;
    width: max-content;
    padding: @section-horizontal-py @space-unit-md;
  }
  @media (min-width: @desktop) {
    padding-left: @space-unit-lg;
    padding-right: @space-unit-lg;
  }
  @media (min-width: @desktop-large) {
    padding-left: @space-unit-xxl;
    padding-right: @space-unit-xxl;
  }
}

.section-horizontal--light {
  background-color: #fff;
}

.section-horizontal--dark {
  background-color: @grayDarker;

  .section-horizontal__title {
    @media (min-width: @tablet) {
      border-right-color: @gray;
    }
  }

  .section-horizontal__title-text {
    color: #fff;
  }

}

.section-horizontal__header {
  @media (min-width: @tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.section-horizontal__divider {
  @media (max-width: @mobile-down) {
    display: none;
  }
  @media (min-width: @tablet) {
    border-right: 1px solid @borderLighter;
  }
}

.section-horizontal__title {
  @media (min-width: @tablet) {
    position: relative;
    padding-left: @space-unit-md;
    border-right: 1px solid @borderLighter;
  }
}

.section-horizontal__title-text {
  .title-style;
  .font-size-title2;
  margin-bottom: 0;
  @media (min-width: @tablet) {
    padding-bottom: @space-unit-xxxs;
    transform: rotate(-90deg) translateY(-100%);
    transform-origin: top right;
    position: absolute;
    white-space: nowrap;
    right: 0;
    left: auto;
  }
}

.section-horizontal__action {
  display: flex;
  justify-content: center;
  @media (min-width: @tablet) {
    flex-direction: column;
  }
}
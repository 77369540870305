/*doc
---
title: Portada Biblioteca y Centro de Documentacion
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/


// Intro biblioteca
div#biblioteca-intro{
	margin-bottom:20px;}
div#biblioteca-intro .texto{
	padding-top:15px;}
div#biblioteca-intro .intro{
	font:400 18px/20px @thinScout;
	color:@grayDark;}

div#horarios-entidades-bibilioteca{
	padding:0 0 20px 0;
	border-bottom:1px solid @grayLighter;}
div#horarios-entidades-bibilioteca h3.titulo-bloque{
	padding-top:20px;
	font:400 20px/20px @thinScout;
	border-top:1px solid @grayLighter;}

div#biblioteca-intro div.preguntas-frecuentes{
	margin-bottom:10px;}
div#biblioteca-intro div.preguntas-frecuentes .btn{
	margin-bottom:5px;}
div#entidades-colaboradoras span{
	margin-bottom:2px;
	font:400 11px/13px @sansFontFamily;
	text-transform:uppercase;}
@media (max-width: 767px) {
div#biblioteca-intro div.bloque-intro p{
	padding:0 10px;}
div#biblioteca-intro div.preguntas-frecuentes{
	padding:0 10px;}
div#entidades-colaboradoras{
	padding:0 10px;}
}


// Coleccion de biblioteca
#coleccion-biblioteca li.limpiar{
	margin-left:0;}
ul.thumbnails a.destacado{
	position:relative; display:block;}
ul.thumbnails a.destacado span.caption{
	width:100%; min-height:30px;display:block;padding:5px;position:absolute; bottom:0; left:0;
	font:400 12px/15px @sansFontFamily;
	background:@grayDark; color:@white;
	opacity:0.8;filter:alpha(opacity=80);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing:border-box;}
ul.thumbnails a.destacado:hover span.caption{
	background:@beige;color:@grayDark;}
@media (max-width: 767px) {
ul.thumbnails a.destacado span.caption{
	position:static;padding:5px 0;
	background:none; color:@grayDark;
	opacity:1;filter:alpha(opacity=100);}
ul.thumbnails a.destacado:hover{
	text-decoration:none;}
ul.thumbnails a.destacado:hover span.caption{
	background:none;}
ul.thumbnails a.destacado span.caption:hover{
	text-decoration:underline;}
}

// Servicios de biblioteca
ul.lista-iconos{
	margin:0;
	list-style:none;}
ul.lista-iconos li{
	width:100%;overflow:hidden;margin-bottom:10px;}
ul.lista-iconos img{
	margin-right:10px; float:left;}

// Catalogo de biblioteca
div.destacado{
	position:relative;}
div.destacado .titulo{
	margin:-0;
	font:400 19px/19px @boldScout;
	color:@black;}
div.destacado .titulo .ver-mas{
	margin-bottom:-1px;}
div.destacado div.caption{
	width:100%;min-height:76px;display:block;padding:5px;position:absolute; bottom:0; left:0;
	font:400 12px/14px @sansFontFamily;
	background:@whiteDarker; color:@grayDark;
	opacity:0.95;filter:alpha(opacity=95);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing:border-box;}
div.destacado div.caption p{
	font:400 12px/17px @sansFontFamily;
	margin-bottom:5px;}
@media (min-width: 1281px) {
div.destacado div.caption p{
	font:400 13px/17px @sansFontFamily;}
}
@media (max-width: 767px) {
div.destacado{
	display:inline-block;}
}
@media (max-width: 480px) {
div.destacado{
	display:block;}
}

// Ajustes nuevo diseno
#coleccion-biblioteca ul.thumbnails a.destacado,
#archivo-archivos ul.thumbnails a.destacado{
	background:@black;}
#coleccion-biblioteca ul.thumbnails a.destacado img,
#archivo-archivos ul.thumbnails a.destacado img{
	opacity:.4;}
#coleccion-biblioteca ul.thumbnails a.destacado:hover img,
#archivo-archivos ul.thumbnails a.destacado:hover img{
	opacity:1;}
#coleccion-biblioteca ul.thumbnails a.destacado span.caption,
#archivo-archivos ul.thumbnails a.destacado span.caption{
	min-height:50px;
	padding:10px;
	bottom:10px;
	font:400 14px/16px @boldScout;
	opacity:0.9;}
#archivo-archivos ul.thumbnails a.destacado span.caption{
	padding:5px 10px;}


#biblioteca-catalogo,
#biblioteca-ayuda{
	margin-bottom:30px;}


// Novedades centro documentacion
div#novedades-centro-documentacion{
	margin-bottom:20px;}
div#novedades-centro-documentacion .texto{
	padding-top:15px;}
div#novedades-centro-documentacion .intro{
	font:400 18px/20px @thinScout;
	color:@grayDark;}

// Recursos y servicios centro documentacion
ul.lista-accesos{
	margin:0;
	border-top:1px solid @grayLighter;}
ul.lista-accesos li{
	list-style-type:none;
	border-bottom:1px solid @grayLighter;}
ul.lista-accesos li:hover{
	background-color:@grayHover;}
ul.lista-accesos li a{
	margin-right:5px;padding:5px 20px 5px 5px;display:block;
	font:400 13px/18px @sansFontFamily;
	color:@gray;background:url(../images/ico.flecha.ir.png) right center no-repeat;}
ul.lista-accesos li a:hover{
	text-decoration:none;}

// Servicios de Centro de documentación
div#recursos-servicios-centro-documentacion{
	border-top:4px solid @grayDark;}
div#recursos-servicios-centro-documentacion .titulo-bloque{
	border-top:none;}
div#recursos-servicios-centro-documentacion ul.lista-accesos{
	border-top:none;}

// Atención visitante
div#proyectos-actividades-exposiciones div.row{
	margin-bottom:0;}

#archivo-archivos{
	margin-bottom:30px;}
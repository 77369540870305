.accordion-list {
    margin-bottom: @space-unit-sm;
}

.accordion-list__item {
    .ancho-completo-xs;
    border-color: @borderLighter;
    border-style: solid;
    border-width: 0 0 1px;
    @media (min-width: @tablet) {
        border-width: 0 1px 1px;
    }
    &:first-child {
        border-width: 1px;
    }
}

.accordion-list__heading {
    .ancho-completo-xs;
    padding-top: @space-unit-sm;
    padding-bottom: @space-unit-sm;
    @media (min-width: @tablet) {
        padding: @space-unit-sm;
    }
}

.accordion-list__collapse {
    .ancho-completo-xs;
    background-color: @whiteDarker;
}

.accordion-list__body {
    padding-top: @space-unit-sm;
    padding-bottom: @space-unit-sm;
    @media (min-width: @tablet) {
        padding: @space-unit-sm;
    }
}
/*doc
---
title: Thumbnails Vídeo
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.video {

		div.field-name-field-video-video {
			margin: 0;
			height: 165px;
			@media (min-width: 1281px) {
				height: 203px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				height: 125px;
			}
		}

	}

}
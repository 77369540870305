.block-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: @space-unit-xxs;
}

.block-price__content {
    > :last-child {
        margin-bottom: 0;
    }
}

.block-price__title {
    margin-bottom: @space-unit-xs;
}

.block-price__text {
    margin-bottom: @space-unit-xxs;
}

.block-price__price {
    flex-shrink: 0;
    margin-left: @space-unit-xxxs;
    @media (min-width: @tablet) {
        margin-left: @space-unit-xs;
    }
}
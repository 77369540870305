.header-section {
  max-width: 35em;
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.header-section--dark {
  
  .header-section__antetitle,
  .header-section__title,
  .header-section__subtitle {
    color: #fff;
  }

  .header-section__intro {
    color: @textColorInverse;
  }

  .header-section__breadcrumbs {
    .breadcrumb-dark();
  }

}

.header-section__breadcrumbs {
  margin-bottom: @space-unit-xs;
}

.header-section__heading {
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.header-section__antetitle {
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.header-section__title {
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}

.header-section__subtitle {
  margin-bottom: 0;
}

.header-section__intro {
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.header-section__action {
  padding-top: @space-unit-xxs;
}

.search-box {
  @media (max-width: @mobile-down) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: @tablet) {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: @space-unit-xxs;
  }

  .views-exposed-widget,
  #label-edit-bundle {
    display: none;
  }

  input,
  select {
    box-sizing: content-box;
    height: 36px;
    @media (min-width: @tablet) {
      height: auto;
      margin-bottom: 0;
      padding-left: @space-unit-xs;
      padding-right: @space-unit-xs;
    }
  }

}

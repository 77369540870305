.data-secondary {
    color: @grayLight;
    &:not(:last-child) {
      margin-bottom: @space-unit-xs;
    }
}
#secondary-data .field-label, //Retrocompatibilidad con html viejo
.data-secondary__title {
    margin-bottom: 2px;
    font-size: @smallFontSize;
    font-weight: bold;
}
#secondary-data p, //Retrocompatibilidad con html viejo
.data-secondary__desc {
    font-size: @smallFontSize;
}

/*doc
---
title: Tablas
category: Estilos basicos
---

Documentación...

*/

.table{
	border-bottom:1px solid @grayLighter;}
.table p,
.table ul{
	margin-bottom:0;}
td,
th{
	padding:2px 10px 2px 0px;
	vertical-align:top;}
.table-bordered{
	.border-radius(0);}
.table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child{
	border-top-left-radius:0;}
.table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child{
	border-radius:0;}
table.table tr:hover td{
	background:none;}
td.rss{
	width:160px;}
td.rss.hijo{
	width:140px; padding-left:20px;}
td.iconos{
	min-width:130px;}
td.iconos a:hover{
	text-decoration:none;}
/*doc
---
title: Espacio en blanco
category: Estilos basicos
---


Para asignar a un elemento una cantidad de espacio en blanco determinada utilizaremos los mixins del tipo ["Espacios en blanco"](/docs/mixins.html#Espacios_en_blanco).

Para modificar sus valores disponemos de una serie de variables en el fichero `less/variables.less` bajo el título "Espacio en blanco".

En caso de que sea necesario utilizar este tipo de márgenes para dar formato a un contenido introducido mediante WYSWYG, disponemos de las siguientes clases. Se trata de márgenes inferiores que nos sirven para separar bloques.

```html_example
<div class="well margen-muy-pequeno">Margen muy pequeño</div>
<div class="well margen-pequeno">Margen pequeño</div>
<div class="well margen-mediano">Margen mediano</div>
<div class="well margen-largo">Margen largo</div>
<div class="well margen-muy-largo">Margen muy largo</div>
```

NOTA: Ignora el estilo `.well` , sólo sirve para diferenciar los bloques entre sí.

*/

.margen-extra-pequeno {
	margin-bottom: @space-unit-xs / 2;
}

.margen-muy-pequeno {
	.margin-bottom-xs ();
}

.margen-pequeno {
	.margin-bottom-sm ();
}

.margen-mediano {
	.margin-bottom-md ();
}

.margen-largo {
	.margin-bottom-lg ();
}

.margen-muy-largo {
	.margin-bottom-xl ();
}

.sin-margen {
	.no-margin-bottom ();
}

.sin-margen--screen-small {
	@media (max-width: 767px) {
		.no-margin-bottom ();
	}
}

.margin-bottom-xxxs {
	margin-bottom: @space-unit-xxxs !important;
}

.margin-bottom-xsm {
  margin-bottom: @space-unit-xsm !important;
}

.margin-bottom-xxs\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xxs !important;
  }
}

.margin-bottom-xs {
  margin-bottom: @space-unit-xs !important;
}

.margin-bottom-sm {
  margin-bottom: @space-unit-sm !important;
}


.margin-bottom-sm\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-sm !important;
  }
}

.margin-bottom-md {
  margin-bottom: @space-unit-md !important;
}

.margin-bottom-md\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-md !important;
  }
}

.margin-bottom-lg {
  margin-bottom: @space-unit-lg !important;
}

.margin-bottom-lg\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-lg !important;
  }
}

.margin-bottom-xl {
  margin-bottom: @space-unit-xl !important;
}

.margin-bottom-xl\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xl !important;
  }
}

.margin-bottom-xxl\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xxl !important;
  }
}

.margin-bottom-xs\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xs !important;
  }
}

.margin-bottom-xs\@tablet-down {
  @media (max-width: @tablet-down) {
    margin-bottom: @space-unit-xs !important;
  }
}

.margin-bottom-xs\@mobile-down {
  @media (max-width: @mobile-down) {
    margin-bottom: @space-unit-xs !important;
  }
}

.margin-bottom-xsm\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xsm !important;
  }
}

.margin-top-xsm {
  margin-top: @space-unit-xsm !important;
}

.margin-top-sm\@tablet {
  @media (min-width: @tablet) {
    margin-top: @space-unit-sm !important;
  }
}

.margin-left-xs {
  margin-left: @space-unit-xs !important;
}

.margin-right-xxxs {
  margin-right: @space-unit-xxxs !important;
}

.margin-right-xxs {
  margin-right: @space-unit-xxs !important;
}

.margin-right-xs {
  margin-right: @space-unit-xs !important;
}

.margin-horizontal-xs {
  margin-left: @space-unit-xs !important;
  margin-right: @space-unit-xs !important;
}

.n-margin-horizontal-sm\@tablet {
  @media (min-width: @tablet) {
    margin-left: -@space-unit-sm !important;
    margin-right: -@space-unit-sm !important;
  }  
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-bottom\@tablet {
  @media (min-width: @tablet) {
    margin-bottom: 0 !important;
  }
}

.no-margin-bottom\@desktop {
  @media (min-width: @desktop) {
    margin-bottom: 0 !important;
  }
}

.no-margin-bottom\@mobile-down {
  @media (max-width: @mobile-down) {
    margin-bottom: 0 !important;
  }
}

.padding-muy-pequeno {
  padding: @space-unit-xs !important;
}

.padding-xsm {
  padding: @space-unit-xsm !important;
}

.padding-sm\@tablet {
  @media (min-width: @tablet) {
    padding: @space-unit-sm !important;
  }
}

.padding-md\@tablet {
  @media (min-width: @tablet) {
    padding: @space-unit-md !important;
  }
}

.padding-md\@desktop {
  @media (min-width: @desktop) {
    padding: @space-unit-md !important;
  }
}

.padding-top-xxxs {
  padding-top: @space-unit-xxxs !important;
}

.padding-top-sm {
  padding-top: @space-unit-sm !important;
}

.padding-top-md {
  padding-top: @space-unit-md !important;
}

.padding-top-lg {
  padding-top: @space-unit-lg !important;
}

.padding-top-md\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-md !important;
  }
}

.padding-top-lg\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-lg !important;
  }
}

.padding-top-xxl\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-xxl !important;
  }
}

.padding-vertical-md {
  padding-top: @space-unit-md !important;
  padding-bottom: @space-unit-md !important;
}

.padding-vertical-md\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-md !important;
    padding-bottom: @space-unit-md !important;
  }
}

.padding-vertical-lg\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-lg !important;
    padding-bottom: @space-unit-lg !important;
  }
}

.padding-vertical-xl\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-xl !important;
    padding-bottom: @space-unit-xl !important;
  }
}

.padding-vertical-xxl\@tablet {
  @media (min-width: @tablet) {
    padding-top: @space-unit-xxl !important;
    padding-bottom: @space-unit-xxl !important;
  }
}

.padding-horizontal-xxs {
  padding-left: @space-unit-xsm !important;
  padding-right: @space-unit-xsm !important;
}

.padding-bottom-lg {
  padding-bottom: @space-unit-lg !important;
}

.last-child-no-margin-bottom :last-child {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.padding-horizontal-xxs\@mobile-down {
  @media (max-width: @mobile-down) {
    padding-left: @space-unit-xxs !important;
    padding-right: @space-unit-xxs !important;
  }
}

.padding-horizontal-xsm\@tablet {
  @media (min-width: @tablet) {
    padding-left: @space-unit-xsm !important;
    padding-right: @space-unit-xsm !important;
  }
}

.padding-horizontal-sm\@tablet {
  @media (min-width: @tablet) {
    padding-left: @space-unit-sm !important;
    padding-right: @space-unit-sm !important;
  }
}

.padding-horizontal-md\@tablet {
  @media (min-width: @tablet) {
    padding-left: @space-unit-md !important;
    padding-right: @space-unit-md !important;
  }
}

.padding-horizontal-lg\@tablet {
  @media (min-width: @tablet) {
    padding-left: @space-unit-lg !important;
    padding-right: @space-unit-lg !important;
  }
}

.padding-horizontal-lg\@desktop {
  @media (min-width: @desktop) {
    padding-left: @space-unit-lg !important;
    padding-right: @space-unit-lg !important;
  }
}

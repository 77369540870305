.site-logo {
    display: inline-flex;
    flex-shrink: 0;
}

.site-logo__optional-heading {
    margin-bottom: 0;
    line-height: 0;
}

.site-logo__link {
    display: inline-block;
    line-height: 0;
    color: #000;
}

.site-logo__img {
    
    svg,
    path {
        fill: currentColor;
    }

    svg {
        height: 50px;
        @media (min-width: @tablet) {
            height: 60px;
        }
    }

}

.site-logo__text {
    .sr-only ();
}

/*doc
---
title: Listas
category: Estilos basicos
---

Documentación...

*/

dl {
	margin: 0;
	&:not([class]) {
    &:not(:last-child) {
			margin-bottom: @space-unit-xs;
		}
	}
}

dd {
	margin-left: 0;
	&:not([class]) {
    &:not(:last-child) {
			margin-bottom: @space-unit-xxs;
		}
	}
}

.dl-inline {
	dt {
		display: inline;
  	margin-right: @space-unit-xxxs;
	}
	dd {
		display: inline;
		&:not(:last-child) {
			&:after {
				height: @space-unit-xxs;
				content: "";
				display: block;
			}
		}
	}
}

.lista-enlaces {
	.no-margin-left ();
	.margin-bottom-sm ();
	list-style: none;
	li {
		margin-bottom: 10px;
	}
}

.lista-enlaces--claros {
	a {
		color: lighten(#fff, 10%);
	}
}
/*doc
---
title: JPlayer
category: Componentes
---

Documentación...

*/

div.jp-audio{
	width:100%;margin-bottom:0;}
div.jp-interface{
	width:100%;position:relative;
	background-color:#fff;}
div.jp-audio div.jp-type-playlist div.jp-interface{
	height:35px;}
div.jp-interface ul.jp-controls {
	margin:0;padding:0;
	list-style-type:none;}
div.jp-interface ul.jp-controls li {
	display:inline;}
div.jp-interface ul.jp-controls a {
	position: absolute;overflow:hidden;text-indent:-9999px;}
a.jp-play,
a.jp-pause,
a.jp-next,
a.jp-previous {
	width:16px;height:16px;z-index:1;}
div.jp-audio div.jp-type-playlist a.jp-play,
div.jp-audio div.jp-type-playlist a.jp-pause {
	top:0;left:0;}
div.jp-audio div.jp-type-playlist .jp-controls-playlist a.jp-play,
div.jp-audio div.jp-type-playlist .jp-controls-playlist a.jp-pause {
	top:0;left:21px;}


a.jp-play {
	background: url("../jplayer/player_RRS.png") 0 0 no-repeat;}
a.jp-play:hover {
	background: url("../jplayer/player_RRS.png") -16px 0 no-repeat;}

a.jp-previous {
	background: url("../jplayer/player_RRS.png") 0 -64px no-repeat;}
a.jp-previous:hover {
	background: url("../jplayer/player_RRS.png") -16px -64px no-repeat;}
div.jp-audio div.jp-type-playlist a.jp-previous {
	top:0;left:0px;}

a.jp-next {
	background: url("../jplayer/player_RRS.png") 0 -48px no-repeat;}
a.jp-next:hover {
	background: url("../jplayer/player_RRS.png") -16px -48px no-repeat;}
div.jp-audio div.jp-type-playlist a.jp-next {
	top:0;left:63px;}

a.jp-pause {
	background: url("../jplayer/player_RRS.png") 0 -32px no-repeat;display: none;}
a.jp-pause:hover {
	background: url("../jplayer/player_RRS.png") -16px -32px no-repeat;}

div.jp-audio div.jp-type-playlist a.jp-stop {
	top:0;left:21px;}
div.jp-audio div.jp-type-playlist .jp-controls-playlist a.jp-stop{
	top:0;left:42px;}

a.jp-stop {
	width:16px;height:16px;background: url("../jplayer/player_RRS.png") 0 -16px no-repeat;z-index:1;}
a.jp-stop:hover{
	background: url("../jplayer/player_RRS.png") -16px -16px no-repeat;}

div.jp-progress{
	width:100%;height:10px;position: absolute;overflow:hidden;top:20px;left:0;
	border:1px solid #333;background-color: #fff;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;}
div.jp-play-bar{
	height:15px;
	background:#000000;}

div.jp-current-time {
	position:absolute;top:0;left:45px;
	font-size:16px;font-weight:bold;
    line-height: 1.2;
}
div.jp-current-time-playlist{
	left:85px;
}

div.jp-duration {
	min-height:15px;padding-left:22px; padding-top:5px;position: absolute; top:-1px; right:0; left:auto;
	font-size:10px;
	background:url(../images/ico.reloj.png) 0 0 no-repeat;}

.jp-playlist{
	font: 400 13px/14px @sansFontFamily;
}
.jp-playlist-info{
	font: 400 11px/13px @sansFontFamily;
}

div.jp-playlist ul {
	margin:0;padding:0;
	list-style:none;}

div.jp-playlist ul li a.jp-playlist-current {
	font-weight: bold;
}
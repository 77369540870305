/*doc
---
title: Iconos
category: Componentes
---

Iconos generados gracias a https://icomoon.io/app. Estos son algunos ejemplos:

```html_example
<span class="icono icono--punto-mapa"></span> 
<span class="icono icono--entrada"></span> 
<span class="icono icono--aforo"></span>
</span>
```

##Tamaños##

Podemos crear variaciones de tamaños utilizando clases con sufijos tipo '--md', '--xs'...

```html_example
<span class="icono icono--md icono--punto-mapa"></span> 
```

##Texto acompañado de icono##

Si queremos mostrar un icono a la izquierda de un texto podemos hacerlo de la siguente forma:

```html_example
<span class="texto-con-icono texto-con-icono--md">
    <span class="icono icono--md icono--punto-mapa"></span> Edificio Sabatini, taller Equipo  
</span>
```

*/

/**
 * Tamaños
 */

 .icono--xs {
    font-size: @size-icon-xs;
}

.icono--sm {
    font-size: @size-icon-sm;
}

.icono--md {
    font-size: @size-icon-md;
}

/**
 * Texto acompañado de icono
 */

.texto-con-icono {
    position: relative;
    display: inline-block;
    line-height: 1.3;

    .icono {
        position: absolute;
        left: 0;
        top: 0;
    }

}

.texto-con-icono--md {
    padding-left: @size-icon-md + 10;
}

/*doc
---
title: Listado de visitas libres
category: Modulos
---


Es un listado con diseño específico acorde a los catálogos de visitas libres que edita el museo.

*/

.lista-visitas-libres {
	padding-top: 5px;
	margin: 0;
	list-style: none;
}

.lista-visitas-libres__visita--pequeno {
  margin-bottom: 10px;
}

.lista-visitas-libres__visita__titulo {
	font-size: 21px;
	font-family: @boldScout;
	@media (min-width: 1281px) {
		font-size: 24px;
	}
}

.lista-visitas-libres__visita__titulo--pequeno{
  font-size: 18px;
}

.lista-visitas-libres__visita__titulo--feminismo,
.lista-visitas-libres--visita--titulo--2582 a{
	&,
	&:hover {
		color: @feminismo;
	}
}

.lista-visitas-libres__visita__titulo--el-artista-en-crisis {
	&,
	&:hover {	
		color: @artista-crisis;
	}
}

.lista-visitas-libres__visita__titulo--cuerpo,
.lista-visitas-libres--visita--titulo--6565 a {
	&,
	&:hover {	
		color: @cuerpo;
	}
}

.lista-visitas-libres__visita__titulo--teatro,
.lista-visitas-libres--visita--titulo--2584 a {
	&,
	&:hover {	
		color: @teatro;
	}
}

.lista-visitas-libres__visita__titulo--poetico,
.lista-visitas-libres--visita--titulo--6029 a {
	&,
	&:hover {	
		color: @lo-poetico;
	}
}

.lista-visitas-libres__visita__titulo--obras-espacios-publicos,
.lista-visitas-libres--visita--titulo--2581 a {
	&,
	&:hover {	
		color: @arquitectura;
	}
}

.lista-visitas-libres__visita__titulo--indispensables,
.lista-visitas-libres--visita--titulo--4288 a {
  &,
  &:hover {
	color: @indispensables;
  }
}

/*doc
---
title: Thumbnails Boletin
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.boletin {

		.imagen {

			img {
				border: 5px solid @whiteDarker;
			}

		}

		div.caption {
			padding:10px 0;
		}

		div.field-name-field-boletin-subtitulo {
			font: 400 12px/16px @sansFontFamily;
			color:@grayLight;
		}

	}

}
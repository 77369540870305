/*doc
---
title: Thumbnail block
category: Componentes
---

Este componente se usa en las siguientes páginas: [Visita](http://www.museoreinasofia.es/visita), [Planificar la visita](http://www.museoreinasofia.es/visita/planificar-visita).

<div class="alert alert-error">
	Este componente será reemplazado cómo variante del nuevo componente Miniatura.
</div>

```html_example
<div class="row">
	<div class="span3">
		<a href="" class="thumbnail-block">
			<img src="http://placehold.it/280x158">
			<div class="caption">
				<h2 class="h2">Planos del Museo</h2>
				<h3 class="h3">Exposiciones y planos descargables</h3>
			</div>
		</a>
	</div>
</div>
```
*/

.thumbnail-block {
	margin-bottom: 30px;
	display: block;
	color: inherit;
	border: 1px solid @borderLight;
	@media (max-width: 767px) {
		max-width: 280px;
	}
	&:hover {
		color: inherit;
		text-decoration: none;

		.caption {
			background-color: @whiteDarker;
		}

	}

	&.thumbnail-intro {
		border: none;
		@media (max-width: 767px) {
			max-width: inherit;
		}
	
		.h1,
		.h2,
		.h3,
		.h4,
		.h5,
		.h6 {
			line-height: 1.2;

			a {
				color: @linkColor;
				text-decoration: underline;
				&:hover,
				&:active {
					color: @linkColorHover;
				}
			}

		}

	}

	.caption {
		padding: 15px;
		min-height: 85px;
		@media (min-width: 1281px) {
			min-height: 62px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			min-height: 105px;
		}
		@media (max-width: 767px) {
			min-height: inherit;
		}

		.h2 {
			margin-bottom: 0;
		}

		.h3 {
			margin-bottom: 0;
		}

	}
	
}
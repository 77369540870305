/*doc
---
title: Botones
category: Estilos basicos
---

Utilizamos los estilos básicos ["Buttons" de Bootstrap 2.3.2](http://getbootstrap.com/2.3.2/base-css.html#buttons), sobreescribiendo alguno de sus estilos y personalizando sus variables.

```html_example
<p>
  <button class="btn btn-large btn-primary" type="button">Large button</button>
  <button class="btn btn-large" type="button">Large button</button>
</p>
<p>
  <button class="btn btn-primary" type="button">Default button</button>
  <button class="btn" type="button">Default button</button>
</p>
<p>
  <button class="btn btn-small btn-primary" type="button">Small button</button>
  <button class="btn btn-small" type="button">Small button</button>
</p>
<p>
  <button class="btn btn-mini btn-primary" type="button">Mini button</button>
  <button class="btn btn-mini" type="button">Mini button</button>
</p>
```

En caso de los botones de compra, podemos añadirles un texto con el precio.

```html_example
<p>
  <button class="btn btn-primary btn--comprar" type="button"><span class="btn--comprar__precio">6€</span> Comprar</button>
</p>
</p>

*/
 
.btn {
	padding: 5px 12px 8px;
	font-weight: bold;
	text-shadow: none;
	background-image: none;
	background-color: #fff;
	border-radius: 4px;
	border-color: @grayDark;
	border-width: 2px;
	box-shadow: none;
	transition: @transition-base;

	[class^="icon-"],
	[class*=" icon-"] {
		margin-top: 1px;
		/*margin-right: -5px; */
	}
	&:active {
		.box-shadow (none);
	}
	&:hover {
		color: #fff;
		background-color: @grayDark;
	}
}

.btn-primary,
.btn-primary:hover,
.btn-warning,
.btn-warning:hover,
.btn-danger,
.btn-danger:hover,
.btn-success,
.btn-success:hover,
.btn-info,
.btn-info:hover,
.btn-inverse,
.btn-inverse:hover {
  text-shadow: none;
}

.btn-large {
	padding: 11px 18px;
	font-size: 16px;
	line-height: @icon-size;
}

.btn-small {
	padding: 5px 9px;
	font-size: @baseFontSize - 1px;
}

.btn-mini {
	padding: 2px 6px;
}

.btn-inverse {
	background-color: @grayDark;
	border-color: @grayDark;
	.box-shadow(0 0 0 transparent);
	&:hover,
	&:active {
		background-color: @grayDarker;
		border-color: @grayDarker;
	}
}

.btn-primary {
	background-color: @grayDark;
	border-color: @grayDark;
	.box-shadow(0 0 0 transparent);
	&:hover,
	&:active {
		background-color: @grayDarker;
		border-color: @grayDarker;
	}
	&.disabled {
		background-color: @gray;
		border-color: @gray;
	}
}

.btn-primary-light {
	box-shadow: none;
	&,
	&:hover,
	&:active {
		background-color: #fff;
		border-color: #fff;
		color: @textColorDark;
	}
}

.btn-secondary {
	&.disabled {
		color: @gray;
		background-color: transparent;
		border-color: @gray;
	}
}

// Botones con menu desplegable

.btn-group > .btn:first-child {
	.border-radius (0);
}

.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
	.border-radius (0);
	.box-shadow (0 0 0 transparent);
}

.btn-group.open .dropdown-toggle {
	.box-shadow (0 0 0 transparent);
}

.btn-group .caret {
	opacity:1;
	filter:none;
}

/**
 * Añado los estilos de .btn-link de Bootstrap v2.3.2
 */

.btn-link,
.btn-link:active,
.btn-link[disabled] {
	background-color: transparent;
	background-image: none;
	.box-shadow(none);
}

.btn-link {
	border-color: transparent;
	cursor: pointer;
	color: @linkColor;
	.border-radius(0);
}

.btn-link:hover,
.btn-link:focus {
	color: @linkColorHover;
	text-decoration: underline;
	background-color: transparent;
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus {
	color: @grayDark;
	text-decoration: none;
}

/**
 * Añado los estilos de .btn-block de Bootstrap v2.3.2
 */

.btn-block {
	display: block;
	width: 100%;
	.box-sizing(border-box);
}

.btn-block + .btn-block {
	margin-top: 5px;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block{
		width: 100%;
	}
}

// Botones de compra

.btn--comprar {
	padding: 0 12px 0 0;
	line-height: 31px;
	&:hover {
		background-color: #000;
	}
}

.btn--comprar__precio {
	height: 31px;
	float: left;
	margin-right: 5px;
	padding: 0 7px;
	display: inline-block;
	font-size: @largeFontSize;
	background-color: #000;
	.border-radius (2px 0 0 2px);
	@media (min-width: 768px) and (max-width: 979px) {
		font-size: @baseFontSize;
	}
}

.btn-icon {
	display: inline-flex;
	justify-content: space-between;
	gap: @space-unit-xs;
	text-align: left;
	&:hover {
		.icon {
			color: #fff;
		}
	}

	&.bnt-block {
		display: flex;
	}

}

/*doc
---
title: Thumbnails Actividad
category: Componentes
---

Documentación...

*/

// Snippet buscador

ul.thumbnails {

	div.actividad {

		div.fecha {
			margin-bottom: 5px;
			font-family: @lightScout;
			font-size: 14px;
			line-height: 1;
			color: @grayDark;

			.field {
				margin-bottom: 0;
			}

		}

		div.field-name-field-actividad-lugar-fueramuseo {
			margin: 0 0 5px 0;
			font-weight: 700;
			font-size: 12px;
			line-height: 1.2;
			color: @grayLight;
		}

	}

}

span.proyecto-asociado {
	display: block;
	margin-bottom: 5px;
	font-size: 11px;
	font-weight: 700;
	line-height: 15px;
	color: @grayDark;
	text-transform: uppercase;
}

div.programa {
	margin-bottom: 5px;
	font-size: 12px;
	line-height: 1.2;
}

// Snippet Programa

ul.programa {
	margin-bottom: 0;

	div.actividad {
		margin-bottom: 20px;
		@media (min-width: 1281px) {
			margin-bottom: 30px;
		}
	}

	.thumbnail {

		.imagen {
			vertical-align: top;
			height: auto;
		}
		
	}

}
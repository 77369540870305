.site-submenu-dropdown {
    
    ul.dropdown-menu {
        padding: 0;
        border: none;
    }

    .dropdown-toggle,
    .dropdown-menu a {
        .site-submenu-item-style();
    }

    .dropdown-toggle {
        border: none;
    }

}


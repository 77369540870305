/*doc
---
title: Lightbox
category: Componentes
---

Documentación...

*/

div#cboxWrapper{
	.border-radius(0);}
div#cboxLoadedContent {
  margin-bottom:60px;}
#cboxContent{
	font-family:@sansFontFamily;}
div#cboxTitle{
	height:50px;
	padding:0 30px 0 15px;
	position:absolute;
	bottom:0;
	left:0;
	overflow:hidden;
	font:400 14px/14px @lightScout;
	color:@grayLight;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	&:before{
		.spritePadded (sprite1, 1, 5, 12px, 11px);
   		left: 0;
   		top: 0;
	}
}
// IE7
.lt-ie8 div#cboxTitle{
	background:url(../images/ie7/bk.guion-credito.png) 0 0 no-repeat;
}

div#cboxClose{
	bottom:15px;}
.lt-ie9 #cboxLoadedContent img {
	max-width: none;
}
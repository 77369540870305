.titulo-bloque {
	.title-style;
  .font-size-title3;
	display: flex;
	gap: @space-unit-xxxs;
	align-items: center;
	margin-bottom: @space-unit-xs;
	position: relative;

	a {
		&.ver_mas {
			display: block;
			width: 16px;
			height: 16px;
			margin-left: @space-unit-xxxs;
			background: url('../images/ico.flecha.ir.png') 0 0 no-repeat;
			
			i {
				display: none;
			}

		}
	}

	small {
		.subtitle-style;
  	.font-size-subtitle5;
	}

}

.bloque {
	margin-bottom: @gridGutterWidth;
	@media (min-width: @desktop-large)  {
		margin-bottom: @gridGutterWidth-desktop-large;
	}
}

@video-intro-padding-tablet: @space-unit-md;

.video-intro {
  padding-top: @video-intro-padding-tablet;
  padding-bottom: @video-intro-padding-tablet;
  background-color: @grayDarker;
  @media (max-width: @mobile-down) {
    width: 100%;
    margin-left: -@gridGutterWidth;
    margin-right: -@gridGutterWidth;
    padding-left: @gridGutterWidth;
    padding-right: @gridGutterWidth;
  }
}

.video-intro__header {
  @media (min-width: @desktop) {
    display: flex;
    align-items: flex-end;
  }
}

.video-intro__breadcrumbs {
  .breadcrumb-dark();
  margin-bottom: @space-unit-xxxs;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xxs;
  }
}

.video-intro__heading {
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.video-intro__antetitle {
  color: #fff;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.video-intro__title {
  color: #fff;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxxs;
  }
}

.video-intro__subtitle {
  margin-bottom: 0;
  color: #fff;
}

.video-intro__intro {
  color: @textColorInverse;
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

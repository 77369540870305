.scroll-x {
  @media (min-width: @tablet) {
    overflow-x: hidden;
  }
}

.scroll-x__container {
  @media (min-width: @tablet) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100vh;
  }
}

.scroll-x__block {
  @media (min-width: @tablet) {
    height: 100%;
    display: flex;
  }
}

/*doc
---
title: Lista de descargables
category: Componentes
---

Documentación...

```html_example
<ul id="lista-material-descargar">
    <li class="documentos">
        <span class="tipo-descarga">Documentos</span>
        <ul class="listado-descargas">
            <li>
                <span class="file">
                    <img alt="pdf" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/application-pdf.png"> <a href="#" title="Descargar Nombre del documento">Nombre del documento</a>
                </span>
            </li>
            <li>
                <span class="file">
                    <img alt="pdf" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/application-pdf.png"> <a href="#" title="Descargar Nombre del documento">Nombre del documento</a>
                </span>
            </li>
        </ul>
    </li>
    <li class="imagenes">
        <span class="tipo-descarga">Imágenes</span>
        <ul class="listado-descargas">
            <li>
                <span class="file">
                    <img alt="imagen" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/image-x-generic.png"> <a href="#" title="Descargar Nombre de la imagen">Nombre de la imagen</a>
                </span>
            </li>
            <li>
                <span class="file">
                    <img alt="imagen" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/image-x-generic.png"> <a href="#" title="Descargar Nombre de la imagen">Nombre de la imagen</a>
                </span>
            </li>
        </ul>
    </li>
    <li class="videos">
        <span class="tipo-descarga">Vídeos</span>
        <ul class="listado-descargas">
            <li>
                <span class="file">
                    <img alt="video" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/video-x-generic.png"> <a href="#" title="Descargar Nombre del vídeo">Nombre del vídeo</a>
                </span>
            </li>
            <li>
                <span class="file">
                    <img alt="video" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/video-x-generic.png"> <a href="#" title="Descargar Nombre del vídeo">Nombre del vídeo</a>
                </span>
            </li>
        </ul>
    </li>
    <li class="audios">
        <span class="tipo-descarga">Audios</span>
        <ul class="listado-descargas">
            <li>
                <span class="file">
                    <img alt="audio" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/audio-x-generic.png"> <a href="#" title="Descargar Nombre del audio">Nombre del audio</a>
                </span>
            </li>
            <li>
                <span class="file">
                    <img alt="audio" src="/sites/all/themes/mrs_twitter_bootstrap/images/iconos/audio-x-generic.png"> <a href="#" title="Descargar Nombre del audio">Nombre del audio</a>
                </span>
            </li>
        </ul>
    </li>
</ul>
```

*/

/**
 * Lista de descargas básico.
 */

.listado-descargas {
    margin: 0 0 15px 0;
    list-style: none;
    li {
        margin: 0 0 5px 0;
        display: block;
    }
    .file {
        padding: 4px 0 4px 36px;
        position: relative;
        display: block;
        line-height: 18px;
        img {
            position: absolute;
            top: 5px;
            left: 0;
        }
        a {
            font-size: @smallFontSize;
            line-height: 1.1;
        }
    }
}

/**
 * El siguiente elemento es un contendor del listado y su título, nos sirve 
 * para separarlo de otros elementos situados encima.
 */

#material-descargar {
    padding-top: 20px;
}

/**
 * Lista de descargables clasificados por tipo de archivo.
 */

#lista-material-descargar {
    .margin-bottom-sm ();
    .no-margin-horizontal ();
    list-style: none;
    border-top: 1px solid #ddd;
    > li {
        border-bottom: 1px solid #ddd;
        position: relative;
        &:before {
            top: 12px;
            left: 10px;
            @media (min-width: 1281px) {
                top: 15px;
            }
        }
        &:last-child {
            border-bottom: none;
        }
        > ul {
            margin: 0 10px 18px 50px;
            list-style: none;
            @media (min-width: 1281px) {
                margin-bottom: 23px;
                margin-left: 55px;
            }
            > li {
                margin-top: 5px;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
    .documentos {
        &:before {
            .spritePadded(sprite1, 0,18,32px,32px); 
        }
    }
    .imagenes {
        &:before {
            .spritePadded(sprite1, 1,18,32px,32px); 
        }
    }
    .videos {
        &:before {
            .spritePadded(sprite1, 2,18,32px,32px); 
        }
    }
    .audios {
        &:before {
            .spritePadded(sprite1, 3,18,32px,32px); 
        }
    }
    .tipo-descarga {
        display: block;
        font-family: @regularScout;
        font-size: 16px;
        line-height: 1.2;
        text-transform: uppercase;
        color: @grayDark;
    }
    span.tipo-descarga {
        padding: 18px 10px 0 50px;
        @media (min-width: 1281px) {
            padding-top: 23px;
            padding-left: 55px;
        }
    }
    a.tipo-descarga {
        padding: 18px 10px 18px 50px;
        @media (min-width: 1281px) {
            padding-top: 23px;
            padding-bottom: 23px;
            padding-left: 55px;
        }
        &:hover,
        &:active {
            background-color: @whiteDarker;
            text-decoration: none;
        }
    }
}
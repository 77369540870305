/*doc
---
title: Portada Autores
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-autores h1.page-header{
	margin-bottom:0;
	border-bottom:none;}
div#abecedario{
	height:32px; margin-bottom:10px;
	background:@grayDark;}
div#abecedario a{
	width:10px;
	text-transform:uppercase; text-align:center; border-color:@gray; color:@grisClaro;}
div#abecedario a:hover{
	background:@beigeHover;border-color:@beigeHover;color:@grayDark;}
div#abecedario li a.active{
	background:@beige;border-color:@beige;color:@grayDark;}
@media (min-width: 768px) and (max-width: 979px) {
div#abecedario a{
	padding:0 8px;}
}
@media (max-width: 767px) {
div#abecedario{
	height:64px;}
div#abecedario li{
	display:inline;}
}
@media (max-width: 480px) {
div#abecedario{
	height:128px;}
}

div#autores > ul.thumbnails > li{
	padding:0;
	border-top:none;}

// Snippet autores
ul.listado-autores{
	margin:0 0 20px 0;
	list-style:none;
	border-top:1px solid @grayLighter;}
ul.listado-autores > li{
	border-bottom:1px solid @grayLighter;}
ul.listado-autores > li h3.titulo-bloque{
	border-top:none;}
@media (min-width: 1281px) {
ul.listado-autores{
	margin:0 0 30px 0;}
}
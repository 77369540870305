/*doc
---
title: Gigapixel
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-gigapixel {
	height: 100%;
	padding: 0;
	margin: 0;
	background: @black;}

div#viewer{
	height: 100%;
	min-height: 100%;
	width: 70%;
	position: absolute;
	top: 0;
	left: auto;
	right:0;
	margin: 0;
	padding: 0;}

div#controls {
    height: 100%;
	min-height: 100%;
    position: absolute;
    width: 28%;
	top:0;
	left:0;
	right:auto;
}

.iipmooviewer img.logo {
	display:none!important;}
.iipmooviewer .credit {
	display:none!important;}

.navcontainer .loadBarContainer .loadBar{
	line-height:10px;}

body.node-type-gigapixel ul.nav-tabs{
	display:inline-block; position:absolute; bottom:70px; left:20px; z-index:100;}

div#controls div#logo-navegacion {
	padding: 20px 0 0px 20px;
	background: @black;}
div#controls div#logo-navegacion a.brand {
	width:130px;height:65px;display:block;
	background: url("../images/logo.museo-negativo.png") 0 0 no-repeat;}
div#controls div#logo-navegacion img,
div#controls div#logo-navegacion span#texto-logo{
	display: none;}

div#controls #datos-obra {
	text-align:left;
	padding-left:20px;
	color: @white;
	margin: 20px 0 40px;}
div#controls #datos-obra h2 {
	margin-bottom:0;}
div#controls #datos-obra h2 small {
	display:block;
	line-height:20px;}

div#controls #cambio-imagen{
	margin-left: 20px;}

div#controls #cambio-imagen span {
	display: block;
	color: @white;}
div#controls #cambio-imagen .btn{
	margin-bottom:5px;}

div#controls .volver {
	margin-left: 20px;
	position: absolute;
	bottom: 40px;}

div#controls div.license{
	margin-left: 20px;
	position: absolute;
	bottom: 10px;
	font:400 10px/13px @sansFontFamily;
	color:@grayLight;}
div#controls div.license a{
	color:@grayLight;}
div#controls div.license a:hover{
	text-decoration:underline; cursor:auto;}

div.field-name-field-page-gigapixel{
	background:@whiteDarker;}
div.field-name-field-page-gigapixel p.titulo-bloque{
	padding-left:10px; padding-right:10px;}
div.field-name-field-page-gigapixel div.content{
	padding:0 10px 10px 10px;}
div.field-name-field-gigapixel-miniatura{
	margin-bottom:10px;}
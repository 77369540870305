/*doc
---
title: Carousel
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si se esta utilizando este componente.</div>

*/

.jcarousel-skin-default {
	text-align: left;
	
	.jcarousel-container-vertical {
		width: 166px;
		height: 440px;
		padding: 32px 0;
		@media (max-width: 767px) {
			margin: 0;
		}
	}

	.jcarousel-clip-vertical {
		height: 440px;
		overflow: hidden;
	}

	.jcarousel-item {
		width: 166px;
		padding: 0;
		overflow: hidden;
		border: none;
		background: none;
		&:hover {
			cursor: pointer;
			
			div.field-content {
				color: @linkColorHover;
			}

		}
		
		div.data-container {
			padding: 0;

			img {
				max-height: 100px;
			}

		}

	}

	.jcarousel-item-vertical {
		margin: 4px 0;
		padding: 0;
	}

	div.field-content {
		padding-top: 5px;
		font: 400 12px/14px @sansFontFamily;
		text-decoration: underline;
	}

	// Botones

	.jcarousel-next,
	.jcarousel-prev {
		background-image: url(../images/arrows.png);
	}
	
	// Vertical Buttons

	.jcarousel-prev-vertical {
		top: 0;
		left: 67px;

	}

	.jcarousel-next-vertical {
		bottom: 0;
		left: 67px;

	}

}
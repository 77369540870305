/*doc
---
title: Table grid
category: Estilos basicos
---

Esta este elemento esta creado a partir de la rejilla original ["Table grid"](http://mdo.github.io/table-grid). He tenido que adapatarla a Bootstrap 2 ya que no podemos trabajar con un enfoque Mobile First en esa versión del framework.

*/

.table-grid {
	width: 100%;
	display: table;
	table-layout: fixed;
	-ms-overflow-x: hidden;
	@media (max-width: 767px) {
		width: auto;
		display: block;
		table-layout: auto;
	}
}

.table-grid--sin-margin {
	.no-margin-bottom ();
}

.col {
	display: table-cell;
	vertical-align: top;
	@media (max-width: 767px) {
		display: block;
		vertical-align: baseline;
	}
	> :last-child {
		@media (min-width: 768px) {
			.no-margin-bottom ();
		}
	}
}

/**
 * IE8 no soporta la pseudo-clase :last-child, así que tenemos que especificarle el elemento en concreto al que queremos quitarle el margen.
 */

.lt-ie9 {
	.col {
		.miniatura {
			.no-margin-bottom ();
		}
	}
}

.col-1 { width: 8.333333%; }
.col-2 { width: 16.666667%; }
.col-3 { width: 25%; }
.col-4 { width: 33.333333%; }
.col-5 { width: 41.666667%; }
.col-6 { width: 50%; }
.col-7 { width: 58.333333%; }
.col-8 { width: 66.666667%; }
.col-9 { width: 75%; }
.col-10 { width: 83.333333%; }
.col-11 { width: 91.666667%; }
.col-12 { width: 100%; }

@media (max-width: 767px) {
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 { width: auto; }
}

.table-grid-padded {
	margin-left:  -20px;
	margin-right: -20px;
	overflow: hidden;
	@media (min-width: 1281px) {
		margin-left:  -30px;
 		margin-right: -30px;
	}
	@media (max-width: 767px) {
		.no-margin-horizontal ();
	}
}

.table-grid-padded .table-grid {
	border-spacing: 20px 0;
	@media (min-width: 1281px) {
		border-spacing: 30px 0;
	}
	@media (max-width: 767px) {
		border-spacing: 0;
	}
}

.table-grid-align-middle .col {
	vertical-align: middle;
}

/**
 * Variante de tipo cuadrícula
 */

.table-grid--cuadricula {

	.col {
		border: 1px solid #fff;
	}

}
.publications-grid {
    display: flex;
    flex-wrap: wrap;
    row-gap: @space-unit-md;
    column-gap: 1px;
    justify-content: center;
}

.publications-grid__item {
    display: block;
    flex-basis: 100%;
    color: initial;
    @media (min-width: @tablet) {
        width: 24.8%;
        flex-basis: 24.8%;
    }
    &:hover {
        text-decoration: none;
        .publications-grid__item-antetitle,
        .publications-grid__item-title,
        .publications-grid__item-subtitle {
            color: @linkColor;
        }
    }
}

.publications-grid__item-image {
    width: 100%;
    aspect-ratio: 1;
}

.publications-grid__item-cover {
    .publication-cover-styles(@aspect-ratio: 1);
}

.publications-grid__item-caption {
    padding-top: @space-unit-xs;
    padding-left: @space-unit-sm;
    padding-right: @space-unit-sm;
    text-align: center;
}

.publications-grid__item-antetitle {
    .antetitle-style;
    transition: .2s;
    &:not(:last-child) {
        margin-bottom: @space-unit-xxxs;
    }
}

.publications-grid__item-title {
    .title-style;
    .font-size-title3;
    transition: .2s;
}

.publications-grid__item-subtitle {
    .subtitle-style;
    .font-size-title4;
    transition: .2s;
}


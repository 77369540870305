@icon-size: 24px;
@icon-size-medium: 36px;
@icon-size-large: 48;
@icon-size-small: 20px;
@icon-size-tiny: 10px;

.icon {
    .size(@icon-size, @icon-size);
    display: inline-flex;
    color: inherit;
    background-image: none;

    svg {
        .size(100%, 100%);
        fill: currentColor;
    }
}

.icon--medium {
    .size(@icon-size-medium, @icon-size-medium);
}

.icon--large {
    .size(@icon-size-large, @icon-size-large);
}

.icon--large\@tablet {
    @media (min-width: @tablet) {
        .size(@icon-size-large, @icon-size-large);
    }
}

.icon--small {
    .size(@icon-size-small, @icon-size-small);
}

.icon--tiny {
    .size(@icon-size-tiny, @icon-size-tiny);
}
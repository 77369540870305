/*doc
---
title: Thumbnails
category: Componentes
---

Sobreescribimos algunos estilos y extendemos el componente de Bootstrap.

*/

/**
 * Índice:
 * 1.Estilos básicos
 * 2.Lista de thumbnails en columnas
 * 3.Lista de thumbnails apilados
 * 4.Lista de thumbnails dentro de un carrusel
 * 5.Rollover con texto
 * 6.Imágenes
 * 7.Caption
 * 8.Lista de thumbnails destacados
 */

/**
 * 1.Estilos básicos
 */

ul.thumbnails {
	margin-bottom: 0;

	> li {
		margin-bottom: 20px;
		@media (min-width: 1281px) {
			margin-bottom: 30px;
		}
		@media (max-width: 767px) {
			width: 100%;
			margin-left: 0;
			margin-bottom: 30px;
			float: none;
		}
	}

	ul.thumbnails {

		> li {
			margin-bottom: 0;
		}

	}
}

.thumbnail {
	padding: 0;
	overflow: hidden;
	border: none;
	.border-radius(0);
	.box-shadow(0 0 0 transparent);

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		text-transform: none;
	}

	> img {
		margin-left: 0;
		margin-right: 0;
	}

	.caption {
		padding: 0;
	}

}

a.thumbnail {
	&:hover {
		border-color: transparent;
		.box-shadow(0 0 0 transparent);
	}
}

.lt-ie8 {

	ul.thumbnails {
		margin-left: 0;

		> li:first-child,
		> .limpiar {
			margin-left: 0;
		}
	}

}

/**
 * 2.Lista de thumbnails en columnas
 */

.grid {
  @media (min-width: @tablet) {
    overflow: hidden;
  }

  ul.thumbnails {
    @media (min-width: @tablet) {
      display: flex;
      flex-wrap: wrap;
      margin-top: -@space-unit-sm;
    }

    > li {
      @media (min-width: @tablet) {
        margin-bottom: 0;
        padding-top: @space-unit-sm;
        position: relative;
      }

      &:before {
        @media (min-width: @tablet) {
          display: block;
          width: 1px;
          height: 100%;
          content: "";
          position: absolute;
          right: -(@gridGutterWidth / 2);
          top: 0;
          background-color: @borderLight;
        }
        @media (min-width: @desktop-large) {
          right: -(@gridGutterWidth-desktop-large / 2);
        }
      }

      &.thumbnail {
        overflow: visible;
      }

    }
  }

  .miniatura {
    margin-bottom: 0;
  }

}

.grid--boxes {

  ul.thumbnails {

    > li {
      @media (min-width: @tablet) {
        display: flex;
      }
    }

    .thumbnail {
      background-color: @whiteDarker;

      .caption {
        padding: @space-unit-xs;
      }

    }

  }

}

/**
 * 3.Lista de thumbnails apilados
 */

div.list {
	border-top: 1px solid #ddd;
	@media (max-width: 767px) {
		border-top: none;
	}

	ul.thumbnails > li {
		width: 100%;
		margin: 0;
		@media (max-width: 767px) {
			padding-top: 0;
			border-top: none;
		}
	}

	.thumbnail {
		min-height: 165px;
		padding: 10px 0 10px 240px;
		position: relative;
		border-left: none;
		border-bottom: 1px solid #ddd;
		background: url(../images/bk.borde.gris.png) 230px 0 repeat-y;
		@media (min-width: 1281px) {
			min-height: 203px;
			padding: 10px 0 10px 300px;
			background: url(../images/bk.borde.gris.png) 285px 0 repeat-y;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			min-height: 125px;
			padding: 10px 0 10px 186px;
			background: url(../images/bk.borde.gris.png) 176px 0 repeat-y;
		}
		@media (max-width: 767px) {
			min-height: inherit;
			padding: 10px 0;
			position: static;
			border-bottom: none;
			border-top: 1px solid #ddd;
			background: none;
		}
	}

	ul.thumbnails {

		.thumbnail {

			.imagen,
			div.field-name-field-video-video,
			div.reproductor {
				width: 220px;
				position: absolute;
				display: block;
				top: 10px;
				left: 0;
				@media (min-width: 1281px) {
					width: 270px;
				}
				@media (min-width: 768px) and (max-width: 979px) {
					width: 166px;
				}
			}

			.imagen,
			div.field-name-field-video-video {
				@media (max-width: 767px) {
					width: auto;
					padding: 0;
					position: static;
					border-right: none;
				}
			}

			div.reproductor {
				@media (max-width: 767px) {
					width: 100%;
					position: relative;
					top: auto;
					left: auto;
				}

				div.imagen {
					top: 0;
					@media (max-width: 767px) {
						position: absolute;
					}
				}

			}

			div.caption {
				padding-top: 0;
				@media (max-width: 767px) {
					padding: 10px 0 0 0;
				}
			}

		}

	}

	// Modificamos los estilos del componente Miniatura al incluirlo en este tipo // de listados

	.miniatura {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ddd;
		background: url(../images/bk.borde.gris.png) 230px 0 repeat-y;
		@media (min-width: 1281px) {
			background: url(../images/bk.borde.gris.png) 285px 0 repeat-y;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			background: url(../images/bk.borde.gris.png) 176px 0 repeat-y;
		}

		@media (max-width: 767px) {
			border-bottom: none;
			border-top: 1px solid #ddd;
			background: none;
			max-width: inherit;
		}

		.media__media-izquierda {
			width: 220px;
			margin-right: 30px;
			text-align: center;
			@media (min-width: 1281px) {
				width: 270px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				width: 166px;
			}
			@media (max-width: 767px) {
				width: auto;
				max-width: inherit;
				margin-bottom: 15px;
				margin-right: 0;
				float: none;
			}
		}
	}

}

// Thumbnails formato listado sin imagen

div.marc .thumbnail {
	background: none;
}

/**
 * 4.Lista de thumbnails dentro de un carrusel
 */

div.vista-carrusel {
	position: relative;

	li > {

		.thumbnail {
			padding: 0 30px;
			@media (max-width: 767px) {
				padding: 0;
			}

			.area-imagen {
				width: 160px;
				@media (min-width: 1281px) {
					width: 210px;
				}
				@media (min-width: 768px) and (max-width: 979px) {
					width: 106px;
				}
			}

			.imagen {
				height: 120px;
				@media (min-width: 1281px) {
					height: 158px;
				}
				@media (min-width: 768px) and (max-width: 979px) {
					height: 80px;
				}

				img {
					max-width: 160px;
					max-height: 120px;
					@media (min-width: 1281px) {
						max-width: 210px;
						max-height: 158px;
					}
					@media (min-width: 768px) and (max-width: 979px) {
						max-width: 106px;
						max-height: 80px;
					}
				}

			}

		}

	}

}

.lt-ie8 {

	div.vista-carrusel {

		li {

			> .thumbnail {

				.imagen {
					line-height: 120px;

					img {
						max-width: 160px;
						max-height: 120px;
					}

				}

			}

		}

	}

}

// Paginado en forma de flechas de carrusel

div.vista-carrusel ul.pager div.ajax-progress {
	display: none;
}

div.vista-carrusel li.pager-current {
	display: none;
}

div.vista-carrusel li.pager-previous,
div.vista-carrusel li.pager-next {
	width: 24px;
	height: 24px;
	display: block;
	line-height: 0;
	position: absolute;
	top: 47%;
}

div.vista-carrusel li.pager-previous {
	left: 0px;
}

div.vista-carrusel li.pager-next {
	right: 0px;
}

div.vista-carrusel ul.pager {

	a {
		width: 24px;
		height: 24px;
		padding: 0;
		margin: 0;
		font-size: 0;
		line-height: 0;
		display: block;
		border: none;
		.border-radius(0);
		background: none;
	}

	li.pager-next a {
		.sprite(sprite1, 2,9);
	}

	li.pager-previous a {
		.sprite(sprite1, 2,8);
	}

	li span.inactivo span {
		display: none;

	}

}

div.vista-carrusel {

	li.pager-previous span.inactivo {
		width: 24px;
		height: 24px;
		padding: 0;
		margin: 0;
		display: block;
		opacity: .3;
		filter: alpha(opacity=30);
		.sprite(sprite1, 2,8);
	}

	li.pager-next span.inactivo {
		width: 24px;
		height: 24px;
		padding: 0;
		margin: 0;
		display: block;
		right: 0px;
		opacity: .3;
		filter: alpha(opacity=30);
		.sprite(sprite1, 2,9);
	}

}

@media (max-width: 767px) {

	div.vista-carrusel li.pager-current,
	div.vista-carrusel li.pager-previous,
	div.vista-carrusel li.pager-next {
		display: block;
		float: left;
		position: static;
	}

	div.vista-carrusel li.pager-current {
		height: 24px;
		padding: 0 10px;
		vertical-align: middle;
	}

	div.vista-carrusel ul.pager {margin: 0 0 20px 0;
		width: 100%;
		overflow: hidden;
	}

}

/**
 * 5.Rollover con texto
 */

ul.thumbnails {

	div.entradilla {

		em {
			font-style: italic;
		}

	}

	.image_hover {
		display: block;
		position: relative;
		&:hover {

			.image_body {
				display: block;
				z-index: 10;
				background: @gray;
				// IE 7-8
				background: fade(@gray, 95%);
				opacity: 1;
				@media (max-width:  1024px) {
					display: none;
				}
			}

			.padding {
				opacity: 1;
			}

		}

		img {
			z-index: 1;
			position: relative;
		}

	}

	.image_body {
		height: 100%;
		display: block;
		overflow: hidden;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
	}

	.padding {
		display: block;
		padding: 10px;
		opacity: 0;
		font: 400 12px/16px @sansFontFamily;
		color: @white;
	}

	.sabatini {

		.image_hover {
			&:hover {

				.image_body {
					background: @sabatini;
					background: fade(@sabatini, 90%);
				}

			}
		}

	}

	.retiro {

		.image_hover {
			&:hover {

				.image_body {
					background: @retiro;
					background: fade(@retiro, 90%);
				}

			}
		}

	}

	.silos {

		.image_hover {
			&:hover {

				.image_body {
					background: @silos;
					background: fade(@silos, 90%);
				}

			}
		}

	}

	.sabatini,
	.retiro,
	.silos {

		.padding {
			color: @white;
		}

	}

}



/**
 * 6.Imágenes
 */

// 220px x 165px
// 270px x 203px
// 166px x 125px

ul.thumbnails {

	.thumbnail {

		.imagen {
			height: 165px;
			display: table-cell;
			vertical-align: middle;
			@media (min-width: 1281px) {
				height: 203px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				height: 125px;
			}

			img {
				max-width: 220px;
				max-height: 165px;
				@media (min-width: 1281px) {
					max-width: 270px;
					max-height: 203px;
				}
				@media (min-width: 768px) and (max-width: 979px) {
					max-width: 166px;
					max-height: 125px;
				}
			}

		}

		a.imagen {
			&:hover {
				text-decoration: none;
			}
		}

		.imagen-vacia {
			background: @whiteDarker;
		}

		.area-imagen {
			width: 220px;
			display: block;
			@media (min-width: 1281px) {
				width: 270px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				width: 166px;
			}
		}

	}

}

.lt-ie8 {

	ul.thumbnails {

		.thumbnail {

			.imagen {
				display: block;
				line-height: 165px;

				img {
					max-width: 220px;
					max-height: 165px;
					vertical-align: middle;
				}
			}

		}

	}

}

// 1 y 3 thumbnails destacados,
// 1 y 2 thumbnails en la home,
// thumbnails de Colección y
// Visita guiada en la home:
//
// 300px por 225px
// 370px por 278px
// 228px por 171px

ul.bloque1 .thumbnail .imagen,
ul.bloque3 .thumbnail .imagen,
ul.bloque_home1 .thumbnail .imagen,
ul.bloque_home2 .thumbnail .imagen,
div#colecciones.bloque-home ul.thumbnails .imagen {
	height: 225px;
}

ul.bloque1 .thumbnail .area-imagen,
ul.bloque3 .thumbnail .area-imagen,
ul.bloque_home1 .thumbnail .area-imagen,
ul.bloque_home2 .thumbnail .area-imagen,
div#colecciones.bloque-home ul.thumbnails .area-imagen {
	width: 300px;
}

ul.bloque1 .thumbnail .imagen img,
ul.bloque3 .thumbnail .imagen img,
ul.bloque_home1 .thumbnail .imagen img,
ul.bloque_home2 .thumbnail .imagen img,
div#colecciones.bloque-home ul.thumbnails .imagen img {
	max-width: 300px;
	max-height: 225px;
  	width: 100%;
}

@media (min-width: 1281px) {

	ul.bloque1 .thumbnail .imagen,
	ul.bloque3 .thumbnail .imagen,
	ul.bloque_home1 .thumbnail .imagen,
	ul.bloque_home2 .thumbnail .imagen,
	div#colecciones.bloque-home ul.thumbnails .imagen {
		height: 278px;
	}

	ul.bloque1 .thumbnail .area-imagen,
	ul.bloque3 .thumbnail .area-imagen,
	ul.bloque_home1 .thumbnail .area-imagen,
	ul.bloque_home2 .thumbnail .area-imagen,
	div#colecciones.bloque-home ul.thumbnails .area-imagen {
		width: 370px;
	}

	ul.bloque1 .thumbnail .imagen img,
	ul.bloque3 .thumbnail .imagen img,
	ul.bloque_home1 .thumbnail .imagen img,
	ul.bloque_home2 .thumbnail .imagen img,
	div#colecciones.bloque-home ul.thumbnails .imagen img {
		max-width: 370px;
		max-height: 278px;
	}

}

@media (min-width: 768px) and (max-width: 979px) {

	ul.bloque1 .thumbnail .imagen,
	ul.bloque3 .thumbnail .imagen,
	ul.bloque_home1 .thumbnail .imagen,
	ul.bloque_home2 .thumbnail .imagen,
	div#colecciones.bloque-home ul.thumbnails .imagen {
		height: 171px;
	}

	ul.bloque1 .thumbnail .area-imagen,
	ul.bloque3 .thumbnail .area-imagen,
	ul.bloque_home1 .thumbnail .area-imagen,
	ul.bloque_home2 .thumbnail .area-imagen,
	div#colecciones.bloque-home ul.thumbnails .area-imagen {
		width: 228px;
	}

	ul.bloque1 .thumbnail .imagen img,
	ul.bloque3 .thumbnail .imagen img,
	ul.bloque_home1 .thumbnail .imagen img,
	ul.bloque_home2 .thumbnail .imagen img,
	div#colecciones.bloque-home ul.thumbnails .imagen img {
		max-width: 228px;
		max-height: 171px;
	}

}

.node-type-entrada div#colecciones.bloque-home ul.thumbnails .imagen {
  height: 100%;
}

/**
 * 7.Caption
 */

ul.thumbnails {

	.thumbnail {

		.caption {
			padding-top: 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
			font-family: @sansFontFamily;
			color:@grayLight;

			p,
			li {
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				font-family: @sansFontFamily;
				color:@grayLight;
			}

			.tipo-fecha {
				margin-bottom: 10px;
				padding-bottom: 8px;
				font-family: @lightScout;
				font-size: 16px;
				color: @grayDark;
				border-bottom: 1px solid darken(@borderLight, 3%);
				@media (max-width: 767px) {
					padding-bottom: 0;
					border-bottom: none;
				}

				span {
					font-family: @regularScout;
				}

			}

		}

		.titulo {
			margin-bottom: 10px;
		}

		h3.titulo {
			margin-bottom: 0;
			font-family: @boldScout;
			font-weight: 400;
			font-size: 19px;
			line-height: 1.1;
			@media (max-width: 767px) {
				font-size: 22px;
			}

			em {
				font-family: @boldScoutItalic;
				font-style: normal;
				font-weight: normal;
			}

			strong {
				font-family: @blackScout;
				font-weight: 400;
			}

		}

		h4 {
			font-family: @thinScout;
			font-weight: 400;
			font-size: 18px;
			line-height: 1.1;
			color:@grayDark;
			@media (max-width: 767px) {
				font-size: 20px;
			}
		}

		.field {
			margin-bottom:5px;
		}

	}

}

/**
 * 8.Lista de thumbnails destacados
 */

ul.bloque1,
ul.bloque2,
ul.bloque3 {

	.thumbnail {
		background-color: @whiteDarker;
	}

}

ul.bloque2,
ul.bloque3 {

	.thumbnail {
		@media (min-width: 768px) and (max-width: 979px) {
			background-color: transparent;
		}
	}

}

ul.bloque1 {

	.thumbnail {

		.imagen {
			vertical-align: top;
		}

		.caption {
			width: 610px;
			padding: 15px 15px 15px 20px;
			display: table-cell;
			@media (min-width: 1281px) {
				width: 760px;
				padding-left: 30px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				width: 466px;
			}
			@media (max-width: 767px) {
				width: auto;
				padding-left: 15px;
				display: block;
			}
		}

	}

}

ul.bloque2 {

	.thumbnail {

		.imagen {
			vertical-align: top;
		}

		.caption {
			width: 220px;
			padding: 15px 15px 15px 20px;
			display: table-cell;
			@media (min-width: 1281px) {
				width: 270px;
				padding-left: 30px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				width: 166px;
				padding-top: 0;
			}
			@media (max-width: 767px) {
				width: auto;
				padding-left: 15px;
				display: block;
			}
		}

	}

}

ul.bloque3 {

	.thumbnail {

		.caption {
			min-height: 100px;
			padding: 15px;
			@media (max-width: 979px) {
				min-height: inherit;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
			}
		}

	}

}
.imagen--legacy {
  height: auto !important;
  bottom: 10px;

}

.area-imagen--legacy {
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: @space-unit-sm;
  background-color: @borderLighter;
  box-sizing: border-box;

  img {
    max-width: 100% !important;
    max-height: 100% !important;
    // height: 150px;
    box-shadow: 0px 2px 2px fade(#000, 14%), 0px 3px 1px fade(#000, 12%), 0px 1px 5px fade(#000, 20%);
  }
}

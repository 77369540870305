.block-with-border {
  &:not(:first-child) {
    padding-top: @space-unit-xsm;
    @media (min-width: @tablet) {
      padding-top: @space-unit-sm;
    }
  }
  &:not(:last-child) {
    padding-bottom: @space-unit-xsm;
    border-bottom: 1px solid @borderLight;
    @media (min-width: @tablet) {
      padding-bottom: @space-unit-sm;
    }
  }
  > :last-child {
    margin-bottom: 0 !important;
  }
}
.read-more-button {
  .reset-button();
  font-weight: bold;
  color: @textColorDark;
  transition: .2s;
  &:hover {
    color: @linkColor;
    text-decoration: underline;
  }
	&::after {
		content: " +";
	}
}

.read-more-button--inverse {
  &,
  &:hover {
    color: #fff;
  }
}
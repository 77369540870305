/*doc
---
title: Thumbnails Publicación
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	p#autores-publicacion {
		margin: 0;
		padding: 0;
		position: static;
		border: none;

		span {
			width: auto;
			padding: 0;
			position: static;
			font-size: 12px;
			line-height: 1.3;
			background: none;
			color: @grayLight;
			text-align: left;
		}

	}

	p.fecha-publicacion {
		margin: 0 0 5px 0;
		font-size: 12px;
		line-height: 1.3;
		color: @grayLight;

		span {
			display: block;
			font-size: 12px;
			line-height: 1.3;
			color: @grayLight;
			text-transform: uppercase;
		}

	}

}
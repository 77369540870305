/*doc
---
title: Menú desplegable
category: Componentes
---

Documentación...

*/

ul.dropdown-menu{
	margin:-1px 0 0 0;padding:5px;
	text-align:left;
	border-color:@grayLighter;
	.border-radius(0);
	.box-shadow(0 0 0 transparent);}
ul.dropdown-menu a.active,
ul.dropdown-menu a.active:hover{background:@gray;}
ul.dropdown-menu a:hover{background:@grayDark;}
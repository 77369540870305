.snippet-pager {
  display: flex;
  flex-direction: column;
  color: #fff;
  transition: .2s;
  @media (min-width: @desktop) {
    flex-direction: row;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    
    .snippet-pager__title {
      text-decoration: underline;
    }

  }
}

.snippet-pager--next {
  text-align: right;
  align-items: flex-end;
  @media (min-width: @desktop) {
    align-items: flex-start;
    flex-direction: row-reverse;
  }

  .snippet-pager__caption {
    @media (min-width: @desktop) {
      margin-right: @space-unit-xxs;
    }
  }

}

.snippet-pager__arrow {
  flex-shrink: 0;
  margin-bottom: @space-unit-xxs;
  @media (min-width: @desktop) {
    margin-bottom: 0;
  }
}

.snippet-pager__caption {
  max-width: 250px;
  @media (min-width: @desktop) {
    margin-left: @space-unit-xxs;
  }
}

.snippet-pager__title {
  .title-style;
  .font-size-title4;
  margin-bottom: @space-unit-xxs;
  color: #fff;
  @media (min-width: @desktop) {
    margin-bottom: @space-unit-xxxs;
  }
}
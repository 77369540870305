.site-submenu {
    display: flex;
    background-color: @whiteDarker;
    @media (min-width: @tablet) {
        margin-top: @space-unit-xs;
    }
}

.site-submenu__item {
    .site-submenu-item-style();
}

.site-submenu__item--active {
    &,
    &:hover {
        background-color: #fff;
    }
}

/*doc
---
title: Sección
category: Componentes
---

Documentación...

*/

.seccion {
	margin-bottom: @space-unit-sm;
	@media (min-width: @tablet) {
		margin-bottom: @space-unit-md;
	}
}

.seccion--margen-grande {
	margin-bottom: @space-unit-md;
	@media (min-width: @tablet) {
		margin-bottom: @space-unit-lg;
	}
}

.seccion--margen-extragrande {
	margin-bottom: @space-unit-md;
	@media (min-width: @tablet) {
		margin-bottom: @space-unit-xxl;
	}
}

.seccion--caja {
	padding: @space-unit-sm;
	background-color: @whiteDarker;
}

.seccion--separador-arriba--fino {
	padding-top: @space-unit-sm;
	border-top: 1px solid @borderLight;
}

.seccion--separador-arriba--grueso {
	padding-top: @space-unit-md;
	border-top: 3px solid @grayDark;
	@media (max-width: 767px) {
		padding-top: @space-unit-sm;
	}	
}

.seccion--separador-abajo--fino {
	border-bottom: 1px solid @borderLight;
}

.seccion--separador-abajo--grueso {
	border-bottom: 3px solid @grayDark;
}

.seccion__cabecera {
	margin-bottom: 20px;
}

.seccion__cabecera__titulo {
	margin-top: 0;
	margin-bottom: 0;
	.title-style;
	.font-size-title2;
}

.seccion__cabecera__titulo--pequeno {
	font-family: @regularScout;
	.font-size-large ();	
}

.seccion__cabecera__subtitulo {
	margin-top: 2px;
	margin-bottom: 0;
	font-family: @thinScout;
	.font-size-seccion__cabecera__subtitulo ();
}

.seccion__bloque {
	margin-bottom: @space-unit-sm;
	@media (min-width: @tablet) {
		margin-bottom: @space-unit-lg;
	}
}

.seccion__bloque__titulo {
	margin-bottom: 20px;
}

.seccion__pie--alineado-izquierda {
	text-align: left;
}

.seccion__pie--alineado-centro {
	text-align: center;
}

.seccion__pie--alineado-derecha {
	text-align: right;
}
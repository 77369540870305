.u-background-color-white {
    background-color: #fff !important;
}

.u-background-color-white-darker {
    background-color: @whiteDarker !important;
}

.u-background-color-beige {
    background-color: @beige !important;
}

.u-background-color-gray-darker {
    background-color: @grayDarker !important;
}

/*doc
---
title: Portada Estática
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-portada #main{
	margin-bottom:20px;}
body.node-type-portada div#visitas-guiadas{
	margin-bottom:20px;}
@media (min-width: 1281px) {
body.node-type-portada #main{
	margin-bottom:30px;}
body.node-type-portada div#visitas-guiadas{
	margin-bottom:30px;}
}

body.node-type-portada .miniatura--fondo-imagen .miniatura__seccion {
	display: none;
}
.separator {
    margin-top: @space-unit-sm;
    margin-bottom: @space-unit-sm;
    border: 0;
    height: 1px;
    background-color: @borderLight;
}

.separator--inverse {
    background-color: @gray;
}
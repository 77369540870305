.artist-list {
  display: grid;
  grid-gap: @space-unit-xs;
  @media (min-width: @tablet) {
    grid-template-rows: repeat(6, minmax(0, max-content));
    grid-template-columns: repeat( auto-fit, minmax(0, max-content) );
    grid-auto-flow: column;
    grid-row-gap: @space-unit-xsm;
    grid-column-gap: @space-unit-md;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10rem;
  }
  @media (min-width: @desktop){
    padding-right: 20rem;
  }
}

.artist-list--3-cols\@md {
  @media (min-width: @tablet) {
    grid-template-columns: repeat( 3, 1fr );
    padding-right: 0;
  }
}

.artist-list__item a{
  color: @textColorDark;
  transition: .2s;

  &:hover,
  &:focus {
    color: @linkColor;
    text-decoration: none;
  }

}
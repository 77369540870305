/*doc
---
title: Portada Publicaciones
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-portada-publicaciones div.buscador-abajo div.region-bottom-header {
	margin-bottom: 0;
}

body.node-type-portada-publicaciones .form-buscar {
	border-bottom: none;
}

@media (max-width: 767px) {

	body.node-type-portada-publicaciones .form-buscar {
		border-bottom: 1px solid @grayLighter;
	}

}

// Cabecera publicaciones

div#cabecera-publicaciones {
	margin-bottom: 20px;
	padding-top: 50px;
	background: url(../images/fotointropublicaciones.jpg) left top repeat-x @grayDark;
}

div#cabecera-publicaciones h2.titulo {
	margin: 0;
	padding: 6px 10px;
	font: 400 20px/20px @boldScout;
	color: @grayDark;
	background: @beige;
}

@media (min-width: 1281px) {
	
	div#cabecera-publicaciones {
		margin-bottom: 30px;
	}

}

// Listado publicaciones

//div#listado-publicaciones-portada {
//	margin-bottom: 20px;
//	border-bottom: 1px solid @grayLighter;
//}

div#listado-publicaciones-portada > div.row {
	background: url(../images/bk.borde.gris.png) 650px 0 repeat-y;
}

//div#listado-publicaciones-portada div.span8 {
//	background: url(../images/bk.borde.gris.png) center top repeat-y;
//}

@media (min-width: 1281px) {

	div#listado-publicaciones-portada {
		margin-bottom: 30px;
	}

	div#listado-publicaciones-portada > div.row {
		background: url(../images/bk.borde.gris.png) 815px 0 repeat-y;
	}

}

@media (min-width: 768px) and (max-width: 979px) {

	div#listado-publicaciones-portada > div.row {
		background: url(../images/bk.borde.gris.png) 506px 0 repeat-y;
	}

}

@media (max-width: 767px) {

	div#listado-publicaciones-portada > div.row {
		background: none;
	}

	div#listado-publicaciones-portada div.span8 {
		background: none;
	}

}

// Contacto y punto de venta

div#contacto-puntos-venta h3.titulo-bloque {
	padding-left: 10px;
}

div#contacto-puntos-venta div.texto {
	padding: 0 10px;
}

// Bloque boletin

form#boletin-museo input.input-large {
	width: 100%;
	height: 28px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

// Bloque Carta

div#portada-carta div.destacado {
	margin-bottom: 10px;
}

// Bloque boletin
.bloque-boletin p small {
  line-height: 13px;
  display: block;
}

/*doc
---
title: Masonry grid
category: Estilos basicos
---

*/

@gridMasonry2Columnas: (100 - (@masonryGutter2Columnas) ) / 2;
@gridMasonry3Columnas: (100 - (@masonryGutter3Columnas * 2) ) / 3;


.grid-masonry--3-columnas {

	.grid-sizer,
	.grid-item {
		width: @gridMasonry3Columnas;
		@media (max-width: 767px) {
			width: @gridMasonry2Columnas;
		}
	}

	.gutter-sizer {
		width: @masonryGutter3Columnas;
	}	

}

.grid-masonry--2-columnas {

	.grid-sizer,
	.grid-item {
		width: @gridMasonry2Columnas;
	}

	.gutter-sizer {
		width: @masonryGutter2Columnas;
	}

}

.grid-masonry {
	
	.grid-sizer,
	.grid-item {
		@media (max-width: 479px) {
			width: 100%;
		}
	}

	.gutter-sizer {
		@media (max-width: 479px) {
			width: 0%;
		}
	}

}

/* Paginador Load-More */
.grid-masonry--pager {
  ul.pager a {
  	color: #fff !important;
	.btn();
	.btn-inverse();
  }

  .ajax-progress-throbber {
	display: none;
  }
}
/*doc
---
title: Calendario
category: Componentes
---

Documentación...

<div class="alert alert-error">
	Este componente lo extenderemos para todos los calendarios del site.
</div>

```html_example
<div class="calendario">
	<span class="hasDatepicker">
		<div class="ui-datepicker ui-widget" style="display: block;">
			<div class="ui-datepicker-header ui-widget-header">
				<a class="ui-datepicker-prev" title="Anterior">
					<span class="ui-icon">Anterior</span>
				</a>
				<a class="ui-datepicker-next" title="Siguiente">
					<span class="ui-icon">Siguiente</span>
				</a>
				<div class="ui-datepicker-title">
					<select>
						<option>Ene</option>
						<option>Feb</option>
						<option>...</option>
					</select>
					<select>
						<option>2004</option>
						<option>2005</option>
						<option>...</option>
					</select>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th>
							<span title="Lunes">Lu</span>
						</th>
						<th>
							<span title="Martes">Ma</span>
						</th>
						<th>
							<span title="Miércoles">Mi</span>
						</th>
						<th>
							<span title="Jueves">Ju</span>
						</th>
						<th>
							<span title="Viernes">Vi</span>
						</th>
						<th>
							<span title="Sábado">Sá</span>
						</th>
						<th>
							<span title="Domingo">Do</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>
							<a class="ui-state-default" href="#">1</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">2</a>
						</td>
					</tr>
					<tr>
						<td>
							<a class="ui-state-default" href="#">3</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">4</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">5</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">6</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">7</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">8</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">9</a>
						</td>
						</tr>
					<tr>
						<td>
							<a class="ui-state-default" href="#">10</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">11</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">12</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">13</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">14</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">15</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">16</a>
						</td>
					</tr>
					<tr>
						<td>
							<a class="ui-state-default ui-state-highlight" href="#">17</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">18</a>
						</td>
						<td>
							<a class="ui-state-default ui-state-active" href="#">19</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">20</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">21</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">22</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">23</a>
						</td>
					</tr>
					<tr>
						<td>
							<a class="ui-state-default" href="#">24</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">25</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">26</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">27</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">28</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">29</a>
						</td>
						<td>
							<a class="ui-state-default" href="#">30</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</span>
</div>
```
*/

// Mixin para colorear calendario

.calendar-colours(@state-default, @state-default-hover, @state-highlight, @state-active) {
	.ui-state-default {
		background: @state-default;
		&:hover {
			background: @state-default-hover;
		}
	}
	.ui-state-highlight {
		background: @state-highlight;
		&:hover {
			background: @state-highlight;
		}
	}
	.ui-state-active {
		background: @state-active;
		&:hover {
			background: @state-active;
		}
	}
}

// Calendario

.ui-widget {
	font-size:1em;
	table {
		margin:0;
		font-size:1em;
	}
}

.calendario {
	max-width: 270px;
}

.ui-datepicker {
	width:auto;
	padding:0;
	background:none;
	border:none;
	.border-radius(0);
	table {
		width: 100%;
	}
	.ui-datepicker-header {
		padding:5px 0;
		border-left:none;
		border-right:none;
		border-top:none;
		border-bottom:1px solid @grayLighter;
		background:none;
		.border-radius(0);
	}
	.ui-widget-header {
		margin-bottom: 10px;
		position: relative;
		padding: 0 20px 10px;
		text-align: center;
	}
	.ui-datepicker-prev,
	.ui-datepicker-next {
		width:8px;
		height:17px;
		display: block;
		padding: 0;
		position: absolute;
		top: 5px;			
		clear: none;
		background-image:url(../images/ico.flechas-calendario.png);
		background-repeat: no-repeat;
		background-color: transparent;
		&:hover {
			cursor:pointer;
			border: none;
			background-color: transparent;
		}
		.ui-icon {
			display: none;
		}
	}
	.ui-datepicker-prev {
		left: 5px;
		right: auto;
		background-position: 0 0;
		&:hover {
			background-position: 0 -17px;
		}
	}
	.ui-datepicker-next {
		right: 5px;
		left: auto;
		background-position: -8px 0;
		&:hover {
			background-position: -8px -17px;
		}
	}
	.ui-datepicker-title {
		margin: 0;
		select {
			width:42%;
			margin:0 5px;
			font-size: 12px;
			line-height: 14px;
			font-family: @sansFontFamily;
		}
	}
	th {
		padding: 5px;
		font-size: 12px;
		line-height: 14px;
		font-family: @sansFontFamily;
		text-transform:uppercase;
	}
	td {
		padding:0;
		font-family: @sansFontFamily;
		font-size: 13px;
		line-height: 15px;
		span,
		a,
		.ui-state-default {
			display: block;
			padding: 5px;
			border-top:none;
			border-left:none;
			border-bottom:1px solid @white;
			border-right:1px solid @white;
		}
		a:hover {
			text-decoration: none;
		}
	}
	.calendar-colours(@beige, darken(@beige, 10%), @mostaza, @grayDark);
	.ui-state-active {
		color: @white;
	}
}

// Calendario BUSCADOR

#ui-datepicker-div {
	margin-top:-1px;
	padding:5px 20px 20px 20px;
	z-index:100000!important;
	background:@white;
	border:1px solid @grayLighter;
	table {
		margin:0;
	}
}

// Calendario dentro de componente Well

.well{
	.calendario{
		margin-bottom: 30px;
		a{
			color: @grayDark;
			&:hover{
				color: @grayDark;
			}
			&.ui-state-highlight{
				color: @grayDark;
			}
			&.ui-state-active{
				color: @white;
			}
		}
		.ui-datepicker{
			.ui-datepicker-header{
				border-bottom:1px solid @borderLight;
			}
			tbody{
				border-left:1px solid @whiteDarker;
				border-top:1px solid @whiteDarker;
			}
			td{
				span,
				a,
				.ui-state-default{
					border-color: @whiteDarker;
				}
			}
			.calendar-colours(@white, darken(@white, 10%), @mostaza, @grayDark);
		}
	}
}
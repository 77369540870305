/*doc
---
title: Hero unit
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si se esta utilizando este componente de Bootstrap.</div>

*/

.hero-unit{
	margin:0;padding:0;background:none;.border-radius(0);}
.hero-unit p{
	font:400 13px/18px @sansFontFamily;}
@media (min-width: 1281px) {
.hero-unit p{
	font:400 14px/21px @sansFontFamily;}
}
/*doc
---
title: Thumbnails Visita guiada
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.visita-guiada,
	.visita-guiada-lista {

		div.fecha {
			margin-bottom: 5px;
			font-family: @lightScout;
			font-size: 14px;
			line-height: 1;
			color: @grayDark;

			.field {
				margin-bottom: 0;
			}
			
		}

	}

}
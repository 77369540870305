/*doc
---
title: Ficha Visita libre
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-taxonomy-term {

	h1.page-header {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
	}

	div.visita-libres {
		padding-top: 5px;

		div.content {
			border-top: 4px solid @grayDark;
		}

		div.cuerpo {
			padding-top: 20px;
		}

	}

	h2.titulo {
		margin: 0;
		font: 400 34px/34px @thinScout;
		@media (min-width: 1281px) {
			font: 400 44px/44px @thinScout;
		}
	}

	div.field-name-field-visita-guiada-imagen {
		margin-bottom: 20px;
		@media (min-width: 1281px) {
			margin-bottom: 30px;		
		}
	}
}
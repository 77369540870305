/*doc
---
title: Carousel
category: Componentes
---

Documentación...

<div class="alert alert-error">Revisar si se esta utilizando este componente de Bootstrap.</div>

*/

// Carrusel formato estandar de imagen 16:9

div.carousel{
	margin-bottom:20px;}
div.carousel div.item > img{
	display:inline-block;}
a.carousel-control{
	width:24px;height:24px;top:47%;
	line-height:35px;
	background: url('../images/rs-minimal-white.png');border:none;
	opacity:1;filter:none;
	.border-radius(0);}
a.carousel-control.left{
	background-position:-68px -36px;left:5px;}
a.carousel-control.right{
	background-position:-68px -68px;right:5px;}
a.carousel-control.left:hover{
	opacity:1;filter:none; border:none;}
a.carousel-control.right:hover{
	opacity:1;filter:none; border:none;}
a.carousel-control span{
	display:none;}

div.carousel-caption{
	text-align:left;}
div.carousel-caption h4{
	margin:0;
	font:700 14px/16px @sansFontFamily;
	text-transform:none;}
div.carousel-caption p{
	margin:0 0 9px 0;}
@media (min-width: 1281px) {
div.carousel{
	margin-bottom:30px;}
}
@media (max-width: 767px) {
a.carousel-control{
	display:none;}
}
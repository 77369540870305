.site-search {
    @media (min-width: @tablet) {
        display: flex;
        justify-content: space-between;
    }
    @media (min-width: @desktop) {
        margin-bottom: @space-unit-sm;
        padding: @space-unit-xxs @space-unit-xs;
        background-color: @whiteDarker;
    }
}

.site-search__item {

    &:not(:last-child) {
        @media (min-width: @tablet) {
            margin-right: @space-unit-xs;
        }
    }

}

.site-search__label {
    color: @gray;
    @media (min-width: @tablet) {
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        margin-bottom: 0;
    }
}

.site-search__select {
    @media (max-width: @mobile-down) {
        width: 100%;
        height: 37px;
    }
    @media (min-width: @tablet) {
        height: auto;
        margin-bottom: 0;
    }
}

.site-search__input { 
    @media (max-width: @mobile-down) {
        width: 100%;
        height: auto;
        padding-top: 7px;
        padding-bottom: 8px;
    }
    @media (min-width: @tablet) {
        flex-grow: 1;
        height: auto;
        margin-bottom: 0;
    }
}

.site-search__button {
    @media (max-width: @mobile-down) {
        width: 100%;
    }
    @media (min-width: @tablet) {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.site-search__links {
    @media (min-width: @tablet) {
        margin-left: @space-unit-lg;
    }
}

.site-search__links__item {
    display: block;
    font-size: @smallFontSize;
    color: @gray;
	text-decoration: underline;
    &:hover,
    &:focus {
		color: @grayDark;
	}
}
/*doc
---
title: Wells
category: Componentes
---

El componente Well pertenece al catálogo de componentes de Bootstrap. Para este proyecto sobreescribimos los estilos originales y añadimos alguna variación de estilo más.

*/

.well {
	.margin-bottom-sm ();
	.padding-xs ();
	background-color: @whiteDarker;
	border-color: @whiteDarker;
	.border-radius (0);
	.box-shadow (none);
	// Para evitar que se sumen los márgenes inferiores de otros elementos al padding de .well, los dejamos a 0
	.miniatura,
	.media {
		.no-margin-bottom ();
	}
}

.well-large {
	.padding-sm ();
}

.well-medium {
	.padding-xxs ();	
}

.well-small {
	padding: 5px;
}

.well--tramado {
	border-color: transparent;
	background: url('../images/bk.trama.png') 0 0 repeat;
}

.well--borde {
	background-color: transparent;
	border-color: darken(@whiteDarker, 4%);
}

.well--beige {
	background-color: @beige;
	border-color: @beige;
}
/*doc
---
title: Paginador
category: Componentes
---

Documentación...

*/

.pagination {
	margin:0 0 20px 0;
	ul {
		.box-shadow (none);
		.border-radius (0);
	}
	li {
		@media (max-width: 767px) {
			display:none;
		}
		&:first-child,
		&:last-child {
			a,
			span {
				.border-radius (0);
			}
		}
		&:first-child {
			a,
			span {
				border-left-width:1px;
			}
		}
	}
	a,
	ul > li > span {
		padding: 0 11px;
		float: left;
		font-size: 13px;
		line-height: 30px;
		text-decoration: none;
		border: 1px solid @grayLighter;
		border-left-width: 0;
		color:@gray;
	}
	a {
		@media (max-width: 767px) {
			margin-left:-1px;
			border-left-width:1px;
		}
	}
	a:hover,
	.active span {
		background: @whiteDarker;
	}
	.progress-disabled {
		float: left;
	}
	.ajax-progress {
		display:none;
	}
	.prev,
	.pager-ellipsis,
	.next {
		@media (max-width: 767px) {
			display:inline;
		}
	}
}

/* Paginador Load-More */
.pager-load-more {
	margin-bottom: 0;
 
  a {
		padding: 5px 18px 8px 12px;
		font-size: @baseFontSize;
		line-height: @baseLineHeight;
		border-radius: 3px;
		color: @grayDarker;
		border-color: #ebebeb;
		transition: .2s;

    &::before {
			content: '↓';
			margin-right: @space-unit-xxs;
		}

    + .ajax-progress-throbber {
      display: none;
    }

  }

}


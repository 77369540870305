/*doc
---
title: Banner Itinerancias
category: Modulos
---

Banner para destacar el minisite de Itinerancias.



*/

.banner-itinerancias {
	min-height: 190px;
	.margin-bottom-lg ();
	.padding-vertical-sm ();
	.padding-left-sm ();
	padding-right: 460px;
	background-image: url('../images/banner-itinerancias-grande.png');
	background-color: #d1ecf0;
	background-position: 440px bottom;
	background-repeat: no-repeat;
	@media (min-width: 1281px) {
		padding-right: 600px;
		background-position: right bottom;
	}
	@media (max-width: 979px) {
		min-height: inherit;
	}
	@media (min-width: 768px) and (max-width: 979px) {
		padding-right: 300px;
		background-position: 380px bottom;
	}
	@media (max-width: 767px) {
		padding-bottom: 120px;
		.padding-right-sm ();
		background-image: url('../images/banner-itinerancias.png');
		background-position: left bottom;
		background-color: #d2edf1;
	}

	.seccion__cabecera {
		margin-bottom: 10px;
	}

	.entradilla {
		.no-margin-bottom ();
	}

}
.button-share {
    display: inline-flex;
    justify-content: center;
    padding: @space-unit-xxs;
    transition: @transition-base;
    &,
    &:hover {
        color: #fff;
    }
}

.button-share--facebook {
    background-color: @button-share-facebook-bg;
    &:hover {
        background-color: darken(@button-share-facebook-bg,10%);
    }
}

.button-share--twitter {
    background-color: @button-share-twitter-bg;
    &:hover {
        background-color: darken(@button-share-twitter-bg,10%);
    }
}

.button-share--whatsapp {
    background-color: @button-share-whatsapp-bg;
    &:hover {
        background-color: darken(@button-share-whatsapp-bg,10%);
    }
}

/*doc
---
title: Tabs
category: Componentes
---

Ajuste para evitar que las anclas de las pestañas se queden bajo la barra amarilla. Fuente: [Stackoverflow](http://stackoverflow.com/questions/9047703/fixed-position-navbar-obscures-anchors).

*/

@navLinkColor:   darken(@grisClaro, 10%);
@navLinkColorHover: @grayDark;
@navBorderColor: @borderLighter;

// Ajuste para evitar que las anclas de las pestañas se queden bajo la barra amarilla

.tab-content > .tab-pane{
  padding-top: 100px;
  margin-top: -100px;
}

.destino-ancla {
  padding-top: 30px;
  margin-top: -30px;
}


// NAVIGATIONS
// -----------

// BASE CLASS
// ----------

.nav {
  margin-top: @space-unit-xs;
  margin-bottom: @space-unit-xsm;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-sm;
  }
}
.nav > li > a:hover {
  background-color: @whiteDarker;
}

/**
 * Tabs
 */

.nav-tabs > li {
  @media (max-width: 767px) {
    float: none;
  }

  &.expanded {

    > a {
      padding-bottom: 5px;
      background-color: transparent;
      border-bottom-color: transparent;
    }

    > ul {
      margin-left: 0;
      padding-bottom: 10px;
      list-style: none;

      > li {

        > a {
          display: block;
          padding: 5px 15px 5px 33px;
          font-family: @lightScout;
          font-size: 14px;
          line-height: 1.1;
          color: @navLinkColor;
          &:hover {
            color: @navLinkColorHover;
            text-decoration: none;
          }

          &.active {
            color: @navLinkColorHover;
            background: url('../images/check.svg') 15px 6px no-repeat;
          }

        }

      }

    }

  }

  > a {
  	margin-right: -1px;
    padding: 12px 15px;
    font-size: @baseFontSize;
    line-height: @headingsLineHeight;
    color: @navLinkColor;
    background-color: darken(@whiteDark, 1%);
    border-color: @navBorderColor;
    .border-radius(0 0 0 0);
    transition: .2s;
    @media (max-width: 767px) {
    	border-width: 1px 0 1px 0;
    }
    &:hover{
      border-color: @navBorderColor;
    	color: @navLinkColorHover;
    }
  }

}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  font-weight: bold;
	color: @grayDark;
  border-color: @navBorderColor;
  border-bottom-color: transparent;
	@media (max-width: 767px) {
		border-width: 1px 0 1px 0;
  	border-bottom-color: @navBorderColor;
	}
}

// STACKED NAV
// -----------

// Tabs
.nav-tabs.nav-stacked > li > a {
  border-width: 1px 0 1px 0;
  .border-radius(0);
}
.nav-tabs.nav-stacked > li:first-child > a {
  .border-radius(0 0 0 0);
}
.nav-tabs.nav-stacked > li:last-child > a {
  .border-radius(0 0 0 0);
}
.nav-tabs.nav-stacked > .active > a,
.nav-tabs.nav-stacked > .active > a:hover {
  border-width: 1px 0 1px 0;
}


// LEFT & RIGHT
// ------------

// Common styles
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs{
  margin-top: 0;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  margin-bottom: -1px;
  border-width: 1px 0 1px 0;
}

// Tabs on the left
.tabs-left > .nav-tabs {
  margin-right: 30px;
  @media (max-width: 767px) {
  	margin-right: 0;
  	float: none;
  }
}
.tabs-left > .nav-tabs > li > a {
  .border-radius(0 0 0 0);
}
.tabs-left > .nav-tabs > li > a:hover {
  border-color: @navBorderColor;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
  border-width: 1px 0 1px 0;
}

// Tabs on the right
.tabs-right > .nav-tabs {
  margin-left: 30px;
  @media (max-width: 767px) {
  	margin-right: 0;
  	float: none;
  }
}
.tabs-right > .nav-tabs > li > a {
  .border-radius(0 4px 4px 0);
}
.tabs-right > .nav-tabs > li > a:hover {
  border-color: @navBorderColor;
}

/**
 * Menú planificar visita
 */

.menu-planificar .nav-tabs,
.menu-visita-menu .nav-tabs,
.nav--submenu {
  margin-top: 0;
  margin-bottom: 30px;
  border-left: 1px solid @navBorderColor;
  border-right: 1px solid @navBorderColor;

  > li {

    > a {
      margin-right: 0;
    }

  }

}

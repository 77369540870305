/*doc
---
title: Obra del mes
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#obra-del-mes{
	h3.titulo-bloque{
		padding:10px 10px 0 10px;
		border-top:4px solid #333333;
	}
	div.views-row{
		padding:0 10px 10px 10px;
	}
}
.color-gris-claro {
    color: @grisClaro !important;
}

.color-gray {
    color: @gray !important;
}

.color-gray-light {
    color: @grayLight !important;
}

.color-gray-dark {
    color: @grayDark !important;
}

.color-gray-darker {
    color: @grayDarker !important;
}

.color-white {
    color: #fff !important;
}
.link-icon {
    display: inline-flex;
    align-items: center;
    color: @linkColor;
    text-decoration: underline;
    &:hover {
        color: @linkColorHover;
    }
}

.link-icon--lg {
    .font-size-large;
    font-family: @boldScout;
    text-decoration: none;
}

.link-icon--icon-left {
    .link-icon__icon {
        margin-left: 0;
        margin-right: @space-unit-xxxs;
    }
}

button.link-icon {
    padding: 0;
    border: none;
    background: none;
    &:focus {
        outline: 0;
    }
}

.link-icon__icon {
    margin-left: @space-unit-xxxs;
}
.sponsors {
  display: flex;
  flex-wrap: wrap;
}

.sponsors--centered {
  @media (min-width: @tablet) {
    justify-content: center;
    text-align: center;
  }

  .sponsors__group {
    &,
    &:not(:last-child) {
      @media (min-width: @tablet) {
        margin-left: @space-unit-lg / 2;
        margin-right: @space-unit-lg / 2;
      }
    }
  }

  .sponsors__group-link {
    &,
    &:not(:last-child) {
      @media (min-width: @tablet) {
        margin-left: @space-unit-xsm / 2;
        margin-right: @space-unit-xsm / 2;
      }
    }
  }
}

.sponsors__group {
  margin-bottom: @space-unit-sm;
  &:not(:last-child) {
    margin-right: @space-unit-sm;
    @media (min-width: @tablet) {
      margin-right: @space-unit-lg;
    }
  }
}

.sponsors__group-title {
  .title-style;
  .font-size-title4;
  margin-bottom: @space-unit-xxxs;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xxs;
  }
}

.sponsors__group-body {
  display: flex;
  flex-wrap: wrap;
}

.sponsors__group-link {
  display: block;
  flex-shrink: 0;
  max-width: 100%;
  font-size: @smallFontSize;
  &:not(:last-child) {
    margin-right: @space-unit-xs;
    @media (min-width: @tablet) {
      margin-right: @space-unit-xsm;
    }
  }

  img {
    max-height: 70px;
  }
  
}

.mfp-gallery-image--vertical {

    .mfp-item {
        display: block;
        margin-bottom: @space-unit-sm;
    }

}

button.mfp-arrow {
    width: 24px;
    height: 24px;
    margin: 0;
    opacity: 1;

    &:before {
        width: 24px;
        height: 24px;
        margin: 0;
        background: url('../images/rs-minimal-white.png');
        border: none;
        opacity: 1;
    }
    &:after {
        content: none;
    }
    &:active {
        margin-top: 0;
    }
}

button.mfp-arrow-left {
    left: 5px;
    &:before {
        background-position: -68px -36px;
    }
}

button.mfp-arrow-right {
    right: 5px;
    &:before {
        background-position: -68px -68px;
    }
}

.mfp-close {
    opacity: 1;
}

img.mfp-img {
    padding-bottom: 10px;
}

.mfp-bottom-bar {
    margin-top: 0;
}

.mfp-title,
.mfp-counter {
    font-family: @thinScout;
    font-size: @baseFontSize;
    line-height: @smallLineHeight;
    color: #fff;
}

.mfp-zoom-out-cur {
    &,
    & .mfp-image-holder .mfp-close {
        cursor: pointer;
    }
}

.mfp-image-holder, 
.mfp-iframe-holder {
    .mfp-close {
        width: auto;
    }
}
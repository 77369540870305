/*doc
---
title: Ficha Entrada
category: Modulos
---

Estilos específicos para la ficha de Entrada.

<div class="alert alert-error">Hay que cambiar la maquetación del título de esta página para comenzar a utilizar el componente <a href="../documentacion/componentes.html#Título_de_página">"Título página"</a>, elminando los estilos que se aplican específicamente a los títulos de este tipo de contenidos.</div>

<div class="alert alert-error">Se puede mejorar la maquetación del bloque #ventra-entradas para que no necesitemos estilos específicos.</div>

*/

// Estilos específicos para los títulos de este tipo de contenido

.node-type-entrada {

    .page-header {
        .no-padding-bottom ();
        .no-margin-bottom ();
        border-bottom: none;
    }

}

// Estilos específicos para los títulos de este tipo de contenido

#venta-entradas {
    .no-margin-bottom ();
    
    .well {
        .margin-bottom-xs ();
    }
    
}
.minisite-title {
    margin-left: @space-unit-xxxs;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    @media (min-width: @tablet) {
        margin-left: @space-unit-xs;
    }
}

.minisite-title__antetitle {
    flex-basis: 100%;
    font-size: @fontScale0;
    font-weight: bold;
    line-height: @headingsLineHeight;
    text-transform: uppercase;
    @media (max-width: @mobile-down) {
        font-size: @fontScale-3;
    }
}

.minisite-title__title {
    .title-style;
    flex-basis: 100%;
    font-size: @fontScale6;
    @media (max-width: @mobile-down) {
        font-size: @fontScale4;
    }
}

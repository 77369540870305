/*doc
---
title: Portada Actividades
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-actividades div.buscador-abajo div.region-bottom-header {
	margin-bottom: 10px;
}

// Actividades actuales

#actividades {

	> .titulo {
		margin-bottom: 0;
		padding-bottom: 15px;
		position: relative;
		background: @white;
		@media (min-width: 1281px) {
			padding-bottom: 15px;
		}
	}

	ul.thumbnails {
		border-bottom: none;

		.thumbnail {

			h3.titulo {
				font-size: 22px;
			}

			h4 {
				font-size: 22px;
			}

			.fecha {
				font-family: @lightScout;
				font-size: 14px;
				line-height: 1;
				color: @grayDark;
			}

			div.field-name-field-actividad-lugar-fueramuseo {
				font-family: @boldScout;
				font-size: 14px;
				line-height: 1;
				color: @grayLight;
			}

		}

	}

}



// Futuras

#actividades-futuras {
	
	.titulo-bloque {
		font-family: @boldScout;
		font-size: 22px;
		line-height: 1;
	}

}

// Pasadas

#actividades-pasadas {

	.titulo-bloque {
		font-family: @boldScout;
		font-size: 22px;
		line-height: 1;
	}

}
.publication-cover-styles(@aspect-ratio) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: @space-unit-md;
    aspect-ratio: @aspect-ratio;
    background-color: @borderLighter;
    box-sizing: border-box;

    img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: 0px 2px 2px fade(#000, 14%), 0px 3px 1px fade(#000, 12%), 0px 1px 5px fade(#000, 20%);
    }

}

.publication-cover-styles-product(@aspect-ratio) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: @space-unit-md;
  aspect-ratio: @aspect-ratio;
  background-color: @borderLighter;
  box-sizing: border-box;
}

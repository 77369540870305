.flexslider {
	margin: 0 0 1px 0;
	border: none;
	.box-shadow(0 0 0 transparent);
	.border-radius(0);
}

#carousel .flex-viewport .slides li{
	margin-right: 1px;
}

#carousel {
	margin-bottom: 20px;
}

ul.flex-direction-nav a {
	width: 24px;
	height: 24px;
	margin: 0;
	top: 47%;
	-webkit-transition: none;
	background: url('../images/rs-minimal-white.png');
	opacity: 1;
	.border-radius(0);
}

ul.flex-direction-nav a.flex-next {
	opacity: 1;
	right: 5px;
	background-position: -68px -68px;
}

ul.flex-direction-nav a.flex-prev {
	background-position: -68px -36px;
	opacity: 1;
	left: 5px;
}

ul.flex-direction-nav a.flex-next:hover {
	opacity: 1;
	right: 5px;
}

ul.flex-direction-nav a.flex-prev:hover {
	opacity: 1;
	left: 5px;
}

.flexslider:hover a.flex-next {
	opacity: 1;
	right: 5px;
}

.flexslider:hover a.flex-prev {
	opacity: 1;
	left: 5px;
}

div#carousel ul.flex-direction-nav a{
	top: 44%;
}

div#carousel ul.flex-direction-nav a.flex-next {
	display: none;
}

div#carousel ul.flex-direction-nav a.flex-prev {
	display: none;
}

div#carousel:hover ul.flex-direction-nav a.flex-next {
	display: block;
}

div#carousel:hover ul.flex-direction-nav a.flex-prev {
	display: block;
}


// Pies de foto
.flexslider ul.slides > li{
	position: relative;
}

.flexslider ul.slides .flex-caption{
	width: 100%;
	min-height: 30px;
	margin: 0;
	padding: 5px;
	position: absolute; 
	bottom: 0; 
	left: 0;
	font: 400 12px/14px @sansFontFamily;
	background: @grayDark; 
	color: @white;
	opacity: 0.8;
	filter:alpha(opacity=80);
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.flexslider ul.slides > li:hover .flex-caption{
	background: @beige;
	color: @grayDark;
}

@media (min-width: 1281px) {
	#carousel{
		margin-bottom: 30px;
	}
}
/*doc
---
title: Alertas
category: Componentes
---

Descripción...

<div class="alert alert-error">Revisar las alertas de la home y otras páginas.</div>

*/

.alert {
	margin-bottom: 30px;
	padding: 15px 30px 15px 15px;
	position: relative;
	font-family: @thinScout;
	font-size: 15px;
	border: none;
	.border-radius (0);
	text-shadow: none;

	.close {
		position: absolute;
		top: 13px;
		right: 15px;
	}

	p {
		font-family: inherit;
		font-size: inherit;
		margin-bottom: 0;
	}
	
	.row-fluid [class*="span"] {
		min-height: inherit;
	}

	strong {
		font-family: @regularScout;
		font-weight: normal;
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

}

.image-slider {
    width: 100%;

    iframe,
    .image-slider-container-video {
      width: 100%;
      height: 100%;
    }
    
    .rsArrow {
        display: flex;
        position: absolute;
        transform: translateY(-50%);
    }

    .rsArrowLeft {
        left: 0;
        top: 50%;
    }

    .rsArrowRight {
        right: 0;
        top: 50%;
    }

    .rsArrowIcn {
        width: 24px;
        height: 36px;
        top: 50%;
        left: 50%;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-color: @beige;
        transition: .2s;
        @media (min-width: @tablet) {
            width: 32px;
            height: 44px;
        }
        @media (min-width: @desktop) {
            width: 52px;
            height: 68px;
        }
    }

    .rsArrowIcn:hover {
        background-color: @beigeHover;
    }

    .rsArrowLeft .rsArrowIcn {
        background-image: url("../templates/includes/icons/arrow-small-left.svg");
    }

    .rsArrowRight .rsArrowIcn {
        background-image: url("../templates/includes/icons/arrow-small-right.svg");
    }

}

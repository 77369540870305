/*doc
---
title: Miniaturas
category: Componentes
---

```html_example
<div class="row">
    <div class="span4">

        <!-- Miniatura dentro de una columna de 4 unidades -->
        <article class="miniatura">
            <a href="" class="miniatura__etiqueta-principal etiqueta">Etiqueta principal</a>
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen">
                    <img src="http://placehold.it/370x277">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura dentro de una columna de 4 unidades -->

    </div>
    <div class="span3">

        <!-- Miniatura dentro de una columna de 3 unidades -->
        <article class="miniatura">
            <a href="" class="miniatura__etiqueta-principal etiqueta">Etiqueta principal</a>
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen">
                    <img src="http://placehold.it/370x277">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura dentro de una columna de 3 unidades -->

    </div>
</div>
```

##Miniatura con imagen alineada en eje vertical##

Muchas imágenes que utilizaremos en las miniaturas no pueden ser recortadas y tienen que conservar sus proporciones respecto a la imagen original. En estos casos añadiremos la clase .miniatura__imagen--centrada-vertical para que aparezcan alineadas cuando varias miniaturas en una misma fila.

Esta variante del componente miniatura no admite el elemento .miniatura__etiqueta-principal ya que debería pisar la imagen, pero muchas veces la imagen no tiene altura suficiente.

```html_example
<div class="row">
    <div class="span3">

        <!-- Miniatura con imagen alineada en eje vertical -->
        <article class="miniatura">
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen miniatura__imagen--centrada-vertical">
                    <img src="http://placehold.it/370x100">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura con imagen alineada en eje vertical -->

    </div>
    <div class="span3">

        <!-- Miniatura con imagen alineada en eje vertical -->
        <article class="miniatura">
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen miniatura__imagen--centrada-vertical">
                    <img src="http://placehold.it/370x150">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura con imagen alineada en eje vertical -->

    </div>
</div>
```


##Miniatura con forma de caja##

```html_example
<div class="row">
    <div class="span3">

        <!-- Miniatura con forma de caja -->
        <article class="miniatura miniatura--caja">
            <a href="" class="miniatura__etiqueta-principal etiqueta">Etiqueta principal</a>
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen">
                    <img src="http://placehold.it/370x277">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura con forma de caja -->

    </div>
</div>
```

##Miniatura de la sección Educación##

La sección de Educación tiene una paleta de colores propia que es aplicable al componente miniatura. Estas son sus variantes: ´miniatura--color-educacion-1, 'miniatura--color-educacion-2', 'miniatura--color-educacion-3', 'miniatura--color-educacion-4', 'miniatura--color-educacion-5', 'miniatura--color-educacion-6', 'miniatura--color-educacion-7', 'miniatura--color-educacion-8'...

```html_example
<div class="row">
    <div class="span3">

        <!-- Miniatura con forma de caja -->
        <article class="miniatura miniatura--caja miniatura--color-educacion-1">
            <a href="" class="miniatura__etiqueta-principal etiqueta">Etiqueta principal</a>
            <a href="" class="enlace--bloque">
                <div class="miniatura__imagen">
                    <img src="http://placehold.it/370x277">
                </div>
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura con forma de caja -->

    </div>
</div>
```

##Miniatura con imagen de fondo##

La imagen de fondo normalmente sera gestionable así que se incluirá utilizando un estilo en línea. Se recomienda utilizar imágenes a 16:9, siendo su tamaño máximo 1170px por 658px.

Este tipo de miniaturas no soporta ni etiquetas ni botones incluidos mediante el elemento 'miniatura__acciones'.

Esta variante del componente miniatura no admite el elemento .miniatura__etiqueta-principal ya en esa posición aparece el elemento .miniatura__seccion.

```html_example
<!-- Miniatura con imagen de fondo -->
<article class="miniatura miniatura--fondo-imagen" style="background-image: url(http://lorempixel.com/g/400/200/abstract/9/);">
    <a href="" class="enlace--bloque">
        <p class="miniatura__seccion">Sección</p>
        <div class="miniatura__pie">
            <h3 class="miniatura__titulo">Título</h3>
            <h4 class="miniatura__subtitulo">Subtítulo</h4>
            <p class="miniatura__texto">Texto</p>
        </div>
    </a>
</article>
<!-- / Miniatura con imagen de fondo -->
```

##Miniatura para contenido embebido##

Esta variante sirve tanto para incluir un vídeo de terceros como para incluir el player de las cápsulas de audio. Basta con incluir el contenido de terceros dentro del elemento .miniatura__contenido-embebido.

```html_example
<div class="row">
    <div class="span4">

        <!-- Miniatura dentro de una columna de 4 unidades -->
        <article class="miniatura">
            <a href="" class="miniatura__etiqueta-principal etiqueta">Etiqueta principal</a>

            <div class="miniatura__contenido-embebido">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe src="https://player.vimeo.com/video/127263231?title=0&byline=0&portrait=0" width="1000" height="563" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
            </div>

            <a href="" class="enlace--bloque">
                <div class="miniatura__pie">
                    <p class="miniatura__antetitulo">Antetítulo</p>
                    <h3 class="miniatura__titulo">Título</h3>
                    <h4 class="miniatura__subtitulo">Subtítulo</h4>
                    <p class="miniatura__texto">Texto</p>
                </div>
            </a>
            <div class="miniatura__acciones">
                <div class="miniatura__acciones__accion">
                    <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                </div>
                <div class="miniatura__acciones__accion">
                    <a href="" class="btn btn-small">Acción</a>
                </div>
            </div>
        </article>
        <!-- / Miniatura dentro de una columna de 4 unidades -->

    </div>
</div>
```

##Miniatura tipo media object##

Esta variante sirve para dar forma de objeto multimedia al componente miniatura.

```html_example
<div class="row">
    <div class="span4">

        <!-- Miniatura dentro de una columna de 4 unidades -->
        <article class="miniatura">
            <div class="media">
                <div class="media__media-izquierda">
                    <a href="" class="imagen--publicacion">
                      <img src="http://placehold.it/110x118">
                    </a>
                </div>
                <div class="media__contenido">
                    <a href="" class="enlace--bloque">
                        <div class="miniatura__pie">
                            <h3 class="miniatura__titulo">Arquitectura</h3>
                        </div>
                    </a>
                    <div class="miniatura__acciones">
                        <div class="miniatura__acciones__accion">
                            <a href="" class="btn btn-small">Descargar PDF <span class="icon-download"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <!-- / Miniatura dentro de una columna de 4 unidades -->

    </div>
</div>
```

La utilizaremos también en la visualización de los resultados de búsqueda apilados.

```html_example
<div class="list">

    <!-- Miniatura dentro de una lista de resultados apilados -->
    <article class="miniatura">
        <div class="media">
            <div class="media__media-izquierda">
                <a href="">
                    <img src="http://placehold.it/370x277">
                </a>
            </div>
            <div class="media__contenido">
                <a href="" class="enlace--bloque">
                    <div class="miniatura__pie">
                        <p class="miniatura__antetitulo">Antetítulo</p>
                        <h3 class="miniatura__titulo">Título</h3>
                        <h4 class="miniatura__subtitulo">Subtítulo</h4>
                        <p class="miniatura__texto">Texto</p>
                    </div>
                </a>
                <div class="miniatura__acciones">
                    <div class="miniatura__acciones__accion">
                        <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                    </div>
                    <div class="miniatura__acciones__accion">
                        <a href="" class="btn btn-small">Acción</a>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <!-- / Miniatura dentro de una columna de 4 unidades -->

    <!-- Miniatura dentro de una lista de resultados apilados -->
    <article class="miniatura">
        <div class="media">
            <div class="media__media-izquierda">
                <a href="">
                    <img src="http://placehold.it/370x277">
                </a>
            </div>
            <div class="media__contenido">
                <a href="" class="enlace--bloque">
                    <div class="miniatura__pie">
                        <p class="miniatura__antetitulo">Antetítulo</p>
                        <h3 class="miniatura__titulo">Título</h3>
                        <h4 class="miniatura__subtitulo">Subtítulo</h4>
                        <p class="miniatura__texto">Texto</p>
                    </div>
                </a>
                <div class="miniatura__acciones">
                    <div class="miniatura__acciones__accion">
                        <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                    </div>
                    <div class="miniatura__acciones__accion">
                        <a href="" class="btn btn-small">Acción</a>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <!-- / Miniatura dentro de una columna de 4 unidades -->

    <!-- Miniatura dentro de una lista de resultados apilados -->
    <article class="miniatura">
        <div class="media">
            <div class="media__media-izquierda">
                <a href="">
                    <img src="http://placehold.it/370x277">
                </a>
            </div>
            <div class="media__contenido">
                <a href="" class="enlace--bloque">
                    <div class="miniatura__pie">
                        <p class="miniatura__antetitulo">Antetítulo</p>
                        <h3 class="miniatura__titulo">Título</h3>
                        <h4 class="miniatura__subtitulo">Subtítulo</h4>
                        <p class="miniatura__texto">Texto</p>
                    </div>
                </a>
                <div class="miniatura__acciones">
                    <div class="miniatura__acciones__accion">
                        <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a> <a href="" class="etiqueta">Etiqueta</a>
                    </div>
                    <div class="miniatura__acciones__accion">
                        <a href="" class="btn btn-small">Acción</a>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <!-- / Miniatura dentro de una columna de 4 unidades -->

</div>
```

*/

.miniatura {
    position: relative;
    margin-bottom: @space-unit-sm;
    @media (max-width: 767px) {
        max-width: 370px;
    }

    .media {
        .no-margin-bottom ();
    }

}

/**
 * Etiqueta principal
 */

.miniatura__etiqueta-principal {
    .no-margin-horizontal ();
    .no-margin-vertical ();
    padding: 10px 17px 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: @largeFontSize - 2;
}

/**
 * Imagen
 */

.miniatura__imagen {
    position: relative;
    overflow: hidden;
    .margin-bottom-xs ();

    img {
        position: relative;
    }

}

.miniatura__imagen--legacy {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: @space-unit-sm;
  aspect-ratio: 4/3;
  background-color: @borderLighter;
  box-sizing: border-box;

  img {
    max-width: 100%;
    max-height: 180px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    @media (min-width: 980px) {
        max-height: 110px;
    }
    @media (min-width: 980px) {
        max-height: 165px;
    }
    @media (min-width: 1281px) {
        max-height: 180px;
    }
  }
  

}

.miniatura__imagen--centrada-vertical {
    @media (min-width: 768px) {
        height: 0;
        padding-bottom: 75%;
    }
    img {
        @media (min-width: 768px) {
            margin: auto 0;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            max-height: 100%;
        }
    }
}

.miniatura__imagen__veladura {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 10%;
    font-size: 0px;
    line-height: 0;
    background-color: fade(@grayDark, 80%);
    .transition (all 200ms ease-out);
    opacity: 0;
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }
}

.miniatura__imagen__veladura__texto {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: auto;
    color: #fff;
}

/**
 * Contenido embebido
 */

.miniatura__contenido-embebido {
    .margin-bottom-xs ();
}

/**
 * Pie
 */

.miniatura__pie {
    margin-bottom: 10px;
}

.miniatura__logo {
    margin-bottom: 5px;
}

.miniatura__antetitulo {
    .antetitle-style;
    &:not(:last-child) {
        margin-bottom: @space-unit-xxs;
    }

    strong {
        font-family: @regularScout;
        font-weight: normal;
    }

}

.miniatura__titulo {
    .title-style;
    .font-size-title4;
    &:not(:last-child) {
        margin-bottom: @space-unit-xxxs;
    }
}

.miniatura__titulo--cursiva {
    font-family: @boldScoutItalic;
}

.miniatura__subtitulo {
    .subtitle-style;
    .font-size-title4;
    &:not(:last-child) {
        margin-bottom: @space-unit-xxxs;
    }
}

.miniatura__subtitulo--cursiva {
    font-family: @thinScoutItalic;
}

.miniatura__texto {
    margin-bottom: 5px;
    color: @gray;

    &,
    p {
        font-size: @baseFontSize;
    }
}

.miniatura__texto--reducido {
    margin-bottom: 5px;
    &,
    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        font-family: @sansFontFamily;
        color:@grayLight;
    }
}

.miniatura__precio {
    margin-right: 10px;
    font:400 20px/20px @thinScout;
}

.miniatura__creditos {
    padding-top: @space-unit-xxxs;
    font-size: @smallFontSize;
    line-height: @smallLineHeight;
    color: @grisClaro;
}

/**
 * Acciones
 */

.miniatura__acciones__accion {
    margin-bottom: 5px;
    &:last-child {
        margin-bottom: 0;
    }
}

.miniatura__acciones--sobre-imagen {
    position: absolute;
    top: 7px;
    right: 7px;
}

/**
 * Variante: miniatura con forma de caja
 */

.miniatura--caja {
    background-color: #fff;
    border: 1px solid @borderLighter;
    &:hover {
        border-color: darken(@borderLighter, 5%);
    }

    .miniatura__imagen {
        text-align: center;
        background-color: @whiteDarker;
    }

    .miniatura__contenido-embebido--reproductor-audio {
        .padding-horizontal-xs ();
    }

    .miniatura__pie {
        .padding-horizontal-xs ();
    }

    .miniatura__acciones {
        .padding-horizontal-xs ();
        padding-bottom: 10px;
    }

}

/**
 * Hacemos otra variante para darle una altura mínima para que las miniaturas
 * de una misma serie tengana la misma altura.
 */

.miniatura--caja--alto-minimo {
    min-height: @miniaturaCajaAltoMinimo;
    @media (max-width: 767px) {
        min-height: inherit;
    }
}

/**
 * Añadimos otra variante para fijar las acciones de la miniatura en la parte
 * inferior.
 */

.miniatura--caja--acciones-abajo {
    .padding-bottom-md ();
    .miniatura__acciones {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

/**
 * Variante: miniatura con imagen de fondo.
 */

.miniatura--fondo-imagen {
    position: relative;
    min-height: 225px;
    background-size: cover;
    background-position: center;
    @media (max-width: 1281px) {
        min-height: 277px;
    }
    @media (min-width: 768px) and (max-width: 979px) {
        min-height: 171px;
    }
    @media (max-width: 767px) {
        min-height: 225px;
    }
    .enlace--bloque {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .padding-xs ();
        .box-sizing(border-box);
        background-image: -webkit-gradient(linear, left 0%, left 100%, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0)));
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#00000000', GradientType=0);
        .transition (all 200ms ease-out);
        &:hover {
            background-image: -webkit-gradient(linear, left 0%, left 100%, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0)));
            background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0);
        }
    }
    .miniatura__seccion {
        color: #fff;
        font-family: @boldScout;
        line-height: 1.1;
        // Aplico el mismo tamaño que a los títulos del componente Sección.
        .font-size-seccion__cabecera__titulo ();
    }
    .miniatura__pie {
        .center ();
        .no-margin-bottom ();
        text-align: center;
    }
    .miniatura__titulo {
        color: #fff;
        font-size: 38px;
        @media (max-width: 979px) {
            font-size: 28px;
        }
    }
    .miniatura__subtitulo {
        color: #fff;
        font-size: 36px;
        @media (max-width: 979px) {
            font-size: 26px;
        }
    }
}

/**
 * Cambio la maquetación de '.miniatura--fondo-imagen .miniatura__pie' para IE8
 */

.lt-ie9,
.lt-ie10 {
    .miniatura--fondo-imagen {
        .miniatura__seccion {
            .margin-bottom-sm ();
        }
        .miniatura__pie {
            .translate (0%, 0%);
            position: static;
            text-align: left;
        }
    }
}

/**
 * Cambio la maquetación de '.miniatura--fondo-imagen' para IE8
 */

.lt-ie9 {
    .miniatura--fondo-imagen {
        background-position: center;
    }
}

.miniatura__enlace {
    &:hover {
        .miniatura__imagen__veladura {
            .opacity (100);
        }
    }
}

/**
 * Variante: Miniatura destacada
 */

.miniatura--destacada {
    @media (max-width: 767px) {
        margin-left: -30px;
        margin-right: -30px;
        max-width: none;
    }

    .enlace--bloque {
        &:hover {
            .opacity (100);
        }
    }

    .miniatura__antetitulo {
        .margin-bottom-xs ();
        .font-size-miniatura-destacada-antetitulo ();
    }

    .miniatura__titulo {
        .font-size-miniatura-destacada-titulo ();
    }

    .miniatura__subtitulo {
        margin-bottom: 10px;
        .font-size-miniatura-destacada-subtitulo ();
    }

    .miniatura__acciones {
        margin-bottom: 10px;
    }

}

.miniatura--destacada__imagen-fondo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/**
 * Utilizo un elemento extra para quitar el estilo de caja a una serie de miniaturas
 */

.miniaturas-sin-caja {

    .miniatura {

        &.miniatura--caja {
            background-color: transparent;
            border: none;

            .miniatura__imagen {
                text-align: left;
                background-color: transparent;
            }

            .miniatura__contenido-embebido--reproductor-audio {
                .no-padding-horizontal ();
            }

            .miniatura__pie {
                .no-padding-horizontal ();
            }

            .miniatura__acciones {
                .no-padding-horizontal ();
                .no-padding-bottom ();
            }

        }

    }

}

.miniaturas-sin-caja-variante-color (@color, @color-claro) {

    .etiqueta {
        .miniatura-etiqueta-variante-color (@color-claro);
    }

    .miniatura__etiqueta-principal {
        .miniatura-etiqueta-principal-variante-color (@color);
    }

}

.miniatura--ancho-completo {
    margin-bottom: 0;

    .miniatura__imagen {
        display: block;
        @media (min-width: @tablet) {
            height: 350px;
            margin-bottom: 0 !important;
        }
        img {
            @media (min-width: @tablet) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .miniatura__textos {
        @media (min-width: @tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

/* Miniatura de actividades dentro de paragraph contenido relacionado*/
.field-name-field-p-relacionado-entidad {
    @media (min-width: @tablet) {
        .miniatura {
             .no-margin-bottom ();
        }
    }
}

.miniatura--horizontal {
    margin-bottom: 0;

    .enlace--bloque {
        display: grid;
        gap: @space-unit-xs;
        grid-template-columns: repeat(2, 1fr) !important;
        @media (min-width: @tablet) {
            gap: @space-unit-sm;
        }
    }
}

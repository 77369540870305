/*doc
---
title: Resultados de búsqueda
category: Componentes
---

Documentación...

<div class="alert alert-error">Este componente es posible que se pueda descomponer en varios.</div>

*/

// Visualizacion lista de resultados
div.paginado-fila{ margin-bottom:20px;height:32px;position:relative;}
div.paginado-fila div.pagination{margin:0;}

div.items-pagina{
	position:absolute; top:0; right:0px;}
div.items-pagina ul{
	margin:0;display:inline-block;}
div.items-pagina ul li{
	display:inline;}
div.items-pagina ul li a{
	padding:0 7px;height:30px;float:left;
	line-height: 30px;
	text-decoration: none;border:1px solid @grayLighter;border-left-width: 0; color:@gray;}
div.items-pagina ul li a:hover{
	background:@whiteDarker;}
div.items-pagina ul li:first-child a{
	border-left-width:1px;}
div.items-pagina ul li.selected a,
div.items-pagina ul li a.selected{
	background:@whiteDarker; color:@grisClaro;}
@media (max-width: 979px) {
div.items-pagina{display:none;}
}


div.estilo-fila{ position:absolute; top:0; right:100px;}
ul#rowStyle{ margin:0; display:inline-block;}
ul#rowStyle li{ display:inline;}
ul#rowStyle li a{width:30px;height:30px;float: left;line-height: 30px;text-decoration: none;border: 1px solid @grayLighter;border-left-width: 0;}
ul#rowStyle li:first-child a{border-left-width:1px;}
ul#rowStyle li a span{ display:none;}
ul#rowStyle a#rowStyleGrid{background:url(../images/ico.grid.png) 7px 7px no-repeat @white;}
ul#rowStyle a#rowStyleList{background:url(../images/ico.list.png) 7px 7px no-repeat @white;}
ul#rowStyle a#rowStyleGrid:hover,
ul#rowStyle a#rowStyleList:hover{background-color:@whiteDarker;}
ul#rowStyle li a#rowStyleGrid.selected,
ul#rowStyle li a#rowStyleList.selected{
	background-color:@whiteDarker; color:@grisClaro;}
@media (max-width: 979px) {
div.estilo-fila{
	right:0;}
}

// Pagina resultados
ul#results_list{width:100%;margin:0 0 20px 0;overflow:hidden;}
ul#results_list li{list-style:none;}
ul#results_list li.limpiar{ margin-left:0; clear:both;}
ul#results_list li.limpiar .span3{margin-left:0;}

// Tipo contenido
div.tipo_contenido{
	padding:4px 5px 3px 5px;display:block;
	font:400 14px/14px @regularScout;
	background:@whiteDark;color:@grayDark; text-transform:uppercase;border-bottom:1px solid #ddd;}
ul.grid div.tipo_contenido{
	margin-bottom:10px;
	border-top:1px solid #ddd;}
/*doc
---
title: Compartir
category: Componentes
---

Documentación...

*/

/**
 * Carcasa y título
 */

#compartir {
  margin-bottom: 5px;

  p {
	display: none;
	margin: 0 0 5px 0;
	font-size: @smallFontSize;
	line-height: 1.2;
	text-transform: uppercase;
	color: @grayDark;
	@media (min-width: 1281px) {
	  margin: 0 5px 0 0;
	  padding-top: 4px;
	  float: left;
	}
  }

}

/**
 * Lista de botones
 */

.share-buttons {
	margin: 0;
	display: inline-block;

	> li {
		margin: 0;
		display: inline-block;
		float: left;

		&.facebook {
			margin: -4px 5px 0 0;

		}

    &.twitter {
			margin-right: 5px;
		}

    &.googleplus {
      max-width: 80px;
    }

	}

}

/**
 * Compartir en contenidos tipo página
 */
.node-type-page {
	#compartir {
		padding-top: 0;
	}
}

/**
 * Compartir para paginas estaticas sin columna
 */

#compartir-sin-columna {
	@media (min-width: 768px) and (max-width: 979px) {
		width: auto;
		margin-left: 20px;
	}

	#compartir {
		margin: 0;
		padding: 0;
		@media (min-width: 768px) and (max-width: 979px) {
			margin: 0 0 10px 0;
		}
		@media (max-width: 767px) {
			margin: 0 0 10px 0;
		}
	}
}

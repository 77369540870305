.steps {
    display: flex;
    border-bottom: 1px solid @borderLighter;
    @media (max-width: @mobile-down) {
        display: none;
    }
}

.steps__step {
    display: inline-flex;
    padding: 0 20px @space-unit-xs;
    color: @grayLight;
    border-bottom: 5px solid transparent;
}

.steps__step--active {
    font-weight: bold;
    color: @grayDark;
    border-bottom-color: @component-active-color;
}

.steps__step--done {
    font-weight: bold;
    color: @component-active-color;
    border-bottom-color: @component-active-color;
}
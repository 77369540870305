/*doc
---
title: Caja precio
category: Componentes
---


Componente que utizamos para mostrar el precio de un producto en su ficha.

*/

.caja-precio {
	text-align: center;
    background-color: @grayDark;
    .padding-xs ();
}

.caja-precio--borde-redondeado--arriba {
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.caja-precio__precio {
    .no-margin-bottom ();
    font-size: 34px;
    font-weight: bold;
    color: #fff;
}

.caja-precio__texto {
    .no-margin-bottom ();
    padding-top: 10px;
    color: @grayLighter;
    .font-size-small ();
}
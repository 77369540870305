.modal {
	border-radius: 0;
	&.fade.in {
		@media (max-width: 480px) {
			top: 15%;
		}
	}
}

.modal-header .close {
	margin: 0;
	padding: 0;
}
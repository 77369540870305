.image-section-horizontal {
  object-fit: cover;
  width: 100vw;
  height: 50vh;
  @media (max-width: @mobile-down) {
    max-width: none;
    margin-left: -@gridGutterWidth;
    margin-right: -@gridGutterWidth;
  }
  @media (min-width: @tablet) {
    width: 50vw;
    height: 100%;
  }
}

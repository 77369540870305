.white-space-nowrap {
  white-space: nowrap !important;
}

.font-size-small {
  &,
  p {
    font-size: @smallFontSize !important;
  }
}

.font-size-0 {
  font-size: 0 !important;
}

.font-family-thin {
  font-family: @thinScout !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.text-left\@tablet {
  @media (min-width: @tablet) {
	  text-align: left !important;
  }
}
.node-type-portada-centro-estudios {
  // Título
  .page-header {
    color: @texto-centro-estudios;
    border-color: #3ca8bb;
    border-width: 1px;
    @media (min-width: 768px) {
      margin-bottom: 40px;
      padding: 0;
      position: relative;
      font-size: 44px;
      line-height: 100%;
      text-align: center;
    }
    .titulo {
      @media (min-width: 768px) {
        position: relative;
        top: .5em;
        padding: 0 .5em;
        background-color: #fff;
      }
    }
  }
  // Destacados
  .destacados {
    margin-bottom: 30px;
    .banner-normal {
      overflow: visible;
      background-color: inherit;
    }
    .antetitulo {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 10px;
      .border-radius (500px);
      .box-shadow (2px 2px 10px rgba(0,0,0,0.4));
    }
    .well {
      padding: 20px;
      background: #d0d8d5;
      border: none;
      .box-shadow (none);
      .border-radius (10px);
    }
    .field-name-field-portada-ce-destacado1 {
      margin-bottom: 30px;
      position: relative;
      @media (min-width: 768px) {
        padding-top: 20px;
      }
      &:before {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        left: 120px;
        content: "";
        background: url('../images/centro-estudios/ico.check.png') 0 0 no-repeat;
        @media (min-width: 1281px) {
          left: 140px;
        }
        @media (min-width: 768px) and (max-width: 979px) {
          left: 90px;
        }
      }
      .titulo {
        a {
          color: @texto-centro-estudios;
        }
      }
      h4 {
        font: 400 19px/19px @boldScout;
      } 
    }
  }
  // Recursos
  .recursos {
    position: relative;
    h1 {
      margin-bottom: 15px;
      font-size: 24px;
      color: #666;
      border-bottom: 1px solid @texto-centro-estudios;
      @media (min-width: 768px) {
        padding: 0;
        position: relative;
        line-height: 100%;
      }
      .titulo {
        @media (min-width: 768px) {
          position: relative;
          top: .5em;
          padding: 0 .5em 0 0;
          background-color: #fff;
        }
      }
    }
    .slides {
      margin-bottom: 30px;
      li {
        border: 1px solid @grayLighter;
        margin-right: 20px;
      }
      .banner-normal {
        padding: 7px;
        background: none;
        min-height: 430px;
        .imagen {
          height: auto;
          display: block;
          .area-imagen {
            width: auto;
            img {
              max-width: 100%;
              max-height: none;
            }
          }
        }
      }
    }
    .flex-control-nav, .flex-direction-nav {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .flex-control-nav {
      display:none;
    }
    .flex-direction-nav {
      width: 48px;
      height: 24px;
      position: absolute;
      top: 20px;
      right: 0;
      background-color: #fff;
      @media (min-width: 768px) {
        top: 12px;
      }
      a {
        background-image: url('../images/rs-minimal-black.png');
        top: 0;
        &.flex-prev {
          left: 0px;
        }
        &.flex-next {
          right: 0;
        }
      }
    } 
  }
}




@purple: #6F2462;

// .page-node-95993,
.page-node-111219,
.page-node-111222 {
  
  .page-header {
    color: @purple;
    border-bottom-color: @purple;
  }

  .subtitle2 {
    color: @purple;
  }

}

.page-node-111219 {

  .seccion {
    padding-top: @space-unit-lg;
    padding-bottom: @space-unit-sm;
    background-color: @purple;
    box-shadow: 0 0 0 100vmax @purple;
    clip-path: inset(0 -100vmax);

    .card {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: @space-unit-sm;
      border: none;
      background-color: #fff;
      
      .title3 {
        color: @purple;
      }

      .link-icon {
        color: @purple;
      }

    }

  }

}
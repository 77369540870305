/*doc
---
title: Cabecera Introducción
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div.bloque-introduccion{
	margin-bottom:20px;padding:15px 20px 5px 20px;
	background-color:@whiteDarker;}
div.bloque-introduccion p,
div.bloque-introduccion li{
	opacity:0.95;filter:alpha(opacity=95);}

// Bloque en la parte superior de la pagina
body.page-pedagogias-educacion{
	.region-banner1{
		margin-top:-20px;
		margin-bottom:20px;
		padding:15px 20px 5px 20px;
		background-color:@whiteDarker;
		div.bloque-introduccion{
			margin-bottom:0;
			padding:0;
			background-color:transparent;
		}
	}
}

.region-banner1 div.bloque-introduccion div.row{
	margin-left:-40px; margin-right:-20px;}
.region-banner1 div.bloque-introduccion div.texto{
	padding-right:20px;}
.region-banner1 div.bloque-introduccion img{
	margin-top:-15px; margin-bottom:-5px;
}
@media (min-width: 1281px) {
.region-banner1 div.bloque-introduccion div.row{
	margin-left:-50px;margin-right:-20px;}
}
@media (max-width: 767px) {
.region-banner1 div.bloque-introduccion div.row{
	margin-left:0; margin-right:0;}
.region-banner1 div.bloque-introduccion div.texto{
	padding-right:0;}
.region-banner1 div.bloque-introduccion img{
	margin:-15px -20px 15px -20px;}
}
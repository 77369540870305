/*doc
---
title: Pie de foto
category: Componentes
---

Documentación...

*/
.cuerpo-ficha--figure {
  margin: 0;
}

div.field-name-field-coleccion-pie-de-foto,
.cuerpo-ficha--figure .rsCaption {
	padding:10px 0 10px 25px;
	position: relative;
	font:400 14px/14px @lightScout;
	color:@grayLight;
	//border-bottom:1px solid @grayLighter;
	&:before{
		.spritePadded(sprite1, 1, 5, 12px, 11px);
   		left: 10px;
   		top: 10px;
	}
}

.node-type-obra .rsMinW .rsGCaption .rsCaption {
	border-bottom: none;
}
// IE7
.lt-ie8 div.field-name-field-coleccion-pie-de-foto{
	background:url(../images/ie7/bk.guion-credito.png) 0 10px no-repeat;
}

/*doc
---
title: Thumbnails Audio
category: Componentes
---

Documentación...

*/

ul#results_list {

	.audio {

		div.reproductor {
			height: 165px;
			@media (min-width: 1281px) {
				height: 203px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				height: 125px;
			}

			div.field {
				margin: 0;
			}

		}

	}

	.reproductor-sin-imagen {

		div.jp-audio {
			position: absolute;
			bottom: 0;
			left: 0;
		}

	}

}

div.list {

	ul#results_list {

		.reproductor-sin-imagen {

			div.jp-audio {
				bottom: auto;
				top: 0;
			}

			a.descarga_audio {
				bottom: auto;
				top: 50px;
			}

		}

	}
}

ul.thumbnails {

	.audio {

		div.caption {

			p.autor {
				margin-bottom: 0;
				font-family: @thinScout;
				font-size: 18px;
				line-height: 1;
				color: @grayDark;
			}

		}

	}

}
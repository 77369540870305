.price {
    .title-style;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: @fontScale2;
    background-color: @beige;
    border-radius: 100%;
}

.price--inverse {
    background-color: #fff;
}
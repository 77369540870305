/*doc
---
title: Base
category: Estilos basicos
---

Documentación...

*/

/* Lo quitamos, detectado en fallo de IE en estampa popular
html.js {
	background: @whiteDarker;
}
*/
body {
	font-size: @baseFontSize;
	line-height: @baseLineHeight;
	color: @textColor;
	background: @white;
	@media (min-width: 1281px) {
		font-size: @baseFontSize + 1;
	}
}

body#tinymce {
	width: auto;
	background: @white;
	border-top: none;
}

.accesorio{
	display: none!important;
}

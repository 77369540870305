.display-grid {
  display: grid !important;
}

.grid-template-columns-repeat-2-1fr {
  grid-template-columns: repeat(2, 1fr) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.row-gap-space-sm {
  row-gap: @space-unit-sm !important;
}

.column-gap-space-sm {
  column-gap: @space-unit-sm !important;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.col-spn-3 {
  grid-column: span 3 / span 3 !important;
}

.col-spn-4 {
  grid-column: span 4 / span 4 !important;
}

.col-spn-5 {
  grid-column: span 5 / span 5 !important;
}

.col-spn-7 {
  grid-column: span 7 / span 7 !important;
}

.col-spn-8 {
  grid-column: span 8 / span 8 !important;
}

.col-spn-9 {
  grid-column: span 9 / span 9 !important;
}

// Tablet

.order-1 {
  order: 1 !important;
}

.order-0\@tablet {
  @media (min-width: @tablet) {
    order: 0 !important;
  }
}

.display-grid\@tablet {
  @media (min-width: @tablet) {
    display: grid !important;
  }
}

.grid-template-columns-repeat-2-1fr\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.grid-cols-2\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.grid-cols-3\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.grid-cols-4\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.grid-cols-5\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
}

.grid-cols-12\@tablet {
  @media (min-width: @tablet) {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
}

.col-spn-3\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 3 / span 3 !important;
  }
}

.col-spn-4\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 4 / span 4 !important;
  }
}

.col-spn-5\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 5 / span 5 !important;
  }
}

.col-spn-7\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 7 / span 7 !important;
  }
}

.col-spn-8\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 8 / span 8 !important;
  }
}

.col-spn-9\@tablet {
  @media (min-width: @tablet) {
    grid-column: span 9 / span 9 !important;
  }
}

// Desktop

.display-grid\@desktop {
  @media (min-width: @desktop) {
    display: grid !important;
  }
}

.grid-template-columns-repeat-2-1fr\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.grid-cols-2\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.grid-cols-3\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.grid-cols-4\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.grid-cols-5\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
}

.grid-cols-12\@desktop {
  @media (min-width: @desktop) {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
}

.col-spn-3\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 3 / span 3 !important;
  }
}

.col-spn-4\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 4 / span 4 !important;
  }
}

.col-spn-5\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 5 / span 5 !important;
  }
}

.col-spn-7\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 7 / span 7 !important;
  }
}

.col-spn-8\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 8 / span 8 !important;
  }
}

.col-spn-9\@desktop {
  @media (min-width: @desktop) {
    grid-column: span 9 / span 9 !important;
  }
}

// Desktop large

.display-grid\@desktop-large {
  @media (min-width: @desktop-large) {
    display: grid !important;
  }
}

.grid-template-columns-repeat-2-1fr\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.grid-cols-2\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.grid-cols-3\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.grid-cols-4\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.grid-cols-5\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
}

.grid-cols-12\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
}

.col-spn-3\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 3 / span 3 !important;
  }
}

.col-spn-4\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 4 / span 4 !important;
  }
}

.col-spn-5\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 5 / span 5 !important;
  }
}

.col-spn-7\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 7 / span 7 !important;
  }
}

.col-spn-8\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 8 / span 8 !important;
  }
}

.col-spn-9\@desktop-large {
  @media (min-width: @desktop-large) {
    grid-column: span 9 / span 9 !important;
  }
}

/*doc
---
title: Nubel
category: Modulos
---

Estilos específicos para la landing de la Cafeteria Restaurante Nubel.

*/

@brandNubel: #3D4B5E;

/**
 * Básicos
 */

.landing-nubel {
	font-family: 'Roboto', sans-serif;
	color: #383838;

  p {
    margin-bottom: 30px;
    font-family: inherit;
  }

  .btn {
    padding: 6px 15px 7px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 0;
  }

}


/**
 * Deepbanner
 */

.landing-nubel__deepbanner {
	margin-bottom: 30px;
	background: url('../images/nubel/nubel-deepbanner.jpg') no-repeat center center;
  background-size: cover;
	@media (max-width: 767px){
		margin-left: -20px;
    margin-right: -20px;
		padding: 60px 20px;
	}
  @media (min-width: 768px){
    display: table;
    min-height: 400px;
    height: 400px;
    margin-bottom: 80px;
  }
}

.landing-nubel__deepbanner__header {
  text-align: center;
  @media (min-width: 768px){
    display: table-cell;
    vertical-align: middle;
  }

  > :last-child {
    margin-bottom: 0;
  }

}

.landing-nubel__deepbanner__title {
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px 15px;
	font-family: 'Raleway', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.7);
	@media (max-width: 767px){
		font-size: 20px;
	}
}

.landing-nubel__deepbanner__subtitle {
  display: inline-block;
  padding: 3px 5px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  background-color: rgba(255, 255, 255, 0.7);
  @media (min-width: 768px){
    font-size: 21px;
  }
}

/**
 * Intro
 */

.landing-nubel__intro {
  .margin-bottom-md ();
  @media (min-width: 768px) and (max-width: 979px){
    margin-bottom: 60px;
  }
}

/**
 * Reservas
 */

.landing-nubel__reservas {
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
  padding: 15px;
  border: 10px solid @brandNubel;
  @media (min-width: 980px) {
    margin-bottom: 150px;
  }
}

.landing-nubel__reservas__title {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: @brandNubel;
  text-transform: uppercase;
}

.landing-nubel__reservas__phone {
  display: inline-block;
  padding: 8px 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  font-weight: 700;
  background-color: @brandNubel;

  &,
  &:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

}

.landing-nubel__wrapper__widget {
  min-height: 135px;
  position: relative;
  margin-bottom: 60px;
  @media (min-width: 980px){
    margin-bottom: 150px;
    min-height: 200px;

  }
  @media (min-width: 1281px){
    margin-left: 60px;
    margin-right: 30px;
  }
}

.landing-nubel__widget {
  box-shadow: 0 2px 50px 0 rgba(0,0,0,0.24);

  @media (min-width: 980px){
    width: 320px;
    position: absolute;
    top: -190px;
    right: -10px;
  }

}

/**
 * Título sección
 */

.landing-nubel__titulo-seccion {
  margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  &:after {
    width: 48px;
    height: 2px;
    margin-top: 10px;
    content: "";
    display: block;
    background-color: #FB9A80;
  }
}

/**
 * Mapa
 */

.landing-nubel__mapa {
  background: url('../images/nubel/cafeteria-nubel-4.jpg') top center no-repeat;
  @media (max-width: 767px){
    padding-top: 320px;
    border: 1px solid #e6e6e6;
  }
  @media (min-width: 768px){
    min-height: 320px;
  }
}

.landing-nubel__mapa__texto {
  padding: 25px 20px;
  color: #fff;
  background-color: @brandNubel;
  @media (min-width: 768px){
    margin: 30px;
  }
  @media (min-width: 1281px){
    min-width: 500px;
    float: right;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .landing-nubel__titulo-seccion {
    color: #fff;

    &:after {
      background-color: #9EA5AE;
    }

  }

}

.landing-nubel__mapa__mapa-museo {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

/**
 * Cartas
 */

.landing-nubel__cartas {
  @media (max-width: 767px){
    margin-left: -20px;
    margin-right: -20px;
  }
  @media (min-width: 768px){
    display: table;
    background-color: #e6e6e6;
    border-spacing: 1px;
    table-layout: fixed;
  }

  .landing-nubel__titulo-seccion {
    padding: 0 20px;
    @media (min-width: 768px){
      padding: 30px;
    }
  }

}
.landing-nubel__cartas__row {
  @media (min-width: 768px){
    display: table-row;
    border-spacing: 1px;
  }
}

.landing-nubel__cartas__col {
  @media (min-width: 768px){
    display: table-cell;
    width: 33%;
    vertical-align: top;
    background-color: #fff;
  }
}

.landing-nubel__carta {
  padding: 20px;

  p {
    margin-bottom: 15px;
  }

}

.landing-nubel__carta__cabecera,
.landing-nubel__carta__titulo {
  overflow:hidden;
  _overflow:visible;
  zoom:1;
}

.landing-nubel__carta__subtitulo {
  margin-left: 10px;
  float: right;
  color: #888;
}

.landing-nubel__carta__titulo h3 {
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}



@deepbanner-item-image-height: 200px;
@deepbanner-item-image-height-tablet: 375px;

.deepbanner {
    margin-bottom: @space-unit-md;
    overflow: hidden;
    @media (max-width: @mobile-down) {
        margin-left: -20px;
        margin-right: -20px;
    }
    @media (min-width: @tablet) {
        margin-bottom: @space-unit-lg;
        background-color: @whiteDarker;
    }

    .rsOverflow {
        @media (min-width: @tablet) {
            margin-top: @space-unit-sm;
            margin-bottom: @space-unit-sm;
        }
        @media (min-width: @desktop) {
            overflow: visible;
        }
    }
}

.deepbanner__container {
    @media (min-width: @tablet) {
        position: relative;
    }
}


.deepbanner__item {
    color: inherit;
    @media (min-width: @tablet) {
        display: flex;
    }
    &:hover {
        text-decoration: none;
        
        .deepbanner__item__caption {
            color: @component-active-color;
        }

    }
}

.deepbanner__item__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: @mobile-down) {
        height: @deepbanner-item-image-height;
    }
    @media (min-width: @tablet) {
        order: 1;
        flex-basis: 68.5%;
        min-height: @deepbanner-item-image-height-tablet;
    }
}

.deepbanner__item__caption {
    padding: 20px 20px 15px;
    transition: @transition-base;
    @media (min-width: @tablet) {
        display: flex;
        align-items: center;
        flex-basis: 31.5%;
        padding: @space-unit-sm;
        background-color: #fff;
    }
    > :last-child {
        margin-bottom: 0;
    }
}

.deepbanner__item__tag {
    margin-bottom: @space-unit-xxs;
}

.deepbanner__item__title {
    margin-bottom: @space-unit-xxxs;
    font-size: 34px;
    line-height: @headingsLineHeight;
    @media (min-width: @tablet) {
        font-size: 40px;
    }
}

.deepbanner__item__subtitle {
    font-size: 24px;
    line-height: @headingsLineHeight;
    @media (min-width: @tablet) {
        font-size: 26px;
    }
    @media (min-width: @desktop) {
        font-size: 29px;
    }
}

.deepbanner__item__intro {
    margin-bottom: @space-unit-xxs;
    > :last-child {
        margin-bottom: 0;
    }
}

.deepbanner__item--repensar-guernica,
.deepbanner__item--itinerancias,
.deepbanner__item--gigapixel {
    &:hover {
        .deepbanner__item__caption {
            color: @grayDark;
        }
    }
    .deepbanner__item__tag {
        background-color: @grayDark;
    }
}

.deepbanner__item--repensar-guernica {
    &:hover {
        .deepbanner__item__caption {
            background-color: @repensar-guernica-dark;
        }
        .deepbanner__item__tag {
            color: @repensar-guernica-dark;
        }
    }
    .deepbanner__item__caption {
        background-color: @repensar-guernica;
    }
    .deepbanner__item__tag {
        color: @repensar-guernica;
    }
}

.deepbanner__item--itinerancias {
    &:hover {
        .deepbanner__item__caption {
            background-color: @itinerancias-dark;
        }
        .deepbanner__item__tag {
            color: @itinerancias-dark;
        }
    }
    .deepbanner__item__caption {
        background-color: @itinerancias;
    }
    .deepbanner__item__tag {
        color: @itinerancias;
    }
}

.deepbanner__item--gigapixel {
    &:hover {
        .deepbanner__item__caption {
            background-color: @gigapixel-dark;
        }
        .deepbanner__item__tag {
            color: @gigapixel-dark;
        }
    }
    .deepbanner__item__caption {
        background-color: @gigapixel;
    }
    .deepbanner__item__tag {
        color: @gigapixel;
    }
}


/***************
*
*  2. Bullets
*
****************/

.deepbanner {
    .rsBullets {
        display: flex;
        @media (max-width: @mobile-down) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media (min-width: @tablet) {
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        *display:inline;
        *zoom:1;
        margin-left: @space-unit-xxs;
        margin-right: @space-unit-xxs;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .rsBullet span {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: @borderLighter;
        @media (min-width: @tablet) {
            background-color: rgba(255, 255, 255, 0.5);
        }

    }

    .rsBullet.rsNavSelected span {
        background-color: @grayDark;
        @media (min-width: @tablet) {
            background-color: #fff;
        }
    }
}


/***************
*
*  1. Arrows
*
****************/

.deepbanner {
    .rsArrow {
        display: flex;
        position: absolute;
        @media (max-width: @mobile-down) {
            display: none !important;
        }
    }

    .rsArrowLeft {
        right: 48px;
        bottom: -2px;
    }

    .rsArrowRight {
        right: -2px;
        bottom: -2px;
    }

    .rsArrowIcn {
        width: 48px;
        height: 48px;
        top: 50%;
        left: 50%;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-color: @grayDarker;
        border: 2px solid @whiteDarker;
    }
    .rsArrowIcn:hover {
        background-color: rgba(0,0,0,0.9);
    }
    .rsArrowLeft .rsArrowIcn {
        background-image: url("../images/iconos/arrow-left.svg");
    }
    .rsArrowRight .rsArrowIcn {
        background-image: url("../images/iconos/arrow-right.svg");
    }
}

.embed-reader {
    width: 100%;
    display: grid;
    @media (min-width: @desktop) {
        grid-template-columns: 2fr 1fr;
    }
    @media (min-width: @desktop-large) {
        grid-template-columns: 3fr 1fr;
    }
}

.embed-reader--without-index {
    @media (min-width: @desktop) {
        grid-template-columns: 1fr;
    }
}

.embed-reader__embed {
    position: relative;
}

.embed-reader__sidebar {
    display: none;
    padding: @space-unit-xxl @space-unit-sm;
    overflow-y: auto;
    background-color: #fff;
    @media (min-width: @desktop) {
        display: block;
    }
}

.embed-reader__sidebar-title {
    .title-style;
    .font-size-title3;
    margin-bottom: @space-unit-xsm;
}

.embed-reader__nav-item {
    display: block;
    
    &,
    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        .embed-reader__nav-title,
        .embed-reader__nav-subtitle {
            color: @linkColor;
        }
    }

    &:not(:last-child) {
        margin-bottom: @space-unit-xsm;
    }
}

.embed-reader__nav-title {
    display: block;
    font-size: @smallFontSize;
    line-height: @smallLineHeight;
    font-weight: bold;
    color: @grayDark;
    transition: .2s;
}

.embed-reader__nav-subtitle {
    display: block;
    font-size: @smallFontSize;
    line-height: @smallLineHeight;
    transition: .2s;
}
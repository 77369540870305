/*doc
---
title: Mostrar más
category: Componentes
---

Documentación...

*/

.show_more{
	margin:0 0 15px 0;
	padding:0 10px 0 0;
	display: inline-block;
	font:700 12px/18px @sansFontFamily;
	background:url(../images/red_arrow_down.png) center right no-repeat;
	&:hover{
		cursor:pointer;
		text-decoration:underline;
	}
}
.show_more.active{
	background:url(../images/red_arrow_up.png) center right no-repeat;
}

p .show_more,
li .show_more,
dt .show_more{
	margin:0;
}

span.w-dropdown{
	display:block;
}

@media (min-width: 1281px) {
	.show_more{
		font:700 12px/21px @sansFontFamily;
	}
}
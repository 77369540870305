.patrocinio-museo {
  display: inline-flex;
  align-items: center;
}

.patrocinio-museo__logo {
	display: inline-block;
}

.patrocinio-museo--ministerio {

	.patrocinio-museo__logo {
		.spriteReplace (sprite2, 0, 2, 193px, 60px);
	}

}

.patrocinio-museo--cultura {

  .patrocinio-museo__logo {
		.spriteReplace (sprite1, 0, 12, 70px, 60px);
	}

}

.patrocinio-museo--transparencia {

  .patrocinio-museo__logo {
		.spriteReplace (sprite2, 4, 2, 192px, 60px);
	}

}

// .lt-ie9 .patrocinio-museo--transparencia .patrocinio-museo__logo { .sprite-svg-ie8 (sprite2); }

.patrocinio-museo--google-art-project {

  .patrocinio-museo__logo {
		.spriteReplace (sprite1, 3, 12, 136px, 60px);
	}

}

.patrocinio-museo--telefonica {

  .patrocinio-museo__logo {
		.spriteReplace (sprite2, 0, 4, 110px, 30px);
	}

}

// .lt-ie9 .patrocinio-museo--telefonica .patrocinio-museo__logo { .sprite-svg-ie8 (sprite2); }

/*doc
---
title: Ficha Visita guiada
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.node-type-visita-guiada {
	div#main {
		margin-bottom: 0;
	}

	.datos-informativos--secundarios {
		@media (max-width: 767px) {
			.margin-bottom-sm ();
		}

		li {
			margin-bottom: 10px;
			font-size: @smallFontSize;
			line-height: 1.4;

			p {
				font-size: @smallFontSize;
				line-height: 1.4;
			}

			p.actividad--enlace-programa {
				.margen-extra-pequeno ();
			}
		}

	}
}
/*doc
---
title: Fondos
category: Estilos basicos
---

Documentación...

*/

.fondo--blanco,
.fondo .fondo--blanco {
    .fondo-variante-color (
		@texto:                @textColor, 
		@fondo:                #fff, 
		@texto-destacado:      @textColorDark, 
		@texto-suavizado:      #777,
		@enlace:               @linkColor, 
		@enlace-hover:         @linkColorHover,
		@etiqueta-color:       @etiquetaColor,
		@etiqueta-fondo:       @etiquetaBackgroundColor,
		@etiqueta-color-hover: @etiquetaColor,
		@etiqueta-fondo-hover: darken(@etiquetaBackgroundColor, 10%)		 
	);
}

.fondo--claro,
.fondo .fondo--claro {
    .fondo-variante-color (
		@texto:                @textColor, 
		@fondo:                @whiteDarker, 
		@texto-destacado:      @textColorDark, 
		@texto-suavizado:      #777,
		@enlace:               @linkColor, 
		@enlace-hover:         @linkColorHover,
		@etiqueta-color:       @etiquetaColor,
		@etiqueta-fondo:       @etiquetaBackgroundColor,
		@etiqueta-color-hover: @etiquetaColor,
		@etiqueta-fondo-hover: darken(@etiquetaBackgroundColor, 10%)
	);
}

.fondo--oscuro,
.fondo .fondo--oscuro {
	.fondo-variante-color (
		@texto:                @textColorInverse, 
		@fondo:                @grayDark, 
		@texto-destacado:      #fff, 
		@texto-suavizado:      #777,
		@enlace:               #ccc, 
		@enlace-hover:         #fff,
		@etiqueta-color:       @etiquetaColor,
		@etiqueta-fondo:       @etiquetaBackgroundColor,
		@etiqueta-color-hover: @etiquetaColor,
		@etiqueta-fondo-hover: darken(@etiquetaBackgroundColor, 10%)
	);
}

/**
 * Fondo ancho completo
 */

.fondo--ancho-completo {
	position: relative;
	overflow-x: hidden;

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		width: 9600px;
		right: 100%;
	}
	
	&:after { 
		width: 9600px;
		left: 100%;
	}

	&.fondo--claro {
		&:before,
		&:after {
			background: @whiteDarker;
		}
	}

}
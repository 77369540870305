@artwork-padding-tablet: @space-unit-md;

.artwork {
  @media (max-width: @mobile-down) {
    margin-bottom: @space-unit-sm;
  }
  @media (min-width: @tablet) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: @space-unit-md;
    padding: @artwork-padding-tablet;
    border-top: 1px solid @borderLighter;
  }
  @media (min-width: @desktop) {
    grid-template-columns: 3fr 1fr;
  }
}

.artwork__image {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  @media (max-width: @mobile-down) {
    padding: @gridGutterWidth 0;
  }

  .artwork__image-link {
    position: sticky;
    top: @artwork-padding-tablet;
  }

  img {
    height: auto;
    max-width: 100%;
    max-height: 20rem; // 320px
    @media (min-width: @tablet) {
      max-height: @img-full-screen-max-height;
      
    }

  }

}

.artwork__data {
  @media (min-width: @tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 20rem; // 320px
  }
}

.artwork__data-breadcrumbs {
  margin-bottom: @space-unit-xxs;
  @media (min-width: @tablet) {
    margin-bottom: @space-unit-xs;
  }
}

.artwork__data-title {
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(:last-child) {
    margin-bottom: @space-unit-xxs;
    @media (min-width: @tablet) {
      margin-bottom: @space-unit-xs;
    }
  }
}

.artwork__data-subtitle {
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
    @media (min-width: @tablet) {
      margin-bottom: @space-unit-sm;
    }
  }
}

.artwork__data-subtitle-item {
  &:not(:last-child) {
    margin-bottom: @space-unit-xs;
  }
}

.artwork__data-subtitle-primary {
  margin-bottom: 0;
}

.artwork__data-subtitle-link {
  color: @linkColor;
  transition: .2s;
  &:hover,
  &:focus {
    color: @linkColorHover;
    text-decoration: none;
  }
}

.artwork__data-list {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  &:not(:last-child) {
    margin-bottom: @space-unit-xsm;
    @media (min-width: @tablet) {
      margin-bottom: @space-unit-sm;
    }
  }

  li {
    font-size: @smallFontSize;
    color: @grayDarker;
    &:not(:last-child) {
      margin-bottom: @space-unit-xxxs;
    }
  
    div {
      display: inline !important;
      float: none !important;
    }
  
    .field-label {
      font-weight: normal;
      color: @gray;
    }
  
  }

}

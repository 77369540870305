/*doc
---
title: Pie
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

.pie {
	position: relative;
	z-index: 10;
	font-size: @baseFontSize - 1;
	@media (min-width: 1281px) {
		font-size: @baseFontSize;
	}
	@media (max-width: 767px) {
		margin-left: -20px;
		margin-right: -20px;
	}

	a {
		color: #ccc;
		&:hover {
			color: #fff;
		}
	}
}

.pie__seccion {
	@media (max-width: 767px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.pie__seccion--superior {
	.padding-top-xs ();
}

.pie__seccion--medio {
	padding-top: 25px;
	.btn {
		margin-bottom: 10px;
		font-size: 12px;
		text-shadow: none;
		white-space: nowrap;
		@media (min-width: 1281px) {
			font-size: 13px;
		}
	}
	.btn-default {
		color: #2a2828;
		background-color: #fff;
		border-color: #fff;
		&:hover {
			color: #fff;
			background-color: #2a2828;
			border-color: #2a2828;
		}
	}
	.btn-inverse {
		color: #fff;
		background-color: #2a2828;
		border-color: #2a2828;
		&:hover {
			color: #2a2828;
			background-color: #fff;
			border-color: #fff;
			.icon-white {
				background-image: url("@{iconSpritePath}");
			}
		}
	}
}

.pie__seccion--inferior {
	.padding-top-sm ();
	.padding-bottom-sm ();
	background-color: #2a2828;
}

.proyectos-museo {
	border-bottom: 1px solid darken(@grayDark, 3%);
	// Cambio el tamaño de las columnas para las tablets verticales
	.span2 {
		@media (min-width: 768px) and (max-width: 979px) {
			width: 228px;
		}
	}
}

.proyectos-museo__logo {
	margin-bottom: 6px;
	display: block;
	.transition (opacity .2s ease-in-out);
	@media (max-width: 767px) {
		margin-bottom: 3px;
	}
	&:hover {
		.opacity (70);
	}
}

.proyectos-museo__logo--radio-reina-sofia {
	.spriteReplace (sprite1, 0, 0, 140px, 28px);
}

.proyectos-museo__logo--carta {
	.spriteReplace (sprite1, 0, 2, 123px, 28px);
}

.proyectos-museo__logo--desacuerdos {
	.spriteReplace (sprite1, 0, 1, 140px, 28px);
}

.proyectos-museo__logo--multimedia {
	.spriteReplace (sprite2, 3, 1, 140px, 28px);
}

.lt-ie9 .proyectos-museo__logo--multimedia { .sprite-svg-ie8 (sprite2); }

.proyectos-museo__logo--museo-en-red {
	.spriteReplace (sprite2, 6, 1, 140px, 28px);
}

.lt-ie9 .proyectos-museo__logo--museo-en-red { .sprite-svg-ie8 (sprite2); }

.proyectos-museo__logo--linternationale {
	.spriteReplace (sprite2, 0, 1, 140px, 28px);
}

.lt-ie9 .proyectos-museo__logo--linternationale { .sprite-svg-ie8 (sprite2); }

.proyectos-museo__logo__pie {
	font-size: @smallFontSize - 1;
	line-height: 1.3;
	@media (max-width: 767px) {
		margin-bottom: 20px;
	}
}

.pie .block-title,
.pie__titulo {
	font-family: @regularScout;
	font-size: 20px;
	color: #fff;
	a {
		color: inherit;
	}
}

.pie__menu {
	.nav {
		.margin-bottom-xs ();
		@media (min-width: 768px) and (max-width: 979px) {
			.margin-bottom-sm ();
		}
		> li {
			margin-bottom: 5px;
			> a {
				&:hover {
					text-decoration: underline;
					background-color: transparent;
				}
			}
		}
	}
}

.pie__menu--dos-columnas {
	.nav {
		margin-left: -10px;
		margin-right: -10px;
		.clearfix ();
		@media (min-width: 1281px) {
			margin-left: -15px;
			margin-right: -15px;
		}
		@media (max-width: 767px) {
			margin-left: 0;
			margin-right: 0;
		}
		> li {
			width: 50%;
			float: left;
			padding-left: 10px;
			padding-right: 10px;
			.box-sizing(border-box);
			@media (min-width: 1281px) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				width: 33.33333333%;
			}
			@media (max-width: 767px) {
				width: 100%;
				float: none;
			}
			&:nth-child(2n+1) {
				clear: left;
				@media (max-width: 979px) {
					clear: none;
				}
			}
			&:nth-child(3n+1) {
				@media (min-width: 768px) and (max-width: 979px) {
					clear: left;
				}
			}
		}
	}
}

.suscripcion-newsletter {
	.margin-bottom-sm ();
}

.enlaces-redes-sociales {
	.no-margin-left ();
  .clearfix ();
  display: flex;
  align-items: center;
  margin-bottom: 0;
	list-style: none;

	> li {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

	}

}

.enlaces-redes-sociales__icono {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	display: table-cell;
	vertical-align: middle;
	.opacity (80);
	.transition (opacity .2s ease-in-out);

	&:hover {
		.opacity (100);
	}

}

.enlaces-redes-sociales__icono--facebook {
	background-color: @facebook;
}

.enlaces-redes-sociales__icono--twitter {
	background-color: @twitter;
}

.enlaces-redes-sociales__icono--instagram {
	background-color: @instagram;
}

.enlaces-redes-sociales__icono--vimeo {
	background-color: @vimeo;
}

.enlaces-redes-sociales__icono--you-tube {
	background-color: @youtube;
}

.enlaces-redes-sociales__icono--google-plus {
	background-color: @google-plus;
}

.enlaces-redes-sociales__icono--rss {
	background-color: @rss;
}

.enlaces-redes-sociales__icono__imagen {
	margin: 0 auto;
}

.enlaces-redes-sociales__icono--facebook__imagen {
	.spriteReplace (sprite2, 0, 0, 13px, 24px);
}

.enlaces-redes-sociales__icono--twitter__imagen {
	.spriteReplace (sprite2, 1, 0, 25px, 20px);
}

.enlaces-redes-sociales__icono--instagram__imagen {
	.spriteReplace (sprite2, 2, 0, 24px, 24px);
}

.enlaces-redes-sociales__icono--vimeo__imagen {
	.spriteReplace (sprite2, 3, 0, 24px, 20px);
}

.enlaces-redes-sociales__icono--you-tube__imagen {
	.spriteReplace (sprite2, 4, 0, 22px, 24px);
}

.enlaces-redes-sociales__icono--google-plus__imagen {
	.spriteReplace (sprite2, 5, 0, 24px, 21px);
}

.enlaces-redes-sociales__icono--rss__imagen {
	.spriteReplace (sprite2, 6, 0, 20px, 20px);
}

// Para ie8 cambiamos la imagen de fondo .svg por .png

.lt-ie9 .enlaces-redes-sociales__icono__imagen { .sprite-svg-ie8 (sprite2); }

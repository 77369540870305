.menu-splash {
    display: flex;
    height: 100%;
    overflow-y: auto;
    padding: @space-unit-xxs 20px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @grayDarker;
    opacity: 0;
    pointer-events: none;
    z-index: @zindexMenuSplash;
    transition: @transition-base;
    @media (min-width: @tablet) {
        padding-top: 63px;
    }
}
  
.menu-splash--active {
    opacity: 1;
    pointer-events: auto;
}

.menu-splash__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: @space-unit-sm;
    @media (max-width: @mobile-down) {
        align-items: center;
    }
}

.menu-splash__nav {
    margin-left: 10px;
    margin-right: 10px;
}

.menu-splash__nav--inline {
    .menu-splash__nav__item {
        display: inline-block;
        margin-bottom: 0;
        margin-right: @space-unit-xxs;
    }
}

.menu-splash__nav__item {
    display: block;
    margin-bottom: 10px;
    .title-style(@regularScout);
    font-size: 16px;
    @media (min-width: @tablet) {
        font-size: 18px;
    }
    &,
    &:hover,
    &:focus {
        color: #fff;
    }
}

.menu-splash__nav__item--active {
    text-decoration: underline;
}

.menu-splash__nav__item--no-active {
    color: gray;
    text-decoration: line-through;
}

.menu-splash__search-button {
    border: 0;
    padding: 0;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    &:focus {
        outline: 0;
    }
}


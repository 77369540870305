/*doc
---
title: Portada Multimedia
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Destacado

div#multimedia-destacado {
	margin: 0 0 20px 0;
	position: relative;
	@media (min-width: 1281px) {
		margin-bottom: 30px;
	}

	ul.thumbnails,
	ul.thumbnails > li {
		margin: 0;
	}

	ul.thumbnails {

		.thumbnail {
			background: none;

			.caption {
				width: 460px;
				padding: 0 0 40px 20px;
				float: left;
				font-size: 13px;
				line-height: 18px;
				color: @grayDark;
				@media (min-width: 1281px) {
					width: 570px;
					padding: 0 0 40px 30px;
					font-size: 14px;
					line-height: 21px;
				}
				@media (min-width: 768px) and (max-width: 979px) {
					width: 352px;
				}
				@media (max-width: 767px) {
					width: auto;
					padding: 10px 0 0 0;
					float: none;
				}

				div.field-name-field-video-fecha-libre {
					font-family: @thinScout;
					font-size: 22px;
					line-height: 1;
				}

				div.field-name-field-audio-fecha-libre,
				div.field-name-field-radio-fecha-libre {
					font-family: @thinScout;
					font-size: 22px;
					line-height: 1;
				}

				div.subtitulo {
					font-family: @regularScout;
					font-size: 16px;
					line-height: 1;
				}

			}

			h3.titulo {
				font-size: 26px;
				@media (min-width: 1281px) {
					font-size: 34px;
				}
			}

			h4 {
				font-size: 26px;
				@media (min-width: 1281px) {
					font-size: 34px;
				}
			}

		}

	}

	// Compartir

	div#compartir {
		padding: 10px 0 0 0;
	}

	// Destacado VIDEO

	div.field-name-field-video-video {
		width: 460px;
		height: 259px;
		float: left;
		@media (min-width: 1281px) {
			width: 570px;
			height: 321px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			width: 352px;
			height: 198px;
		}
		@media (max-width: 767px) {
			width: auto;
			height: auto;
			float: none;
		}
	}

	// Destacado AUDIO y RADIO

	div.jp-audio {
		margin-bottom: 0;
	}

	div.reproductor {
		width: 352px;
		height: auto;
		@media (min-width: 1281px) {
			width: 460px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			width: 290px;
		}
		@media (max-width: 767px) {
			width: 100%;
			height: auto;
			float: none;
		}

		div.imagen {
			width: auto;
			height: auto;
			display: block;
		}

		div.imagen img {
			max-width: 100%;
			max-height: 100%;
		}

	}

	.reproductor-con-imagen {

		div.reproductor {
			float: left;
		}

	}

	.reproductor-sin-imagen {

		div.reproductor {
			height: auto;
			float: right;
		}

		div.caption {
			padding: 0 20px 40px 0;
			@media (min-width: 1281px) {
				padding: 0 30px 40px 0;
			}
		}

	}

}
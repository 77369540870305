/*doc
---
title: Portada Visitas guiadas
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

div#visitas-guiadas-portada > div.view-visitas-guiadas > div.view-content{
	margin-bottom:20px;}
div.view-visitas-guiadas{
	ul.thumbnails > li{
		margin-bottom:10px;padding-bottom:10px;border-bottom:1px solid @grayLighter;
	}
	ul.thumbnails{
		span.imagen{
			height:140px;
		}
		span.area-imagen{
			width:140px;
			img{
				max-width:140px;max-height:140px;
			}
		}
		div.caption{
			width:460px;padding-left:20px;display:table-cell;
		}
	}
	.btn{
		margin-bottom:10px;
	}
}
@media (min-width: 1281px) {
div.view-visitas-guiadas{
	ul.thumbnails{
		span.imagen{
			height:170px;
		}
		span.area-imagen{
			width:170px;
			img{
				max-width:170px;max-height:170px;
			}
		}
		div.caption{
			width:570px;padding-left:30px;
		}
	}
}
}
@media (min-width: 768px) and (max-width: 979px) {
div.view-visitas-guiadas{
	ul.thumbnails{
		span.imagen{
			height:104px;
		}
		span.area-imagen{
			width:104px;
			img{
				max-width:104px;max-height:104px;
			}
		}
		div.caption{
			width:352px;
		}
	}
}
}
@media (max-width: 767px) {
div.view-visitas-guiadas{
	ul.thumbnails > li{
		margin-bottom:0;padding-bottom:0;border-bottom:none;
	}
	ul.thumbnails{
		div.caption{
			width:auto;padding:10px 0;display:block;
		}
	}
}
}
/*doc
---
title: Enlaces
category: Estilos basicos
---

Diferentes tipos de enlace.

```html_example
<p><a href="#">Enlace por defecto</a></p>
<p><a href="#" class="enlace--subrayado">Enlace subrayado</a></p>
<p><a href="tel: 948072222" class="enlace--telefono">Enlace para números de teléfono</a></p>
<a href="#" class="enlace--bloque">
	<p>Enlace para envolver bloques</p>
</a>
```
*/

.link-undeline {
	color: inherit;
	text-decoration: underline;
	&:hover {
		color: @textColorDark;
	}
}

.enlace--telefono {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		cursor: text;
	}
}

.enlace--bloque {
	color: inherit;
	.transition (color .2s ease-in-out);
	&:hover {
		text-decoration: none;
		color: @component-active-color;
		img {
			.opacity (75);
		}
	}

	img {
		.transition (all .2s ease-in-out);
	}
	
}

.enlace--ver-mapa {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	padding-top: 10px;
	&:before {
		.spritePadded (sprite2, 7, 0, 40px, 40px);
		left: -10px;
		top: 0;
	}
}

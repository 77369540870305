/*doc
---
title: Ficha Colección
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Titulo

body.node-type-coleccion {

	.page-header {
		margin: 0;
		padding: 10px 20px 15px 20px;
		background: @grayDark;
		border-bottom: none;

		span {
			display: block;
			color: @white;
			&.nombre {
				margin-bottom: 5px;
				font: 400 20px/20px @regularScout;
			}
			&.lema {
				margin-bottom: 5px;
			}
			&.fecha {
				font: 400 20px/20px @regularScout;
			}
		}

	}

}

// Descripcion Coleccion

div#coleccion-descripcion {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid @grayLighter;
	@media (max-width: 767px) {
		padding-bottom: 0;
		border-bottom: none;
	}
}

div.field-name-field-coleccion-descripcion {
	@media (max-width: 767px) {
		padding-bottom: 20px;
	}
}

// Foto coleccion

div#foto-coleccion {
	margin-bottom: 20px;
}

// Listado de salas agrupadas por planta

div.sala {
	margin-bottom: 20px;
	@media (max-width: 767px) {
		margin-bottom: 40px;
	}
}

div.listado-salas-planta {
	margin-bottom: 20px;

	ul {
		margin: 0;
		border-top: 1px solid @grayLighter;

		li {
			padding-left: 95px;
			position: relative;
			font: 400 13px/18px @sansFontFamily;
			list-style-type: none;
			border-bottom: 1px solid @grayLighter;
			&:hover {
				background-color: @grayHover;
			}

			a {
				margin-right: 5px;
				padding: 5px 20px 5px 5px;
				display: block;
				color: @gray;
				background: url(../images/ico.flecha.ir.png) right center no-repeat;
				&:hover {
					text-decoration: none;
					background-color: @grayHover;
				}
			}

			span.nombre_sala {
				width: 75px;
				padding: 0 5px;
				position: absolute;
				top: 5px;
				left: 0;
				display: inline-block;
				font-size: 11px;
				text-transform: uppercase;
				background: @grayDark;
				color: @white;
			}

		}

	}

}

.icon-map-marker {
	width: 16px;
	height: 16px;
	background: url(../images/ico.map.png) 0 0 no-repeat;
}

// Ampliar plano

div.plano {
	min-height: 230px;
	margin-bottom: 20px;
	position: relative;
	border: 10px solid @whiteDarker;
	background-color: @white;
}

div.ampliar-plano {
	position: absolute;
	bottom: 10px;
	left: 10px;
	@media (max-width: 767px) {
		display: none;
	}
}

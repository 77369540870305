/*doc
---
title: Portada Museo
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Informacion Museo
div#informacion-museo ul.lista-accesos{
	border-top:4px solid @grayDark;}
div#informacion-museo ul.lista-accesos li a{
	font:400 19px/19px @boldScout;
	color:@grayDark;}

// Mision Historia Asociacion
div#mision-historia-asociacion-museo{
	margin-bottom:20px;
	border-bottom:1px solid @grayLighter;}
@media (min-width: 1281px) {
div#mision-historia-asociacion-museo{
	}
}

#sedes-museo .thumbnails li {float:none;}
div#mision-historia-asociacion-museo div.row{
	margin-bottom:0;}

div#carta-servicios-museo{
	margin-bottom:20px;
	border-top:4px solid @grayDark;}
div#carta-servicios-museo h3.titulo-bloque{
	border-top:none;}
div#carta-servicios-museo ul{
	margin:0;
	list-style:none;}
div#carta-servicios-museo ul li{
	list-style-type:none;
	border-bottom:1px solid @grayLighter;}
div#carta-servicios-museo ul li:hover{
	background-color:@grayHover;}
div#carta-servicios-museo ul li a{
	margin-right:5px;padding:5px 20px 5px 5px;display:block;
	font:400 13px/18px @sansFontFamily;
	color:@gray;background:url(../images/ico.flecha.ir.png) right center no-repeat;}
div#carta-servicios-museo ul li a:hover{
	text-decoration:none;}
@media (min-width: 1281px) {
div#carta-servicios-museo{
	margin-bottom:30px;}
}
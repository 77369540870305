/*doc
---
title: Portada Restauracion Proyectos
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

body.page-coleccion-restauracion-proyectos .form-buscar{
	margin-bottom:20px;}
div#restauracion-proyectos-listado ul.thumbnails{width:100%;margin:0 0 20px 0;overflow:hidden;}
div#restauracion-proyectos-listado ul.thumbnails li{list-style:none;}
div#restauracion-proyectos-listado ul.thumbnails li.limpiar{ margin-left:0; clear:both;}
div#restauracion-proyectos-listado ul.thumbnails li.limpiar .span3{margin-left:0;}
div#restauracion-proyectos-listado{
	border-bottom:none;}
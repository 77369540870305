/*doc
---
title: Relacionados
category: Componentes
---

Documentación...

*/

div.faldon-relacionados{
	position:relative; z-index:100;
	background:@white; }
@media (max-width: 767px) {
div.faldon-relacionados .thumbnails{
	border-bottom:none;}
div.faldon-relacionados .thumbnails > li{
	margin-bottom:10px;padding:0;
	border-top:none;}
}
.node-nota-prensa div.faldon-relacionados{
	padding-top: 30px;
}
/*doc
---
title: Navegacion con filtros y paginado
category: Componentes
---

Este componente incluye a su vez el componente de Bootstrap para paginaciones.

*/

.navegacion-filtros-paginado {
	.margin-bottom-sm ();
	.pagination {
		.no-margin-bottom ();
	}
}
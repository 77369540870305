/*doc
---
title: Botonera
category: Componentes
---


Componente que utizamos para añadir una serie de acciones. Consta de botones y textos de ayuda.

*/

.botonera {
    .margin-bottom-sm ();
}

.botonera__accion__boton {
    margin-bottom: 5px;
}

.botonera__accion__texto {
    .no-margin-bottom ();
    font-weight: bold;
}

.botonera__accion--entrada-gratuita {
    min-height: 45px;
    padding: 16px 0 0 60px;
    background: url('../images/iconos/visita/ico.entrada.png') 0 0 no-repeat;
}

.botonera__accion__texto-ayuda {
    font-size: @smallFontSize;
    color: @gray;
}
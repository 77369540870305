/*doc
---
title: Tipografía
category: Estilos basicos
---

Para asignar a un elemento un tamaño de fuente que cambie en función de los tamaños de pantalla definidos por Bootstrap utilizaremos los mixins del tipo ["Tamaños de fuente"](/docs/mixins.html#Tamaños_de_fuente).

Para modificar sus valores disponemos de una serie de variables en el fichero `less/variables.less` bajo el título "Tamaños de fuente en función del tamaño de pantaña".

Además, para facilitar la tarea de formatear texto introducido mediante un WYSWYG hemos incluido las clases: `.fuente-grande` , `.fuente-base` , `.fuente-pequena` .

```html_example

<p class="fuente-grande">Tamaño de fuente grande</p>
<p class="fuente-base">Tamaño de fuente base</p>
<p class="fuente-pequena">Tamaño de fuente pequeña</p>

```

### Títulos de sección

Los títulos de sección se muestran por defecto con el siguiente aspecto.

```html_example

<h1>Título 1</h1>
<h2>Título 2</h2>
<h3>Título 3</h3>
<h4>Título 4</h4>
<h5>Título 5</h5>
<h6>Título 6</h6>

```

Además, disponemos de las siguientes clases para cambiar la apariencia de un título de sección o de cualquier otro tipo de elemento.

```html_example

<h2 class="h1">Título 1</h2>
<h1 class="h2">Título 2</h1>
<h4 class="h3">Título 3</h4>
<h1 class="h4">Título 4</h1>
<h3 class="h5">Título 5</h3>
<h5 class="h6">Título 6</h5>

```

### Cuerpo de texto

Este es un ejemplo de cuerpo de texto basado en la ficha de obra del Guernica de Picasso.

```html_example

<p>Reflejo fiel de una época y de unas luctuosas y dramáticas circunstancias, el lienzo Guernica nació para formar parte del Pabellón Español en la Exposición Internacional de París, de 1937. El motivo que impulsó a Pablo Picasso a realizar la escena representada en esta gran pintura fue la noticia de los bombardeos efectuados por la aviación alemana sobre la villa vasca que da nombre a la obra, conocidos por el artista a través de las dramáticas fotografías publicadas, entre otros diarios, por el periódico francés L'Humanité. A pesar de ello, tanto los bocetos como el cuadro no contienen ninguna alusión a sucesos concretos, sino que, por el contrario, constituyen un alegato genérico contra la barbarie y el terror de la guerra. Concebido como un gigantesco cartel, el gran lienzo es el testimonio del horror que supuso la Guerra Civil española, así como la premonición de lo que iba a suceder en la Segunda Guerra Mundial. La sobriedad cromática, la intensidad de todos y cada uno de los motivos, y la articulación de esos mismos motivos, determinan el extremado carácter trágico de la escena, que se iba a convertir en el emblema de los desgarradores conflictos de la sociedad de nuestros días.</p>

<p>Guernica ha suscitado numerosas y polémicas interpretaciones, circunstancia a la que contribuye indudablemente la voluntaria eliminación del lienzo de cualquier tonalidad ajena a la grisalla. Al analizar su iconografía, uno de los estudiosos de la obra, Anthony Blunt, divide a los actores de esta composición piramidal en dos grupos, el primero de los cuales está integrado por tres animales: el toro, el caballo herido y el pájaro alado que se aprecia tenuemente al fondo, a la izquierda. Los seres humanos componen un segundo grupo, en el que figuran un soldado muerto y varias mujeres: la situada en la zona superior derecha, que se asoma por una ventana y sostiene hacia fuera una lámpara; la madre que, a la izquierda del lienzo, grita llevando al hijo muerto; la que entra precipitadamente por la derecha; y finalmente, la que clama al cielo, con los brazos alzados, ante una casa en llamas.
En este mismo contexto, tampoco hay que olvidar que dos años antes, en 1935, Picasso había grabado al aguafuerte la Minotauromaquia, obra sintética que condensa en una sola imagen todos los símbolos del ciclo dedicado a este animal mitológico y que es, a la vez, el antecedente más directo de Guernica.</p>

<p>Los acontecimientos de la vida privada de Picasso, junto a los sucesos políticos que atribularon al continente europeo en el período de entreguerras, se fusionan en los motivos creados por el pintor en estos momentos, para dar lugar tanto al propio Guernica como a sus bocetos y post scriptum, considerados como unas de las obras de arte más representativas del siglo XX.</p>

```

*/

// Tamaños de fuente

.fuente-grande {
	.font-size-large ();
}

.fuente-base {
	.font-size-base ();
}

.fuente-pequena,
.fuente-pequena p {
	.font-size-small ();
	line-height: 1.2;
}

.fuente-muy-pequena {
	font-size: @xsmallFontSize !important;
	line-height: 1.2 !important;
}

.fuente-negrita {
	font-weight: bold;
}

.fuente-normal {
	font-weight: normal;
}

.fuente-cursiva {
	font-style: italic;
}

.texto-tachado {
	text-decoration: line-through;
}

// Titulos de sección

h1,
.h1 {
	.font-size-h1 ();
	margin-bottom: 20px;
	font-family: @boldScout;
	text-transform: none;
	small {
		font-size:28px;
	}
	strong {
		font-family:@blackScout;
	}
	em {
		font-family:@boldScoutItalic;
		font-weight:normal;
		font-style:normal;
	}
}

h2,
.h2 {
	.font-size-h2 ();
	margin-bottom: 10px;
	font-family: @boldScout;
	text-transform: none;
	small {
		font-size:18px;
	}
}

h3,
.h3 {
	.font-size-h3 ();
	margin-bottom: 10px;
	font-family: @thinScout;
	text-transform: none;
	small {
		font-size: 14px;
	}
}

h4,
.h4 {
	.font-size-h4 ();
	margin-bottom: 5px;
	font-family: @regularScout;
	text-transform: uppercase;
	small {
		font-size: 14px;
	}
}

h5,
.h5 {
	.font-size-h5 ();
	margin-bottom: 5px;
	font-family: @lightScout;
	color: @gray;
	text-transform: none;
	small {
		font-size: 14px;
	}
}

h6,
.h6 {
	.font-size-h6 ();
	margin-bottom: 5px;
	font-family: @lightScout;
	text-transform: uppercase;
	color: @grayLight;
	small {
		font-size: 12px;
	}
}

h1, .h1, 
h2, .h2, 
h3, .h3, 
h4, .h4, 
h5, .h5, 
h6, .h6 {
	line-height: 1;
	small {
		font-family: @lightScout;
		color: @grayLight;
	}
	a {
		color:@grayDark;
		&:hover {
			color:@grayDark;
		}
	}
	* {
		font-weight:normal;
	}
}

p + h1:not([class]),
p + h2:not([class]),
p + h3:not([class]),
p + h4:not([class]),
p + h5:not([class]),
p + h6:not([class]),
ul + h1:not([class]),
ul + h2:not([class]),
ul + h3:not([class]),
ul + h4:not([class]),
ul + h5:not([class]),
ul + h6:not([class]),
ol + h1:not([class]),
ol + h2:not([class]),
ol + h3:not([class]),
ol + h4:not([class]),
ol + h5:not([class]),
ol + h6:not([class]),
dl + h1:not([class]),
dl + h2:not([class]),
dl + h3:not([class]),
dl + h4:not([class]),
dl + h5:not([class]),
dl + h6:not([class]) {
	padding-top: @space-unit-sm;
}

.hideText {
	text-indent: -999em;
	letter-spacing: -999em;
	overflow: hidden;
}

// Enlaces

a {
	color:@linkColor;
	&:hover {
		color:@linkColorHover;
	}
}

// Parrafos

p {
	margin: 0 0 15px;
}

.lead {
	&,
	p {
		.lead-styles;
	}

	p:last-child {
		margin-bottom: 0;
	}
	
}

// Separadores

hr {
	border-width: 1px 0 0 0;
	border-color: @grayLighter;
}

// Listas

ul, ol {
	margin:0 0 15px 25px;
}

li,
dt,
dd {
	line-height: @baseLineHeight;
}

// Listados que cuelgan de una sección

.lista--seccion {
	.margin-bottom-xs ();
	.clearfix ();
	border-bottom: 1px solid #ddd;
	&:last-child {
		border-bottom: none;
	}
}

.lista--seccion__titulo {
	margin-top: 5px;
	@media (min-width: 1281px) {
		width: 30%;
		float: left;
	}
}

.lista--seccion__lista {
	margin-left: 0;
	list-style: none;
	@media (min-width: 1281px) {
		width: 70%;
		float: left;
	}
	li {
		margin-bottom: 5px;
	}
}

.dl-horizontal {
	width:100%;
	overflow:hidden;
	border-bottom:1px solid #bbb;
	dt {
		margin-bottom:5px;
		padding-top:5px;
		text-align: left;
		text-overflow:clip;
  		white-space:normal;
	}
	dd {
		margin:0;
		padding:5px 0 5px 130px;
		border-top:1px solid #bbb;
	}
}

.dl-horizontal--sin-bordes {
    margin-top: 0;
    border-bottom: none;

    dt {
    	width: 80px;
	    padding-top: 0;
	    text-align: right;
	    @media (max-width: 767px) {
	    	text-align: left;
	    }
	}

	dd {
	    border-top: none;
	    padding-top: 0;
	    padding-bottom: 0;
	    padding-left: 85px;
	    @media (max-width: 767px) {
	    	padding-left: 0;
	    }
	}

}

// Blockquote

blockquote {
	margin: @space-unit-md 0;
	border-color: @grayDarker;
	&.pull-right {
		border-color: @grayDarker;
	}

	p {
		font-family: @thinScout;
		font-size: @fontScale1;
		font-weight: 400;
		line-height: @baseLineHeight;
	}

	small {
		font-family: @thinScout;
		font-size: @fontScale0;
		line-height: @smallLineHeight;
		font-weight: 400;
		color: @grayLight;
	}
}

// Code, pre

code,
pre {
	.border-radius (0);
}

// Entradilla

.entradilla {
	.margin-bottom-sm ();
	font-family: @thinScout;
	line-height: 1.3;
	.font-size-large ();
}

.introduccion {
	.margin-bottom-sm ();
	.padding-bottom-xs ();
	border-bottom: 1px solid #ddd;
}

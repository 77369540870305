/*doc
---
title: Ficha Sala
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Titulo

body.node-type-sala {

	h1.page-header {

		span.nombre {
			margin: 7px 5px 0 0;
			padding: 0 5px;
			display: block;
			float: left;
			font: 400 16px/23px @boldScout;
			background: @grayDark;
			color: @white;
			text-transform: uppercase;
			@media (min-width: 1281px) {
				margin: 9px 5px 0 0;
			}
			@media (max-width: 767px) {
				margin: 0 0 5px 0;
			}
		}

		span.lema {
			@media (max-width: 767px) {
				display: block;
				clear: both;
			}
		}

	}

}

// Descargar Ficha sala

div#descripcion-sala {
	@media (min-width: 768px) and (max-width: 979px) {
		width: 414px;
		margin-bottom: 60px;	
	}
}

div#descargar-ficha-sala {
	@media (min-width: 768px) and (max-width: 979px) {
		width: 290px;
	}
}

.salas-relacionadas {
	margin-bottom: 10px;
}

.field-name-field-sala-informacion-widget {
	clear: both;
}

// Imagenes sala

div#imagenes-sala {
	margin-bottom: 20px;
}

// Autores de la sala

div#autores-sala {
	margin-bottom: 20px;
}
/*doc
---
title: Horarios y tarifas
category: Modulos
---

Documentación...

*/

.horarios-tarifas__cabecera {
	.margin-bottom-sm ();
}

.horarios-tarifas__cabecera__titulo {
	@media (min-width: 768px) {
		padding-top: 6px;
		.no-margin-bottom ();
	}
}

.horarios-tarifas__cabecera__accion {
	@media (min-width: 768px) {
		text-align: right;
	}
}
/*doc
---
title: Submenú
category: Mixins
---

*/

/**
 * Con este mixin generamos las variantes de los fondos de color, como por 
 * ejemplo los de la sección de Educación.
 */

.fondo-variante-color (@texto, @fondo, @texto-destacado, @texto-suavizado, @enlace, @enlace-hover, @etiqueta-color, @etiqueta-fondo, @etiqueta-color-hover, @etiqueta-fondo-hover) {
	color: @texto;
    &,
    &:before {
        background-color: @fondo;
    }
    
    h1, h2, h3, h4, h5, h6 {
        color: @texto-destacado;
    }
    
    a:not([class]) {
        color: @enlace;
        &:hover,
        &:focus {
            color: @enlace-hover;
        }
    }

    .texto-destacado {
        color: @texto-destacado;
    }
    
    .texto-suavizado {
        color: @texto-suavizado;
    }

    .btn-primary {
        background-color: darken(@fondo, 30%);
        border-color: darken(@fondo, 30%);
        &:hover,
        &:active {
            background-color: darken(@fondo, 38%);
            border-color: darken(@fondo, 38%);
        }
    }
    
    .etiqueta {
        color: @etiqueta-color;
        background-color: @etiqueta-fondo;
        &:hover {
            color: @etiqueta-color-hover;
            background-color: @etiqueta-fondo-hover;
        }
    }
    
}
@banner-microsite-image-width: 57%;
@banner-microsite-text-min-height: 190px;

.banner-microsite {
  display: block;
  @media (min-width: @tablet) {
    position: relative;
  }
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.banner-microsite--gigapixel {
  &:hover {

    .banner-microsite__text {
      background-color: @gigapixel-dark;
    }

  }

  .banner-microsite__text {
    background-color: @gigapixel;
  }

}

.banner-microsite--repensar-guernica {
  &:hover {

    .banner-microsite__text {
      background-color: @repensar-guernica-dark;
    }

  }

  .banner-microsite__text {
    background-color: @repensar-guernica;
  }

}

.banner-microsite--repensar-guernica-v2 {
  @media (min-width: @tablet) {
    display: flex;
  }

  .banner-microsite__text {
    background-color: @repensar-guernica;
    @media (min-width: @tablet) {
      margin-right: 0;
    }
    &:hover {
      background-color: @repensar-guernica-dark;
    }
  }

  .banner-microsite__block-title {
    background-color: @repensar-guernica;
  }

  .banner-microsite__block:hover {
    .banner-microsite__block-title {
      background-color: @repensar-guernica-dark;
    }
  }
}

.banner-microsite--itinerancias {
  &:hover {

    .banner-microsite__text {
      background-color: @itinerancias-dark;
    }

  }

  .banner-microsite__text {
    background-color: @itinerancias;
  }

}

.banner-microsite__text {
  display: block;
  padding: @space-unit-sm;
  color: @grayDark;
  transition: @transition-base;
  @media (min-width: @tablet) {
    min-height: @banner-microsite-text-min-height;
    margin-right: @banner-microsite-image-width;
  }
  &,
  &:hover {
    text-decoration: none;
  }
}

.banner-microsite__title {
  .title-style;
  .font-size-title2;
}

.banner-microsite__subtitle {
  .title-style(@thinScout);
  display: block;
  font-size: @fontScale3;
  @media (min-width: @desktop-large) {
		font-size: @fontScale4;
	}
}

.banner-microsite__image {
  display: none;
  @media (min-width: @tablet) {
    display: block;
    width: @banner-microsite-image-width;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    left: auto;
  }
}

.banner-microsite__blocks {
  @media (min-width: @tablet) {
    display: flex;
    flex-shrink: 0;
    width: @banner-microsite-image-width;
  }
}

.banner-microsite__block {
  display: block;
  position: relative;
  @media (max-width: @mobile-down) {
    height: 220px;
  }
  @media (min-width: @tablet) {
    width: 50%;
  }
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.banner-microsite__block-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banner-microsite__block-caption {
  position: absolute;
  top: auto;
  left: @space-unit-xs;
  right: @space-unit-xs;
  bottom: @space-unit-xs;
}

.banner-microsite__block-title {
  .title-style;
  .font-size-title4;
  display: inline-block;
  padding: @space-unit-xxs;
  transition: @transition-base;
}

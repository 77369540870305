.mosaic {
    .ancho-completo-without-padding-xs;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: @tablet) {
        display: flex;
        flex-wrap: wrap;
        background-color: @whiteDarker;
    }
}

.mosaic__item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    text-align: center;
    @media (min-width: @tablet) {
        flex-basis: percentage(1/2);
    }
    @media (min-width: @desktop) {
        flex-basis: percentage(1/3);
    }

    &,
    &:hover {
        color: inherit;
    }

    @media (min-width: @tablet) {
        &:hover {
            &::before {
                opacity: 0.65;
                background-image: linear-gradient(-180deg, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%);
            }
            .mosaic__info {
                align-items: center;
                background-color: #fff;
                border-color: @grayDarker;
            }
            .mosaic__caption {
                display: none;
            }
            .mosaic__text {
                display: block;
            }
        }
    }

    &::before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.65;
        background-image: linear-gradient(-180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%);
        transition: @transition-base;
    }
  img {
    width: 100%;
  }
}

.mosaic__info {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    top: 20px;
    left: 20px;
    right: 20px;
    transition: @transition-base;
}

.mosaic__caption {
    flex-basis: 100%;
    padding: @space-unit-xxs;
    > :last-child {
        margin-bottom: 0;
    }
}

.mosaic__title {
    margin-bottom: @space-unit-xxxs;
    .font-size-title3;
    color: #fff;
}

.mosaic__subtitle {
    .font-size-title4;
    color: #fff;
}

.mosaic__text {
    display: none;
    flex-basis: 100%;
    padding: @space-unit-xsm;
}

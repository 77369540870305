.episodes {
  .ancho-completo-xs;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: @grayDarker;

  @media (min-width: @tablet) {
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(0, max-content));
    grid-template-columns: repeat(4, 1fr);
    gap: @space-unit-md;
    /*margin-left: ~"calc( ( (100vw - 724px) / 2) * -1 ) ";
    margin-right: ~"calc( ( (100vw - 724px) / 2) * -1 ) ";*/
    padding: @space-unit-md;
  }

  @media (min-width: @desktop) {
    gap: @space-unit-md;
    /*margin-left: ~"calc( ( (100vw - 940px) / 2) * -1 ) ";
    margin-right: ~"calc( ( (100vw - 940px) / 2) * -1 ) ";*/
    padding: @space-unit-md;
  }

  @media (min-width: @desktop-large) {
    gap: @space-unit-lg;
    /*margin-left: ~"calc( ( (100vw - 1170px) / 2) * -1 ) ";
    margin-right: ~"calc( ( (100vw - 1170px) / 2) * -1 ) ";*/
    padding: @space-unit-lg;
  }

}

.episodes__snippet {
  @media (max-width: @mobile-down) {
    display: flex;
    min-height: 141px;
  }
  @media (min-width: @tablet) {
    grid-column-start: span 2;
  }
  &:hover {
    text-decoration: none;
  }
  &:not(.episodes__snippet--no-video):hover {
    @media (min-width: @tablet) {
      .episodes__snippet-image {
        display: none;
      }
      .episodes__snippet-video {
        display: block;
      }
    }
  }
  &:not(:last-child) {
    @media (max-width: @mobile-down) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid @grayDark;
    }
  }
  &:last-child:nth-child(odd) {
    @media (min-width: @tablet) {
      grid-column: 2 / span 2;
    }
  }
}

.episodes__snippet-media {
  width: 100%;
  position: relative;
  @media (max-width: @mobile-down) {
    flex-basis: 50%;
  }
  &::before {
    display: block;
    padding-top: percentage(9 / 16);
    content: "";
    @media (min-width: @tablet) {
      padding-top: percentage(9 / 21);
    }
  }
}

.episodes__snippet-image,
.episodes__snippet-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.episodes__snippet-video {
  display: none;
  max-width: 100%;
  object-fit: cover;
}

.episodes__snippet-caption {
  @media (max-width: @mobile-down) {
    flex-basis: 50%;
    padding-left: @space-unit-xs;
  }
  @media (min-width: @tablet) {
    padding-top: 20px;
  }
}

.episodes__snippet-header {
  @media (min-width: @tablet) {
    display: flex;
  }
}

.episodes__snippet-antetitle {
  display: block;
  font-size: @fontScale0;
  line-height: @headingsLineHeight;
  color: #fff;
  @media (max-width: @mobile-down) {
    margin-bottom: @space-unit-xxs;
  }
  @media (min-width: @tablet) {
    flex-shrink: 0;
    font-size: @fontScale2;
  }
  @media (min-width: @desktop) {
    font-size: @fontScale3;
  }
  @media (min-width: @desktop-large) {
    font-size: @fontScale4;
  }
}

.episodes__snippet-title {
  font-size: @fontScale1;
  line-height: @headingsLineHeight;
  color: #fff;
  @media (min-width: @tablet) {
    display: flex;
    margin-left: @space-unit-xs;
    padding-left: @space-unit-xs;
    font-size: @fontScale2;
    border-left: 1px solid @gray;
  }
  @media (min-width: @desktop) {
    margin-left: 20px;
    padding-left: 20px;
    font-size: @fontScale3;
  }
  @media (min-width: @desktop-large) {
    font-size: @fontScale4;
  }
}

/*doc
---
title: Volver arriba
category: Componentes
---

Documentación...

*/

.top{
	width:40px;height:40px;display:block;position:fixed;right:15px;bottom:0;top:auto;z-index:1000;
	line-height:40px;
	background:@grayDark; text-align:center; color:@white;
	opacity:1;filter:none;
	-webkit-transition:opacity 700ms linear;
	-moz-transition:opacity 700ms linear;
	transition:opacity 700ms linear;}
.top.hidden {
	opacity:0;}
.top:hover{
	background:@beige;color:@grayDark; text-decoration:none;}
.top span{
	display:none;}
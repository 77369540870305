.list-checks {
    margin-left: 0;
    list-style: none;

    li {
        margin-bottom: @space-unit-xxxs;
        padding-left: 20px;
        background-image: url('../images/iconos/check.svg');
        background-repeat: no-repeat;
        background-position: 0 .35em;
    }

}
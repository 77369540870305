.data {
    margin-bottom: @space-unit-xxs;
}

.data--sm {
    margin-bottom: 0;
    &:not(:last-child) {
        margin-bottom: @space-unit-xsm;
    }

    .data__title {
        display: inline;
        font-weight: normal;
    }

    .data__desc {
        display: inline;
        font-weight: normal;
        color: @grayDark;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 0;
            clear: both;
        }
    }

}

.data__title {
    font-size: @smallFontSize;
}

.data__desc {
    margin-bottom: @space-unit-xxs;
    font-size: @smallFontSize;
    font-weight: bold;

    * {
        font-size: inherit;
    }

}

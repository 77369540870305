/*doc
---
title: Banner
category: Componentes
---

Sirve para incluir banners en la web.

```html_example
<div class="row">
	<div class="span3">
	    
	    <!-- Banner dentro de una columna de 3 unidades -->
		<div class="banner">
			<img src="http://placehold.it/280x100">
		</div>
		<!-- / Banner dentro de una columna de 3 unidades -->

	</div>
</div>
```

##Banner para mostrar logos##

```html_example
<div class="row">
	<div class="span3">
	    
	    <!-- Banner dentro de una columna de 3 unidades -->
		<a href="" class="banner banner--logo">
		    <img src="http://placehold.it/80x80">
		</a>
		<!-- Banner dentro de una columna de 3 unidades -->

	</div>
</div>
```

*/

// Banner

.banner {
	border: 1px solid @borderLighter;
	&:hover {
		img {
			.opacity (75);
		}
	}
}

.banner--logo {
	display: block;
	.margin-bottom-sm ();
	.padding-horizontal-xs ();
	.padding-vertical-xs ();
	text-align: center;
}

// Banner normal (antiguo)

.banner-normal {
	.imagen {
		height: 165px;
		display: table-cell;
		vertical-align: middle;
		@media (min-width: 1281px) {
			height: 203px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			height: 125px;
		}
		img {
			max-width: 200px;
			max-height: 165px;
			@media (min-width: 1281px) {
				max-width: 250px;
				max-height: 203px;
			}
			@media (min-width: 768px) and (max-width: 979px) {
				max-width: 146px;
				max-height: 125px;
			}
		}
	}
	.imagen-vacia {
		background: @whiteDarker;
	}
	.area-imagen {
		width: 200px;
		display: block;
		@media (min-width: 1281px) {
			width: 250px;
		}
		@media (min-width: 768px) and (max-width: 979px) {
			width: 146px;
		}
	}
	div.pie-foto {
		display: none;
	}
	div.caption {
		padding-top: 10px;
		font: 400 12px/15px @sansFontFamily;
		color: @grayLight;
		@media (max-width: 767px) {
			padding-bottom: 10px;
		}
	}
	div.field-name-field-obra-autor {
		margin-bottom: 2px;
		font: 700 12px/14px @sansFontFamily;
		a {
			color: @grayDark;
			&:hover {
				color: @linkColorHover;
			}
		}
		div {
			display: inline;
		}
	}	
	h3.titulo {
		margin-bottom: 5px;
		font: 400 19px/19px @boldScout;
	}
	div.titulo {
		margin-bottom: 5px;
		h3.titulo {
			margin-bottom: 0;
		}
	}	
	.obra {
		h3.titulo {
			font-family: @boldScoutItalic;
			font-style: normal;
			font-weight: normal;
			strong {
				font-family: @blackScoutItalic;
				font-style: normal;
				font-weight: normal;
			}
		}
	}
	h4 {
		margin-bottom: 5px;
		font: 400 18px/18px @thinScout;
		color: @grayDark;
	}
	div.titulo h4 {
		margin-bottom: 0;
		font: 400 18px/18px @thinScoutItalic;
	}
	div.field {
		margin-bottom: 5px;
		font: 400 12px/15px @sansFontFamily;
		color: @grisClaro;
	}
	div.entradilla {
		p,
		ul,
		li {
			font: 400 12px/15px @sansFontFamily;
		}
	}
}

#esculturas-publicas .banner-normal {
	background: none;
}

// IE7

.lt-ie8 {
	.banner-normal {
		.imagen {
			display: block;
			line-height: 165px;
			img {
				max-width: 200px;max-height: 165px;vertical-align: middle;
			}
		}
	}
}
.banner-full-width {
    .ancho-completo-xs;
    display: block;
    color: inherit;
    background-color: @beige;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: @transition-base;
    &:hover {
        color: @component-active-color;
        text-decoration: none;

        .btn {
            border-color: @component-active-bg;
            background-color: @component-active-bg;
        }

    }
    @media (max-width: @mobile-down) {
        background-image: none!important;
    }
}

.banner-full-width__content {
    padding-top: @space-unit-md;
    padding-bottom: @space-unit-md;
    text-align: center;
    @media (min-width: @tablet) {
        padding-left: @space-unit-sm;
        padding-right: @space-unit-sm;
        background-color: @beige;    
    }
}

.banner-full-width__title {
    .title-style;
    margin-bottom: @space-unit-xxs;
}

.banner-full-width__text {
    margin-bottom: @space-unit-xs;
}
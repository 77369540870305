.small{
	font-size: @smallFontSize;
	line-height: 1.2;
	color: darken(@grayLighter, 13%);
}

// SECTION: sedes
#sedes{
	background:url(../images/bk.borde.gris.png) center 0 repeat-y;
	@media (max-width: 767px) {
		background: none;
	}
}

// ARTICLE .edificio
.edificio{
	margin-bottom: 30px;
	position: relative;
	@media (min-width: 768px) and (max-width: 979px) {
		height: 94px;
	}
	.h4{
		margin: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		display: inline-block;
		padding: 7px 10px;
		background-color: @whiteDarker;
		@media (min-width: 768px) and (max-width: 979px) {
			font-size: 13px;
			line-height: 13px;
		}
	}
}

// SECTION: Horarios y tarifas
#horarios-tarifas{
	background:url(../images/bk.borde.gris.png) center 0 repeat-y;
	@media (max-width: 767px) {
		background: none;
	}
	.h2{
		margin-bottom: 15px;
		padding-bottom: 5px;
		border-bottom: 1px solid @grayLighter;
	}
	.edificio{
		@media (min-width: 768px) {
			margin-bottom: 15px;
		}
	}
	.tarifas{
		padding-top: 15px;
		background-color: @whiteDarker;
		background-position: 10px 15px;
		background-image: url(../images/iconos/visita/ico.entrada-gris.png);
		@media (max-width: 767px) {
			padding-top: 0;
			background-color: transparent;
			background-image: none;
		}
	}
	.dl-horizontal{
		@media (min-width: 768px) {
			margin: 0;
		}
	}
	.ver-todos{
		padding-top: 20px;
		text-align: center;
		background-color: @white;
		@media (max-width: 767px) {
			padding-top: 0;
			text-align: left;
		}
	}
}

// ARTICLE: .horarios & .tarifas
.horarios,
.tarifas{
	min-height: 75px;
	padding-left: 75px;
	background-position: 10px 0;
	background-repeat: no-repeat;
	@media (max-width: 767px) {
		padding-left: 0;
	}
	.h3{
		margin-bottom: 15px;
		padding-bottom: 10px;
		border-bottom: 1px solid @grayLighter;
	}
	.dl-horizontal{
		margin: 0 0 30px;
		border-bottom: none;
	  dt{
	  	width: 145px;
	  	margin: 0 0 15px 0;
	  	padding: 0;
	  	float: left;
	  	font-weight: normal;
	  	line-height: @baseLineHeight - 2;
	  	clear: both;
	  	@media (min-width: 1281px) {
	  		width: 195px;
	  	}
	  	@media (min-width: 768px) and (max-width: 979px) {
	  		width: 91px;
	  	}
	  	@media (max-width: 479px) {
	  		width: 130px;
	  	}
	  }
	  dd{
	  	.clearfix;
	  	width: 219px;
	  	margin: 0 0 15px 20px;
	  	padding: 0;
	  	float: left;
	  	border: none;
	  	line-height: @baseLineHeight - 2;
	  	@media (min-width: 1281px) {
	  		width: 269px;
	  		margin-left: 30px;
	  	}
	  	@media (min-width: 768px) and (max-width: 979px) {
	  		width: 165px;
	  	}
	  	@media (max-width: 767px) {
				width: 362px;
	  	}
	  	@media (max-width: 479px) {
				width: 129px;
	  	}
	  	.help-text{
	  		width: 140px;
	  		float: right;
	  		display: block;
	  		@media (min-width: 1281px) {
	  			width: 170px;
	  		}
	  		@media (min-width: 768px) and (max-width: 979px) {
	  			width: auto;
					float: none;
	  		}
	  		@media (max-width: 767px) {
					width: 283px;
	  		}
	  		@media (max-width: 479px) {
	  			width: auto;
					float: none;
	  		}
	  		a{
					color: darken(@grayLighter, 10%);
					text-decoration: underline;
					&:hover,
					&:active{
						color: @linkColorHover;
					}
	  		}
	  	}
	  }
	}
}

// ARTICLE: .horarios
.horarios{
	background-image: url(../images/iconos/visita/ico.horario.png);
	@media (max-width: 767px) {
		background-image: none;
	}
}

// ARTICLE: .tarifas
.tarifas{
	background-image: url(../images/iconos/visita/ico.entrada.png);
	@media (max-width: 767px) {
		background-image: none;
	}
}

// Planificar visita
#planificar-visita{
	.title-section{
		margin-bottom: 30px;
		padding-bottom: 5px;
		border-bottom: 4px solid @grayDark;
	}
}

#horarios-tarifas-detalle{
	background:url(../images/bk.borde.gris.png) center 0 repeat-y;
	@media (max-width: 767px) {
		background: none;
	}
}
.border-lighter {
    border: 1px solid @borderLighter;
}

.border-top-lighter {
    border-top: 1px solid @borderLighter;
}

.border-bottom-lighter {
    border-bottom: 1px solid @borderLighter;
}

.border-bottom-dark {
    border-bottom: 2px solid @grayDark;
}

.border-top-lighter\@tablet {
    @media (min-width: @tablet) {
        border-top: 1px solid @borderLighter;
    }
}

.border-bottom-lighter\@tablet {
    @media (min-width: @tablet) {
        border-bottom: 1px solid @borderLighter;
    }
}

.border-left-gray\@tablet {
    @media (min-width: @tablet) {
        border-left: 1px solid @gray;
    }
}
/*doc
---
title: Royal Slider
category: Componentes
---

Documentación...

<div class="alert alert-block">Hay otras hojas de estilo para dar forma a este slider.</div>

*/

// Diapositivas
div.sliderContainer{
	margin-bottom:20px;
	position:relative;}

div.sliderContainer--educacion {
  margin-bottom: 0;
}

// Diapositivas: pie
.rsMinW .rsGCaption{
	width: 100%;
	position: static;
	float: left;
	left: auto;
	bottom: auto;
	padding: 0;
	font:400 14px/18px @thinScout;
	background: transparent;

	.rsCaption{
		padding: 10px 0 10px 20px;
		color: @grayLight;
		//border-bottom:1px solid @grayLighter;
		position: relative;

		&:before{
			.spritePadded(sprite1, 1, 5, 12px, 11px);
   			left: 5px;
   			top: 11px;
		}
	}
}

// Diapositivas: minipaginado
.rsMinW .rsBullets{
	display:none;}

// Diapositivas: botones
.rsMinW .rsArrow{
	width:24px; height:24px; bottom:auto; top:47%; z-index:100;}
.rsMinW .rsArrowIcn{
	margin:0; top:auto; left:auto; right:auto;}
.rsMinW .rsArrowLeft{
	right:auto; left:5px;}
.rsMinW .rsArrowRight{
	right:5px; left:auto;}

// Diapositivas: pantalla completa
.rsMinW .rsFullscreenBtn{
	width:32px;height:32px;right:5px; top:5px;}
.rsMinW .rsFullscreenIcn{
	margin:0;}

/*doc
---
title: Ficha de Obra
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Titulo

body.node-type-obra {
	
	.page-header {
		margin: 0;
		font-family: @boldScoutItalic;
		
		strong {
			font-family: @blackScoutItalic;
		}

	}

}

// Autores y colaboradores

div#autores-colaboradores {
	position: relative;
	padding: 7px 30px 7px 0px;
	border-bottom: 1px solid @grayLighter;
}

// Listado autores

ul.autores {
	margin: 0;
	list-style: none;

	li {
		margin-right: 20px;
		display: inline;
		font: 400 18px/18px @thinScout;
		color: @grayLight;
		@media (max-width: 767px) {
			margin-bottom: 10px;
			white-space: normal;
			display: block;
		}

		a {
			margin-right: 5px;
			font: 400 19px/19px @boldScout;
		}

	}

	span.separador {
		@media (max-width: 767px) {
			display: none;
		}
	}

	span.datos-biograficos {
		@media (max-width: 767px) {
			display: block;
			line-height: 15px;
		}
	}

}

// Colaboradores

dl.colaboradores {
	margin: 0;

	dt,
	dd {
		margin: 0 5px 0 0;
		display: inline;
		font: 400 16px/16px @thinScout;
		color: @grayLight;
	}

	dd {

		a {
			color: @grayDark;
		}

	}

	dt {
		text-decoration: underline;
		color: @gray;
	}

}

// Foto horizontal

div#foto-horizontal {
	margin-bottom: 20px;
	border-bottom: 1px solid @grayLighter;
	@media (max-width: 767px) {
		border-bottom: none;
	}
	
	div#imagen-obra {
		border-bottom: 1px solid @grayLighter;
	}

}

// Foto vertical

div#foto-vertical {
	margin-bottom: 20px;
	border-bottom: 1px solid @grayLighter;
	background: url(../images/bk.borde.gris.png) center 0 repeat-y;
	@media (max-width: 767px) {
		background: none;
		border-bottom: none;
	}
}

// Foto de la obra

div#imagen-obra {
	padding: 10px 0;
}

div.field-name-field-obra-imagen {
	padding-bottom: 10px;
}

// Cuerpo ficha obra

div#cuerpo-ficha-obra {
	padding-top: 10px;
}

// Comentario de la obra

div.field-name-field-obra-comentario-de-la-obra {
	margin-bottom: 20px;
	
	p,
	li,
	dd,
	dt {
		@media (min-width: 1281px) {
			font-size: 14px;
			line-height: 21px;
		}
	}

}

// Mostrar mas info

div.mostrar-ocultar {
	margin-bottom: 25px;
}

a#lnk-mostrar-ocultar {
	margin: 0;
	padding: 4px 10px 5px 5px;
	display: inline-block;
	font: 700 13px/15px @sansFontFamily;
	background: @grayDark;
	color: @white;
	&:hover {
		background: @linkColorHover;
		text-decoration: none;
	}
}

// Datos de la obra

ul#datos-obra-basicos {
	margin: 0 0 20px 0;
	padding: 15px 20px;
	list-style: none;
	background: @whiteDarker;

	li {
		margin: 0 0 5px 0;
		font: 400 13px/18px @sansFontFamily;
		&.datos-avanzados {
	
			ul {
				margin: 0;
				list-style: none;
			}

		}

		div {
			display: inline;
			float: none;
		}

	}

}

// Datos ubicacion

div#datos-ubicacion {
	margin-bottom: 20px;
	
	span {
		margin-bottom: 5px;
		padding: 0 5px;
		display: inline-block;
		font-size: 11px;
		border: 1px solid @grayLighter;
		text-transform: uppercase;
	}

}

// Colaboradores

div#colaboradores {
	margin-bottom: 15px;
	padding: 15px 0 0 0;
	border-top: 1px solid @grayLighter;
	@media (min-width: 1281px) {
		position: relative;
		padding-left: 100px;
	}

	div.field-label {
		margin: 0 0 5px 0;
		display: block;
		font: 400 11px/13px @sansFontFamily;
		text-transform: uppercase;
		color: @grayDark;
		@media (min-width: 1281px) {
			position: absolute;
			top: 20px;
			left: 0;
		}
	}

	div.field-items {
		display: inline-block;
	}

}

// Ficha de Obra compuesta

div#foto-obra-compuesta {

	> div.row {
		background: url(../images/bk.borde.gris.png) 730px 0 repeat-y;
		@media (min-width: 1281px) {
			background: url(../images/bk.borde.gris.png) 915px 0 repeat-y;		
		}
		@media (max-width: 979px) {
			background: none;		
		}
	}
	
	div#cuerpo-ficha-obra {
		border-top: 1px solid @grayLighter;
	}

}

// Autores relacionados

div#autores-relacionados {
	margin-bottom: 20px;
	position: relative;
	@media (min-width: 1281px) {
		margin-bottom: 30px;
	}
	
	ul {
		margin: 0 0 10px 0;
		border-top: 1px solid @borderLighter;

		li {
			list-style-type: none;
			border-bottom: 1px solid @borderLighter;
			&:hover {
				background-color: @grayHover;
			}

			a {
				padding: @space-unit-xxxs;
				display: block;
				font: 400 13px/18px @sansFontFamily;
				color: @gray;
				&:hover {
					text-decoration: none;
					background-color: @grayHover;
				}

			}

		}

	}

}

// Docs relacionados

div#docs_relacionados {
	margin-bottom: 20px;
	position: relative;
	
	ul {
		margin: 0 0 10px 0;
		border-top: 1px solid @borderLighter;

		li {
			margin: 0;
			list-style-type: none;
			border-bottom: 1px solid @borderLighter;
			&:hover {
				background-color: @grayHover;
			}

			.biblio {
				margin-right: 5px;
				padding: 5px 20px 7px 5px;
				color: @gray;
				border-bottom: none;
			}

			a {
				&:hover {
					text-decoration: none;
					background-color: @grayHover;
				}
			}

		}

	}

	.titulo {
		margin-bottom: 2px;
		font: 400 13px/18px @sansFontFamily;

		a {
			color: @gray;
		}

	}

	.autor {
		margin-right: 3px;
		font: 400 12px/15px @sansFontFamily;
		color: @grayLight;
	}

}

// Obras del autor

div#obras-autor {
	padding: 10px 0 20px 0;
	border-top: 4px solid @grayDark;

	h2 {
		margin-bottom: 20px;

		small {
			display: block;
			color: @grayDark;
		}

	}

	a.btn {
		margin-bottom: 20px;
	}

	span.autor {
		display: block;
	}

}

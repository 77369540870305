/*doc
---
title: Login Prensa
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

// Login para prensa

.form-inline div{
	margin:0;
	display:inline;
}
.region-before-breadcrumbs{
	padding-top:20px;
	float:right;
	.enlaces{
		font:400 11px/14px @sansFontFamily;
		text-align:right;
		float: right;
	}
	#user-login{
		margin-bottom:0;
		padding-top:0;
		text-align:right;
		label{
			display:none;
		}
	}
	@media (max-width: 767px) {
		padding-top:0;
		float:none;
		#user-login{
			margin-bottom:0;
			text-align:left;
			div{
				display:block;
			}
			.control-group{
				margin-bottom:5px;
			}
		}
	}
}

// Exposiciones para prensa
.view-exposiciones-informacion-para-prensa ul.thumbnails{
	margin-left:0;}

// Login y cambio de pwd
form#user-login,
form#user-pass,
form#user-register-form{
	padding-top:20px;
}

// Ajustes para el offset de las anclas de menu
#exposiciones-actuales-anchor,
#exposiciones-pasadas-anchor,
#exposiciones-futuras-anchor{
	position:absolute;
	top:-30px;
}

// Pagina registro Periodistas y login Periodistas
.page-prensa-registro,
.page-prensa-login{
	.page-header{
		border-bottom:none;
	}
	#pie-inferior{
		border-top:none;
	}
}
#user-login-form > div{
	padding:20px 19px;
	border:1px solid @grayLighter;
}
#user-register-form,
#user-login-form{
	.control-group{
		margin-bottom:0;
	}
}

// Usuario prensa: ver y editar perfil
.page-user{
	#main > .span12{
		width:620px;
	}
	.nav-tabs{
		margin-bottom:0;
	}
	// Ver
	.profile{
		padding:20px;
		border-left:1px solid @grayLighter;
		border-right:1px solid @grayLighter;
		border-bottom:1px solid @grayLighter;
	}

	.profile__section {
		.margin-top-sm ();
		.margin-bottom-xs ();
		border-bottom: 1px solid #ddd;
		h2 {
			display: inline-block;
		}
	}


	.field{
		margin-bottom:10px;
	}
	// Editar
	#user-profile-form, #user-register-form{
		padding:20px;
		border-left:1px solid @grayLighter;
		border-right:1px solid @grayLighter;
		border-bottom:1px solid @grayLighter;
		.password-strength{
			width:300px;
			float:right;
		}
		.password-strength-title,
		.password-strength-text{
			font:400 13px/18px @sansFontFamily;
		}
		.password-strength-text{
			float:right;
		}
		.password-indicator{
			font:400 13px/18px @sansFontFamily;
		}
		.password-indicator{
			background:@whiteDarker;
			.indicator{
				width:0px;
				height:15px;
				background:@grisClaro;
			}
		}
		.form-actions{
			padding:0;
			background:none;
			border-top:none;
		}
	}
	#edit-locale{
			display:none;
	}
}

@media (min-width: 1281px) {
body.page-user div#main > .span12{
	width:770px;}
}
@media (max-width: 979px) {
body.page-user div#main > .span12{
	width:auto;}
}
@media (max-width: 767px) {
body.page-user form#user-profile-form input.form-text,
body.page-user form#user-profile-form select,
body.page-user form#user-profile-form textarea{
	width:100%; min-height:30px;
	-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
body.page-user form#user-profile-form{
	border-top:1px solid @grayLighter;}
body.page-user form#user-profile-form div.password-strength{
	width:auto;float:none; margin-bottom:20px;}
}
.row-equal-height {
    @media (min-width: @tablet) {
        display: flex;
        &.header-block-right{
            div:first-child{
                order: 3;
            }
        }
    }
}

.row-equal-height__col {
    @media (min-width: @tablet) {
        display: flex;
        .section-header {
            width: 100%;
        }
    }
    @media (max-width: @mobile-down) {
        .section-header {
            margin-bottom: @space-unit-xsm;
        }
    }
}
.gap-xs,
.gap-space-xs {
  gap: @space-unit-xs !important;
}

.gap-xsm {
  gap: @space-unit-xsm !important;
}

.gap-sm,
.gap-space-sm {
  gap: @space-unit-sm !important;
}

.gap-md {
  gap: @space-unit-md !important;
}

.row-gap-xs,
.row-gap-space-xs {
  row-gap: @space-unit-xs !important;
}

.row-gap-xsm {
  row-gap: @space-unit-xsm !important;
}

.row-gap-sm {
  row-gap: @space-unit-sm !important;
}

.row-gap-md {
  row-gap: @space-unit-md !important;
}

.column-gap-xxxs {
  column-gap: @space-unit-xxxs !important;
}

.column-gap-xxs {
  column-gap: @space-unit-xxs !important;
}

.column-gap-xsm {
  column-gap: @space-unit-xsm !important;
}

.column-gap-sm {
  column-gap: @space-unit-sm !important;
}

.gap-lg\@tablet {
  @media (min-width: @tablet) {
    gap: @space-unit-lg !important;
  }
}

.row-gap-md\@tablet {
  @media (min-width: @tablet) {
      row-gap: @space-unit-md !important;
  }
}

.row-gap-lg\@tablet {
  @media (min-width: @tablet) {
      row-gap: @space-unit-lg !important;
  }
}

.column-gap-sm\@tablet {
  @media (min-width: @tablet) {
      column-gap: @space-unit-sm !important;
  }
}

.column-gap-xsm\@desktop {
  @media (min-width: @desktop) {
      column-gap: @space-unit-xsm !important;
  }
}

.column-gap-sm\@desktop {
  @media (min-width: @desktop) {
      column-gap: @space-unit-sm !important;
  }
}

.column-gap-lg\@tablet {
  @media (min-width: @tablet) {
      column-gap: @space-unit-lg !important;
  }
}

.column-gap-lg\@desktop {
  @media (min-width: @desktop) {
      column-gap: @space-unit-lg !important;
  }
}

.gap-lg\@desktop {
  @media (min-width: @desktop) {
    gap: @space-unit-lg !important;
  }
}

.column-gap-lg\@desktop-large {
  @media (min-width: @desktop-large) {
      column-gap: @space-unit-lg !important;
  }
}

.column-gap-sm\@desktop-large {
  @media (min-width: @desktop-large) {
      column-gap: @space-unit-sm !important;
  }
}
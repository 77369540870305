/*doc
---
title: Etiquetas
category: Componentes
---

Se trata de un nuevo componente para reemplazar al que trae Bootstrap.

*/

.etiqueta {
	margin-bottom: 5px;
	padding: 4px 8px 6px;
	display: inline-block;
	font-family: @sansFontFamily;
	font-size: 13px;
	font-weight: 700;
	line-height: 18px;
	color: @etiquetaColor;
	background-color: @etiquetaBackgroundColor;
	.transition (all .2s ease-in-out);
	&:hover {
		text-decoration: none;
		color: @etiquetaColor;
		background-color: darken(@etiquetaBackgroundColor, 10%);
	}
}

.etiqueta--grande {
	margin-right: 5px;
	padding: 7px 10px;
	&:last-child {
		.no-margin-right ();
	}
}

.etiqueta--muy-grande {
	.margin-bottom-xs ();
	.margin-right-xs ();
	padding: 12px 20px 11px;
	font-size: @largeFontSize;
	@media (max-width: 767px) {
		display: block;
		.no-margin-right ();
	}
}

.etiqueta--extra-grande {
	.margin-bottom-xs ();
	.margin-right-xs ();
	padding: 11px 20px 15px;
	position: relative;
	font-size: 20px;
	@media (max-width: 767px) {
		display: block;
		.no-margin-right ();
	}

	&:after {
		display: block;
		width: 100%;
		height: 5px;
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		background-color: rgba(0,0,0,.15);
	}

}

.etiqueta--sin-link {
  color: @textColorDark;
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}

.etiqueta--link\@mobile-down {
	@media (max-width: @mobile-down) {
		display: inline;
		margin: 0 @space-unit-xxxs 0 0;
		padding: 0;
		line-height: inherit;
		color: @linkColor;
		background-color: transparent;
		&:hover {
			color: @linkColorHover;
			background-color: transparent;
		}
		
	}
	&:not(:last-child) {
		@media (max-width: @mobile-down) {
			&::after {
				content: ',';
				font-weight: normal;
				color: @textColorDark;
			}
		}
	}
}

.etiqueta--inactiva {
	&,
	&:hover {
		color: @grayDark;
		background-color: @whiteDarker;
	}
}

.etiqueta--oscura {
	color: #fff;
	background-color: @etiquetaColor;
	&:hover {
		color: #fff;
		background-color: @grayDark;
	}
}

/**
 * Etiquetas pequeñas antiguas
 */

a.tipo,
span.tipo {
  margin-bottom: 5px;
  padding: 3px 5px;
  z-index: 100;
  display: inline-block;
  font-family: @regularScout;
  font-size: 14px;
  line-height: 1;
  color: @grayDark;
  background: @beige;
  @media (max-width: 767px) {
	padding: 6px 10px;
	font-size: 16px;
  }
}

/*doc
---
title: Webforms
category: Modulos
---

Documentación...

<div class="alert alert-error">Revisar que elementos se pueden convertir en componentes.</div>

*/

.node-webform {
	max-width:620px;
}
form.webform-client-form {
	margin:0 0 20px 0;
}
form.webform-client-form > div {
	padding:30px;
	border:1px solid @borderLight;
}
@media (min-width: 1281px) {
	.node-webform {
		max-width:770px;
	}
	form.webform-client-form {
		margin:0 0 30px 0;
	}
}
@media (min-width: 768px) and (max-width: 979px) {
	.node-webform {
		max-width:476px;
	}
}
@media (max-width: 767px) {
	.node-webform{
		max-width:inherit;
	}
}

@media (max-width: 480px) {
	form.webform-client-form input.form-text {
		width:100%;height:28px;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
}

// Fieldset
form.webform-client-form fieldset {
	margin-bottom:20px;
}
form.webform-client-form legend {
	padding-bottom:7px;
	margin-bottom: 30px;
	line-height:28px;
	font:400 24px/24px @thinScout;
	border-bottom-color:@borderLight;
}
@media (min-width: 1281px) {
	form.webform-client-form fieldset{
		margin-bottom:30px;
	}
}

// Descripcion
form.webform-client-form div.description{
	margin:-7px 0 10px 0;
	font:400 13px/18px @sansFontFamily;
	color:@grayLight;}
form.webform-client-form div.fieldset-description{
	margin:-15px 0 20px 0;}

// Parrafo destacado
form.webform-client-form .parrafo-destacado{
	margin-bottom:15px;
	font:400 13px/18px @sansFontFamily;}
form.webform-client-form .parrafo-destacado ul,
form.webform-client-form .parrafo-destacado p{
	margin-bottom:0;
	font:400 13px/18px @sansFontFamily;}

// Etiquetas alineadas a la izda
form.webform-client-form div.webform-container-inline {
	margin-bottom:20px;
	position:relative;
	padding-left:180px;
}
form.webform-client-form div.webform-container-inline div.webform-container-inline{
	margin-bottom:0;padding-left:0;}
form.webform-client-form div.webform-container-inline *{
	margin-bottom:0;}

form.webform-client-form div.webform-container-inline label {
	width:160px;
	margin-left:-180px;
	text-align:right;
	line-height: 1.25;
	display: inline-block;

}
form.webform-client-form div.webform-container-inline div.controls label {
	position:static;
}

form.webform-client-form div.webform-container-inline input.form-text {
	width:100%;
	height:28px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	vertical-align: top;
}
form.webform-client-form div.webform-container-inline input,
form.webform-client-form div.webform-container-inline select,
form.webform-client-form div.webform-container-inline textarea{
	vertical-align: top;
	width:100%;
}

form.webform-client-form div.webform-container-inline div.description {
	margin:2px 0 0 0;
}

@media (max-width: 767px) {
	form.webform-client-form div.webform-container-inline {
		margin-bottom:9px;
		padding-left: 0;
	}
	form.webform-client-form div.webform-container-inline label{
		width:auto;
		margin-left: 0;
		margin-right:0;
		margin-bottom: 5px;
		padding-top:0;
		display:block;
		text-align:left;
	}

	form.webform-client-form div.webform-container-inline input,
	form.webform-client-form div.webform-container-inline select,
	form.webform-client-form div.webform-container-inline textarea{
		margin-bottom:9px;
		vertical-align: top;
	}

	form.webform-client-form div.webform-container-inline div.description{
		margin:-7px 0 9px 0;padding-left:0;
	}
}

// Titulos
form.webform-client-form p.titulo{
	padding-bottom:2px;
	font:700 12px/14px @sansFontFamily;
	border-bottom:1px solid #eee; text-transform:uppercase;}

// Fecha
form.webform-client-form div.webform-container-inline.webform-datepicker {
	padding-left:0;
}
form.webform-client-form div.webform-container-inline .controls select,
form.webform-client-form div.webform-container-inline input.webform-calendar{
	width: auto;
}
form.webform-client-form div.webform-component-date div.webform-container-inline {
	margin-bottom:9px;
}
form.webform-client-form input.webform-calendar {
	margin-top:6px;
}
@media (max-width: 767px) {
	form.webform-client-form div.webform-component-date input,
	form.webform-client-form div.webform-component-date select,
	form.webform-client-form div.webform-component-date textarea{
		margin-bottom:0;
	}
	form.webform-client-form div.webform-component-date div.webform-container-inline{
		margin-bottom:9px; display:inline-block;
	}
}
@media (max-width: 480px) {
	form.webform-client-form div.webform-component-date input.form-text{
		width:auto;
	}
}

// Subir archivo
form.webform-client-form div.form-managed-file{
	width:100%;overflow:hidden;}
form.webform-client-form div.webform-component-managed_file input.form-file{
	margin-right:20px; margin-bottom:3px;float:left;}
form.webform-client-form div.webform-component-managed_file .btn{float:left;}
form.webform-client-form div.webform-component-managed_file div.description{
	clear:both;}
form.webform-client-form div.webform-component-managed_file.webform-container-inline{
	position:relative;padding-left:180px;}

form.webform-client-form div.webform-component-managed_file.webform-container-inline div.description{
	padding-left:0;}
@media (max-width: 767px) {
form.webform-client-form div.webform-component-managed_file div.description{
	margin-top:0;}
form.webform-client-form div.webform-component-managed_file.webform-container-inline{
	position:static;padding-left:0;}
form.webform-client-form div.webform-component-managed_file.webform-container-inline label{
	position:static;}
form.webform-client-form div.webform-component-managed_file.webform-container-inline div.description{
	padding-left:0;}
}
@media (max-width: 480px) {
form.webform-client-form div.webform-component-managed_file div.form-managed-file{
	display:block;}
form.webform-client-form div.webform-component-managed_file div.form-managed-file input.form-file{
	width:100%;float:none;margin:0;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;}
}

// Cuadricula
form.webform-client-form div.webform-component-webform_grid table.webform-grid{
	margin-bottom:9px;
	font:400 13px/18px @sansFontFamily;
	border-top:1px solid @grayLighter;}
form.webform-client-form div.webform-component-webform_grid table.webform-grid tbody tr td,
form.webform-client-form div.webform-component-webform_grid table.webform-grid tbody tr th{
	background:@white;}
form.webform-client-form div.webform-component-webform_grid table.webform-grid div.control-group{
	margin-bottom:0;}
@media (max-width: 480px) {
form.webform-client-form div.webform-component-webform_grid table.webform-grid{
	width:100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;}
}

// Opciones de seleccion
form.webform-client-form div.webform-component-radios,
form.webform-client-form div.webform-component-checkboxes{
	width:100%; overflow:hidden;}
form.webform-client-form div.form-radios,
form.webform-client-form div.form-checkboxes{
	margin-bottom:9px;}
form.webform-client-form div.form-radios label,
form.webform-client-form div.form-checkboxes label{
	color:#666;}
form.webform-client-form div.form-radios label,
form.webform-client-form div.form-checkboxes label{
	display:inline-block;}
form.webform-client-form div.form-radios div.control-group,
form.webform-client-form div.form-checkboxes div.control-group{
	margin-bottom:0; margin-left:0; float:none;}

// Opciones de seleccion alineadas
form.webform-client-form div.webform-component-radios,
form.webform-client-form div.webform-component-checkboxes{
	position:relative; width:auto;}
form.webform-client-form div.webform-container-inline div.form-radios,
form.webform-client-form div.webform-container-inline div.form-checkboxes{
	margin-bottom:0;display:block;}
form.webform-client-form div.webform-container-inline div.form-radios label,
form.webform-client-form div.webform-container-inline div.form-checkboxes label{
	width:auto;float:none; display:block;
	text-align:left;}
form.webform-client-form div.webform-container-inline div.form-radios > label,
form.webform-client-form div.webform-container-inline div.form-checkboxes > label{
	position:absolute; top:0; left:0;}
form.webform-client-form div.webform-component-radios.webform-container-inline div.description,
form.webform-client-form div.webform-component-checkboxes.webform-container-inline div.description{
	margin-top:2px;}
@media (max-width: 767px) {
form.webform-client-form div.webform-container-inline div.form-radios,
form.webform-client-form div.webform-container-inline div.form-checkboxes{
	padding-left:0;display:block;}
}

// Textarea
form.webform-client-form div.form-textarea-wrapper{
	margin-bottom:9px;}
form.webform-client-form div.form-textarea-wrapper textarea{
	max-width:100%;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	box-sizing:border-box;}

// Hora
form.webform-client-form div.webform-component-webform_time div.webform-container-inline{
	margin-bottom:9px;}
form.webform-client-form div.webform-component-webform_time div.webform-container-inline label{
	width:auto;min-width:inherit; margin-right:5px;float:none;}
form.webform-client-form div.webform-component-webform_time div.form-radios{
	padding:0; display:inline-block;}
@media (max-width: 767px) {
form.webform-client-form div.webform-component-webform_time input,
form.webform-client-form div.webform-component-webform_time select,
form.webform-client-form div.webform-component-webform_time textarea{
	margin-bottom:0;}
form.webform-client-form div.webform-component-webform_time div.webform-container-inline{
	margin-bottom:9px; display:inline-block;}
}

// Botones
//.webform-client-form__actions {
//	text-align: center;
//}

form.webform-client-form .btn {
	padding:10px 40px;
	//margin-left: 180px;
}
@media (max-width: 767px) {
	form.webform-client-form .btn {
		margin-left: 0;
	}
}

form.webform-client-form .btn-primary {
	background:@black;
	.box-shadow(0 0 0 transparent);
}
form.webform-client-form .btn-primary:hover{
	background:@btnInverseBackgroundHighlight;
}

// Confirmacion
body.page-node-done div.webform-confirmation {
	max-width:620px;margin-bottom:20px;padding:17px 20px 5px 20px;
	border:1px solid @grayLighter;background:@whiteDark;}
@media (min-width: 1281px) {
body.page-node-done div.webform-confirmation {
	max-width:770px;}
}
@media (min-width: 768px) and (max-width: 979px) {
body.page-node-done div.webform-confirmation {
	max-width:476px;}
}
@media (max-width: 767px) {
body.page-node-done div.webform-confirmation {
	max-width:inherit;}
}
body.page-node-done div.links a{
	margin-bottom:20px;display:inline-block;padding:2px 5px 3px 7px;
	font:700 13px/18px @sansFontFamily;
	border:1px solid @grayLighter; background:@whiteDark; color:@grayDark;}
body.page-node-done div.links a:hover{
	text-decoration:none; background:@whiteDarker;}
@media (min-width: 1281px) {
body.page-node-done div.links a{
	margin-bottom:30px; }
}

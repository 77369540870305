/*doc
---
title: Ficha
category: Modulos
---

Estilos básicos para todas las fichas de contenido final.

*/

/**
 * Tamaños de título en función del número de caracteres.
 */

.h1-xl {
	margin: 0 0 20px 0;
	font-size: 28px;

	small {
		font-size: 24px;
	}

}

.h1-xxl {
	margin: 0 0 20px 0;
	font-size: 24px;

	small {
		font-size: 22px;
	}

}

.h1-xxxl {
	margin: 0 0 20px 0;
	font-size: 20px;

	small {
		font-size: 18px;
	}

}

/**
 * Cuerpo
 */

div#cuerpo-ficha,
.cuerpo-ficha {
	margin-bottom: 60px;
}

/**
 * Fecha y lugar
 */

.fecha-lugar {
 	padding: 10px 0;

 	&,
 	* {
 		font: 400 22px/22px @thinScout;
 		color: @grayDark;
 	}

 	em {
 		font: 400 22px/22px @thinScoutItalic;
 		font-style: normal;
 	}

 	* {
 		display: inline;
 	}

 	small {

 		&,
 		* {
 			font: 400 16px/22px @thinScout;
 		}

 		em {
 			font: 400 16px/22px @thinScoutItalic;
 			font-style: normal;
 		}

 	}

 }

/**
 * Imagen
 */

div#imagen-ficha {
	margin-bottom: 20px;
}

/**
 * Bloque lateral con fijado
 */

#relacionados-compartir {
	.padding-top-sm ();

}

/**
 * Columna lateral
 */

div#cuerpo-ficha-lateral {
	margin-bottom: 15px;
	
	// Texto

	div.field {
		margin: 0 0 10px 0;
		line-height: 14px;
		color: @gray;

		* {
			line-height: 18px;
		}

		div.field-label {
			margin-bottom: 2px;
			display: block;
			font: 400 16px/17px @boldScout;
			color: #777;
		}

		div.field-items {
			display: block;

			&,
			p,
			li {
				font: 400 14px/17px @regularScout;
				color: #777;
			}

			em {
				font: 400 14px/17px @regularScoutItalic; font-style: normal;
			}

			strong {
				font: 400 14px/17px @boldScout;
			}

			a {
				color: @linkColor;
				&:hover {
					color: @linkColorHover;
				}
			}

		}

	}

	div.field-label-inline {
		
		* {
			display: inline;
			float: none;
		}

		p,
		ul {
			margin-bottom: 0;display: block;
		}

	}

	// Titulos

	div#titulos {
		margin-bottom: 10px;
	}

	h1.page-header {
		margin-bottom: 0;padding-bottom: 0;
		font: 400 28px/28px @boldScout;
		border-bottom: none;
		@media (min-width: 1281px) {
			font: 400 34px/34px @boldScout;
		}
	}

	h2.subtitulo {
		margin-bottom: 0;
		font: 400 28px/28px @thinScout;
		@media (min-width: 1281px) {
			font: 400 34px/34px @thinScout;
		}
	}

	h2.nombre {
		margin-bottom: 0;
		font: 400 28px/28px @thinScout;
	}

	p.subtitulo {
		margin-bottom: 10px;
		font: 400 16px/16px @regularScout;
	}
	
	// Descargas

	ul.listado-descargas {

		li {
			margin: 0 0 5px 0;
			display: block;
		}

	}

	// Enlaces

	ul.listado-links {
		margin: 0 0 15px 0;
		list-style: none;

		li {
			margin: 0 0 5px 0;
			display: block;

			a {
				font: 400 14px/14px @lightScout;
			}

		}

	}

	// Etiquetas
	
	a.tipo,
	span.tipo {
		padding: 6px 10px;
		font-size: 16px;
	}

	a.tipo {
		&:hover {
			background: @beigeHover;
			text-decoration: none;
		}
	}

}
/*doc
---
title: Ver más
category: Componentes
---

Documentación...

*/

.ver-mas{
	width:13px;
	height:13px;
	margin-bottom:-2px;
	display:inline-block;
	.sprite(sprite1, 0, 4);
	&:hover{
		.sprite(sprite1, 1,4);
	}
	span{
		display:none;
	}
}

.tab-pane--load-more {
  max-height: 200px;
  position: relative;
  overflow: hidden;
  -webkit-transition: max-height 0.8s;
  -moz-transition: max-height 0.8s;
  transition: max-height 0.8s;

}

.tab-pane--load-more__container{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-image: linear-gradient(to bottom, transparent, white);
}

.tab-pane .show_more {
  margin-bottom: 30px;
}

/*doc
---
title: Buscador lateral
category: Componentes
---

Documentación...

*/

div.region.well{
	padding:10px;
	border-color:@grayLighter;
	.block{
		margin-bottom:30px;
		p.block-title{
			margin-bottom:5px;
			font:700 12px/14px @sansFontFamily;
			text-transform:uppercase;color:@grayLight;
		}
		form{
			.control-group{
				margin-bottom:0;
			}
		}
	}
	form#views-exposed-form-buscar-page,
	form#views-exposed-form-buscar-page-1,
	form#views-exposed-form-buscar-page-2{
		padding:0;
		border:none;
		label{
			display:none;
		}
	}
	form#views-exposed-form-buscar-page-2{
		label#label-edit-fecha-de,
		label#label-edit-fecha-hasta{
			display:block;
		  color: #777777;
		  margin-bottom:3px;
		}
	}
	div#links_explorar{
		width:100%;
		overflow:hidden;
		float:none;
		text-align: left;
		a{
			margin-bottom:5px;
			display:block;
			float: left;
			clear:both;
		}
	}
	div.ajax-progress{
		display:none;
	}
}
div.form-item-fecha-de{
  margin-bottom: 0px;
}


// Su busqueda
#block-current-search-current-search-local{
	margin:-10px -10px 10px -10px; padding:10px 10px 15px 10px;
	background:@beige;border-bottom:1px solid @grayLighter;}
#block-current-search-current-search-local h4,
#block-current-search-current-search-local p.block-title{display:none;}
#block-current-search-current-search-local div.current-search-item-text{
	margin-bottom:15px;
	font:700 14px/18px @sansFontFamily;}
#block-current-search-current-search-local div.current-search-item-bk-solr-custom h3{
	margin:0 0 9px 0;
	font:700 12px/14px @sansFontFamily;
	text-transform:uppercase;color:#666;}
#block-current-search-current-search-local div.current-search-item-bk-solr-custom div.current-search-group ul{
	border-top:none;}

// Categoria
h4.current-search-group-title{ margin:0 0 9px 0;font:700 12px/14px @sansFontFamily;text-transform:uppercase;color:@grayLight;}
div.current-search-item-bk-solr-custom ul{
	margin:0;list-style:none;border-top:1px dotted @grayLighter;}
div.current-search-item-bk-solr-custom li{
	padding:5px;color:@grayLight;border-bottom:1px dotted @grayLighter;}
div.current-search-item-bk-solr-custom li:hover{ background:@whiteDark;}
div.current-search-item-bk-solr-custom a{color:@grayLight;}
div.current-search-item-bk-solr-custom a:hover{color:@linkColorHover; text-decoration:none;}

ul.facetapi-facet-im-field-obra-clasificaciongener{
	max-height:145px; overflow:auto;}
ul.facetapi-facet-im-field-obra-autor{
	max-height:145px; overflow:auto;}
ul.facetapi-facet-itm-field-publicacion-anio{
	max-height:145px; overflow:auto;}


// Desplegable seccion
#sidebar-left #block-views-exp-buscar-page select,
#sidebar-left #block-views-exp-buscar-page input,
#sidebar-left #block-views-exp-buscar-page-1 select,
#sidebar-left #block-views-exp-buscar-page-1 input,
#sidebar-left #block-views-exp-buscar-page-2 select,
#sidebar-left #block-views-exp-buscar-page-2 input{
	width:100%;height:28px;margin-bottom:10px;
	-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}


// Calendario
#block-views-exp-buscar-page input#edit-fecha,
#block-views-exp-buscar-page-1 input#edit-fecha,
#block-views-exp-buscar-page-2 input#edit-fecha-de,
#block-views-exp-buscar-page-2 input#edit-fecha-hasta{
	padding-left: 30px;
	background:url(../images/ico.calendario.png) 5px 8px no-repeat @white;
}

// Calendario en sidebar
#sidebar-left #block-views-exp-buscar-page input#edit-fecha,
#sidebar-left #block-views-exp-buscar-page-1 input#edit-fecha,
#sidebar-left #block-views-exp-buscar-page-2 input#edit-fecha-de,
#sidebar-left #block-views-exp-buscar-page-2 input#edit-fecha-hasta{
	padding-left: 30px;
	background:url(../images/ico.calendario.png) 5px 5px no-repeat @white;
}


// Eventos pasados
#sidebar-left form#views-exposed-form-buscar-page div.form-item-pasados label,
#sidebar-left form#views-exposed-form-buscar-page-1 div.form-item-pasados label{
	padding-top:0; margin-bottom:10px;display:block!important;
	color:@grayLight;}
#sidebar-left form#views-exposed-form-buscar-page div.form-item-pasados input.form-checkbox,
#sidebar-left form#views-exposed-form-buscar-page-1 div.form-item-pasados input.form-checkbox{
	width:auto; height:auto;margin:2px 0 0 -17px;}

// Filtro activo
li.facetapi-active{
	background:@whiteDark;}

// Eliminar filtro
.block-facetapi a.facetapi-active,
.block-current-search span.remove-facet{ width:16px; height:16px; margin-right:5px;display:inline-block;font:700 13px/16px @sansFontFamily; text-align:center; background:@grayLight; color:@white;}
.block-facetapi a.facetapi-active{margin:0 5px 0 0;}
.block-facetapi a.facetapi-active:hover,
.block-current-search span.remove-facet:hover{background:@linkColorHover;color:@white; cursor:pointer;}
#block-current-search-current-search-local div.current-search-item-bk-solr-custom a:hover{color:@grayLight; cursor:default;}

// Filtrar por categoria
ul.facetapi-facetapi-links{ margin:0;list-style:none;border-top:1px dotted @grayLighter;}
ul.facetapi-facetapi-links li{
	padding:5px;
	font:400 13px/18px @sansFontFamily;
	color:@grayLight;border-bottom:1px dotted @grayLighter;}
ul.facetapi-facetapi-links li:hover{ background:@whiteDark;}
ul.facetapi-facetapi-links li.expanded{}
ul.facetapi-facetapi-links a{color:@grayLight;}
ul.facetapi-facetapi-links a:hover{color:@linkColorHover;text-decoration:none;}
ul.facetapi-facetapi-links li ul.expanded{margin:0;padding-top:5px;list-style:none;}
ul.facetapi-facetapi-links li ul.expanded li{padding-left:18px;border-top:1px solid @grayLighter;border-bottom:none;}
ul.facetapi-facetapi-links li ul.expanded li.last{ padding-bottom:0;}
div.current-search-item-reset-filters{text-align:right;}
div.current-search-item-reset-filters a{display:inline-block;font:400 11px/13px @sansFontFamily; text-decoration:underline;color:@grayLight;}
div.current-search-item-reset-filters a:hover{ color:@grayDark;}

// Ver mas autores
a.facetapi-limit-link{
	padding-top:5px;display:block;float:right;
	font:400 11px/13px @sansFontFamily;
	text-decoration:underline;color:@grayLight;}
a.facetapi-limit-link:hover{
	color:@grayDark;}

// Datacion - año inicio
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1{ width:100%; overflow:hidden;}
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1 div.control-group,
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1 label,
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1 div.control-group div.controls{margin:0;display:block; float:left;}
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1 label{margin:3px;}
@media (min-width: 768px) and (max-width: 979px){
form#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-datacion-1 label{margin:5px 2px;}
}

// Datacion - año de ingreso
#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso { width:100%; overflow:hidden;}
#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso div.control-group,
#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso label,
#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso div.control-group div.controls{margin:0;display:block; float:left;}
#bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso label{margin:3px;}
@media (min-width: 768px) and (max-width: 979px){
  #bk-solr-facetapi-select-year-range-widget-form-itm-field-obra-anio-ingreso label{margin:5px 2px;}
}

.btn-filter-range {
  margin-left: 3px;
  padding: 3px 3px;
}
@media (min-width: 768px){
  .btn-filter-range {
	margin-left: 5px;
	padding: 3px 5px;
  }
}

@media (min-width: 768px) {
  .select_facet_range {
	padding-left: 0;
	padding-right: 0;
  }
}

// Filtrar por adquisicion reciente
ul.facetapi-bk-solr-facetapi-boolean{ margin:0;list-style:none;border-top:1px solid @grayLighter;}
ul.facetapi-bk-solr-facetapi-boolean li{
	padding:5px;
	font:400 13px/18px @sansFontFamily;
	color:@grayLight;border-bottom:1px solid @grayLighter;}
ul.facetapi-bk-solr-facetapi-boolean li:hover{ background:@whiteDark;}
ul.facetapi-bk-solr-facetapi-boolean a{color:@grayLight;}
ul.facetapi-bk-solr-facetapi-boolean a:hover{color:@linkColorHover;text-decoration:none;}

// Obras recientemente adquiridas
#block-facetapi-prr9uohnrjkgt1biyhdqb1h11h83rojo{
	margin-bottom:0;}

// Obra en exposicion
#block-facetapi-1lhui6ejjgptyewxkltyno6vtbemp3m4{
	margin-bottom:0;}
#block-facetapi-1lhui6ejjgptyewxkltyno6vtbemp3m4 p.block-title{
	display:none;}
#block-facetapi-1lhui6ejjgptyewxkltyno6vtbemp3m4 ul.facetapi-bk-solr-facetapi-boolean{
	border-top:none;}

// Con imagen
#block-facetapi-qb7jcnicgsk4oiq3my0m8xnf2ljujekp ul.facetapi-bk-solr-facetapi-boolean{
	border-top:none;}

// Faceta seccion del web. la escondemos no queremos que se vea
#block-facetapi-s0chqykzuqqzwoekgt8rq0xt18fx0ycp{
  display:none;}

.page-buscar .form-item-pasados {
  display: none;
}

/*doc
---
title: Tiendas Palacios & Museos
category: Modulos
---

Estilos específicos para la landing de la tienda Palacios & Museos.

*/

/**
 * Pie
 */

.landing-tiendas-palacios-museos__pie {
	.margin-bottom-sm ();
	padding-top: 10px;
	border-top: 3px solid #333;
	@media (max-width: 767px) {
		text-align: center;
	}
}

.landing-tiendas-palacios-museos__pie__iconos-social {
	float: left;
	@media (max-width: 767px) {
		.margin-top-xs ();
		.margin-bottom-sm ();
		float: none;
		text-align: center;
	}

	a {
		padding: 0 5px;

		&:hover {
			text-decoration: none;
		}	
	}

}

.landing-tiendas-palacios-museos__pie__logo {
	.margin-left-lg ();
	.margin-bottom-xs ();
	float: right;
	@media (max-width: 767px) {
		display: inline-block;
		.margin-horizontal-sm ();
		float: none;
	}
	@media (max-width: 479px) {
		display: block;
		text-align: center;
	}
}
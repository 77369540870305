/*doc
---
title: Thumbnails Colección
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.coleccion {
		
		h2,
		h3.titulo {
			margin-bottom: 0;

			span {
				display: block;
				&.lema {
					font-family: @boldScout;
					font-size: 19px;
					line-height: 1.1;
					@media (max-width: 767px) {
						font-size: 22px;
					}
				}
			}

		}

		span.title {
			font-family: @regularScout;
			font-size: 14px;
		}

		span.fecha {
			font-family: @thinScout;
			font-size: 18px;
		}

		div.padding {

			* {
				font-size: 12px;
				line-height: 1.2;
			}

			em {
				font-style: italic;
			}

		}

	}

}
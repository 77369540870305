/*doc
---
title: Cargando
category: Componentes
---

Descripción...

*/

div#main.cargando{
  opacity:0.3;filter:alpha(opacity=8);
  -webkit-transition:opacity 500ms linear;
  -moz-transition:opacity 500ms linear;
  transition:opacity 500ms linear;
}

div#main {
  opacity:1;
  -webkit-transition:opacity 700ms linear;
  -moz-transition:opacity 700ms linear;
  transition:opacity 700ms linear;
}

div#cargandoImg {
  position:fixed;
  top:48%;
  left:48%;
  width:80px;
  height:80px;
  z-index:99999;

  img {
	width:80px;
	height:80px;
  }
}
/*doc
---
title: Título de página
category: Componentes
---

Este componente nos sirve para construir los títulos de las diferentes páginas del site.

##Ejemplo antiguo##

A continuación vemos un ejemplo de como habíamos construido el título de una ficha de actividad en la primera fase del proyecto. El HTML esta mal estructurado y los estilos son específicos para ese tipo de contenido, lo que impide su reutilización.

```html_example
<body class="node-type-actividad">
	<h1 class="page-header">Archivos del común</h1>
	<h2 class="subtitulo">Cápsulas de silencio</h2>
	<article>
		<div id="cuerpo-ficha">
			<div class="fecha-lugar">
				<span class="fecha">11 - 12 diciembre, 2015</span> / Edificio Nouvel, Auditorio 200 y Sala de Protocolo
			</div>
		</div>
	<article>
</body>
```

##Título de página mejorado##

En esta primer ejemplo hemos mantenido el aspecto del primer diseño del site pero hemos mejorado el etiquetado HTML .

```html_example
<header class="titulo-pagina">
	<hgroup class="titulo-pagina__titulos">
		<h1 class="titulo-pagina__titulos__titulo">Cuando el cuerpo habla</h1>
		<h2 class="titulo-pagina__titulos__subtitulo">Este es su subtítulo</h2>
	</hgroup>
	<p class="titulo-pagina__texto">21 enero, 2015 - 19:00 h / Edificio Nouvel, Auditorio 200</p>
</header>
```

##Título de página mejorado con nuevos elementos##

Con el rediseño de la sección de Educación hemos mejorado el componente añadiéndole nuevos elementos (principalmente el público al que va dirigido el contenido y una llamada a la acción del usuario).

```html_example
<header class="titulo-pagina">
	<hgroup class="titulo-pagina__titulos">
		<h1 class="titulo-pagina__titulos__titulo">Cuando el cuerpo habla</h1>
		<h2 class="titulo-pagina__titulos__subtitulo">Este es su subtítulo</h2>
	</hgroup>
	<div class="titulo-pagina__etiquetas">
		<p class="titulo-pagina__texto">Niños de 6 a 12 años acompañados de adultos</p>
		<a class="etiqueta etiqueta--grande" href="">Visita</a> <a class="etiqueta etiqueta--grande" href="">Teatro</a> <a class="etiqueta etiqueta--grande" href="">Taller</a>
	</div>    
    <div class="table-grid table-grid-align-middle">
      	<div class="col col-6">
        	<p class="titulo-pagina__texto">Domingos 4 octubre, 2015 - 5 junio, 2016 - 12:00 h</p>
      	</div>
        <div class="col col-3">
        	<p class="titulo-pagina__cta__texto texto-destacado"><span class="icono icono--sm icono--entrada"></span>Entrada gratuita</p>
        </div>
    </div>
</header>
```

##Título de página con estilo de mancheta##

Para el diseño de la nueva sección de Educación añadimos la opción de incorporar diferentes fondos de color.

```html_example
<header class="titulo-pagina titulo-pagina--mancheta fondo fondo--color-educacion-7">  
    <hgroup class="titulo-pagina__titulos">
    	<h1 class="titulo-pagina__titulos__titulo">Cuando el cuerpo habla</h1>
        <h2 class="titulo-pagina__titulos__subtitulo">Este es su subtítulo</h2>
    </hgroup>
    <div class="titulo-pagina__etiquetas">
		<p class="titulo-pagina__texto">Niños de 6 a 12 años acompañados de adultos</p>
		<a class="etiqueta etiqueta--grande" href="">Visita</a> <a class="etiqueta etiqueta--grande" href="">Teatro</a> <a class="etiqueta etiqueta--grande" href="">Taller</a>
	</div>    
    <div class="table-grid table-grid-align-middle">
      	<div class="col col-6">
        	<p class="titulo-pagina__texto">Domingos 4 octubre, 2015 - 5 junio, 2016 - 12:00 h</p>
      	</div>
        <div class="col col-3">
        	<p class="titulo-pagina__cta__texto texto-destacado"><span class="icono icono--sm icono--entrada"></span>Entrada gratuita</p>
        </div>
    </div>
</header>
```

##Título de página para portadillas##

Para poder crear cabeceras en las portadillas de los diferentes públicos de la sección de Educación añadimos un nuevo elemento de tipo Entradilla.

```html_example
<header class="titulo-pagina titulo-pagina--mancheta fondo fondo--color-educacion-7">
    <hgroup class="titulo-pagina__titulos">
        <h1 class="titulo-pagina__titulos__titulo">Niños</h1>
    </hgroup>
    <div class="titulo-pagina__entradilla texto-suavizado">
        <p>La <strong>creatividad</strong> es una de las claves fundamentales de la programación que el Museo ofrece a los niños, familias y jóvenes. En las actividades para niños y adultos, diversos lenguajes artísticos como la música, la danza, el circo, o las artes escénicas entran en diálogo con la <strong>Colección del Museo</strong> desde una concepción abierta, buscando una aproximación creativa a los contenidos de las obras.</p>
    </div>
</header>
```


*/

/**
 * Títulos
 */

.titulo-pagina__titulos {
	margin-bottom: @space-unit-xxs;
	padding-bottom: @space-unit-xxs;
	.font-size-titulo-pagina__titulos__titulo ();
	border-bottom: 4px solid @grayDark;
}

.titulo-pagina__titulos__titulo {
	margin-bottom: 0;
	&:not(:last-child) {
		margin-bottom: @space-unit-xxxs;
	}
}

.titulo-pagina__titulos__subtitulo {
	.font-size-titulo-pagina__titulos__subtitulo ();
	margin-bottom: 0;
	font-family: @thinScout;
}

/**
 * Textos que pueden acompañar a los títulos
 */

.titulo-pagina__texto {
	font-family: @thinScout;
	.font-size-titulo-pagina__texto ();
	line-height: 1.3;
}

/**
 * Entradilla
 */

.titulo-pagina__entradilla p {
	.font-size-titulo-pagina-entradilla ();
	font-family: @thinScout;
	
	strong {
		font-family: @boldScout;
		font-weight: normal;
	}

}

/**
 * Etiquetas
 */

.titulo-pagina__etiquetas {
	margin-bottom: 10px;

	.titulo-pagina__texto {
		margin: 2px 15px 5px 0;
		float: left;
		font-family: @regularScout;
		@media (max-width: 767px) {
			.no-margin-top ();
			.no-margin-right ();
			float: none;
		}
	}

}

/**
 * CTA
 */

.titulo-pagina__cta__texto {
	padding-right: 10px;
	.no-margin-bottom ();
	font-family: @regularScout;
	font-size: 17px;
	line-height: 1.2;
	font-weight: normal;
	@media (min-width: 1281px) {
		font-size: 20px;
	}
	@media (max-width: 767px) {
		margin-bottom: 10px;
	}

	.icono {
		vertical-align: middle;
		position: relative;
		top: -3px;
	}

}

.titulo-pagina__cta__texto--derecha {
	text-align: right;
	@media (max-width: 767px) {
		text-align: left;
	}
}	

.titulo-pagina__cta__boton {
	display: block;
	padding: 12px 20px;
	font-family: @regularScout;
	font-size: 17px;
	line-height: 1.2;
	font-weight: normal;
	@media (min-width: 1281px) {
		font-size: 20px;
	}
	@media (max-width: 767px) {
		display: inline-block;
	}
}

.titulo-pagina__cta__boton--inline {
	display: inline-block;
}

/**
 * Variante: Mancheta de color
 */

.titulo-pagina--mancheta {
	.no-margin-bottom ();
	padding-top: 35px;
	.padding-horizontal-sm ();
	.padding-bottom-xs ();
	@media (min-width: 1281px) {
		.padding-horizontal-md ();
	}
	@media (max-width: 767px) {
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.table-grid {
		.no-margin-bottom ();
	}

	.titulo-pagina__titulos {
		.margin-bottom-xs ();
		.no-padding-bottom ();
		border-bottom: none;
	}

	.titulo-pagina__titulos__subtitulo {
		padding-top: 5px;
	}
	
}
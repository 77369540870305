.publication-cover {
    .publication-cover-styles(@aspect-ratio: 16 / 9);    
}

.publication-cover__wrapper {
    display: inline-flex;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
}
/*doc
---
title: Thumbnails Bibliografía
category: Componentes
---

Documentación...

*/

ul.thumbnails {

	.biblio {
		min-height: inherit;
		padding: 10px;
		border-left: none;
		border-bottom: 1px solid @grayLighter;

		h4.titulo {
			margin-bottom: 5px;
			font-family: @regularScout;
			font-size: 16px;
			line-height: 1;
		}

		.autor,
		.publicacion {
			margin-bottom: 5px;
			display: block;
			font-size: 12px;
			font-family: @sansFontFamily;
			line-height: 1.3;
			color: @grayLight;
		}

	}

}
/*doc
---
title: Enlaces destacados
category: Componentes	
---

Descripción...

*/

div.enlaces-destacados{
	margin:0 0 20px 0;display:table;
	border-right:1px solid #ddd;}
div.enlaces-destacados ul{
	margin:0;padding:0;
	display: table-row;}
div.enlaces-destacados ul li{
	margin:0;display: table-cell;
	border-top:1px solid #ddd;border-left:1px solid #ddd;border-bottom:1px solid #ddd;}
div.enlaces-destacados ul li a{
	padding:10px 20px 15px 20px;display:block;
	font:400 14px/14px @boldScout;
	color:@grayDark;}
div.enlaces-destacados ul li:hover{
	background:@whiteDarker;}
div.enlaces-destacados div.view-visitas-guiadas{
	margin-bottom:0;}
@media (min-width: 768px) and (max-width: 979px){
div.enlaces-destacados{
	display:block;
	border-right:none;border-top:1px solid #ddd;}
div.enlaces-destacados ul{
	display:block;}
div.enlaces-destacados ul li{
	display:block;
	border-top:none;border-left:1px solid #ddd;border-right:1px solid #ddd;}
}
@media (max-width: 767px) {
div.enlaces-destacados{
	display:block;
	border-right:none;border-top:1px solid #ddd;}
div.enlaces-destacados ul{
	display:block;}
div.enlaces-destacados ul li{
	display:block;
	border-top:none;border-left:1px solid #ddd;border-right:1px solid #ddd;}
}